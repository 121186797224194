<template>
  <v-dialog v-model="show" :max-width="options.maxWidth" scrollable>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title v-if="!atDashboard">
            Still Here?
          </v-toolbar-title>
          <v-toolbar-title v-else>
            Chart Closed
          </v-toolbar-title>
        </v-toolbar>
        
        <!-- Content -->
        <v-card-text v-if="!atDashboard" >
          This chart will close automatically after 5 minutes of inactivity. In order to keep Protected Health Information safe, we periodically check to see if you're still here. You can stay in the chart by clicking below.
        </v-card-text>

        <v-card-text v-else >
          In order to keep Protected Health Information safe, we periodically check to see if you're still here. The chart you were viewing has been closed due to inactivity.
        </v-card-text>

        <!-- Actions -->
        <v-card-actions v-if="!atDashboard">
          <!-- Buttons -->
          <v-btn depressed @click="logOut()">Log Out</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed dark color="#primary" @click="onClose()">Continue</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <!-- Buttons -->
          <v-spacer></v-spacer>
          <v-btn text color="#primary" @click="onClose()">Dismiss</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
</template>


<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'

export default {
  name: 'OneConfirmDialog',
  mixins: [DialogMixin],
  components: {
  },
  data() {
    return {
      countdownString: null,
      countdownTimer: null,
      className: 'Confirm',
      defaultOptions: {
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        showOk: true,
        showCancel: false
      }
    }
  },
  methods: {
    ...mapActions('auth', ['signOut', 'updateLocation']),
    ...mapActions("patient", ["prepareForSigningOut"]),
    logOut() {
      clearInterval(this.countdownTimer)
      this.prepareForSigningOut()
      this.signOut().then(() => {
        this.$router.push({ name: 'Login' });
        bus.$emit('toast', { type: 'success', text: 'Logged Out' })
        this.cancel()
      })
    },
    logOutAutomatic() {
      clearInterval(this.countdownTimer)
      this.signOut().then(() => {
        this.$router.push({ name: 'Login' });
        bus.$emit('toast', { type: 'success', text: 'Logged Out' })
      })
    },
    onOpen() {
    },
    onClose() {
      clearInterval(this.countdownTimer)
      this.cancel()
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    atDashboard() {
      if (this.$route.meta.requirePatient) {
        return false
      }
      return true
    },
    showBuilding() {
      return this.options.unit && this.options.building
    }
  }
}
</script>
