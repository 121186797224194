export default {
  accessToken: null,
  temporaryAuthToken: null,
  tempAuthExpiration: null,
  token: null,
  user: null,
  userNoteMentions: [],
  userAssignments: [],
  billingAccounts: [],
  authToken: null,
  authUser: null,
  email: null,
  authExpiry: Date.now(), // firebase token expires after 1 hour
  appTitle: 'OneChart',
  appLocation: null,
  postAuthActionsPayload: null,
  userGroupMembership: [],
  selectedUserGroup: null,
  selectedUserGroupMembers: null,
  subdomainPartnerID: null,
  appTheme: null,
  subdomain: 'patient',
  headerURL: 'https://storage.googleapis.com/onechart-prod/themes/patient/header.png',
  loginURL: 'https://storage.googleapis.com/onechart-prod/themes/patient/login.png',
  termsURL: 'https://storage.googleapis.com/onechart-prod/themes/patient/termsofuse.html',
  privacyURL: 'https://storage.googleapis.com/onechart-prod/themes/patient/privacy.html',
  themeLoaded: false,
  signature: null,
  requestSignatureOptions: null,
  // RPM Tracking
  rpmCurrentSession: null,
  rpmPartner: null,
  configuration: null,
  partner: null,
  orgUsers: null,
  // notification
  userMentions: null,
  demoFeatures: false,
  viewerMode: false,
  firebaseUnsubscribeFunctions: []
}
