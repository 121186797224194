<template>
  <v-dialog v-model="show" :max-width="options.maxWidth" scrollable>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title v-if="!loggedOut">
            Still Here?
          </v-toolbar-title>
          <v-toolbar-title v-else>
            Your Session Has Expired
          </v-toolbar-title>
        </v-toolbar>
        
        <!-- Content -->
        <v-card-text v-if="!loggedOut" >
          Your current session will expire shortly. In order to keep Protected Health Information safe, we periodically check to see if you're still here. You can stay logged in by clicking below.
        </v-card-text>

        <v-card-text v-else >
          In order to keep Protected Health Information safe, we periodically check to see if you're still here. Your session has expired due to inactivity. Log back in to continue.
        </v-card-text>

        <!-- Actions -->
        <v-card-actions v-if="!loggedOut">
          <!-- Buttons -->
          <v-btn depressed @click="logOut()">Log Out</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed dark color="#primary" @click="onClose()">Stay Logged In</v-btn>
        </v-card-actions>
        <v-card-actions v-else>
          <!-- Buttons -->
          <v-spacer></v-spacer>
          <v-btn text color="#primary" @click="onClose()">Dismiss</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
</template>


<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import { mapActions, mapState } from 'vuex'
import bus from '@/core/helpers/bus'

export default {
  name: 'OneConfirmDialog',
  mixins: [DialogMixin],
  components: {
  },
  data() {
    return {
      countdownString: null,
      countdownTimer: null,
      className: 'Confirm',
      defaultOptions: {
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        showOk: true,
        showCancel: false
      }
    }
  },
  methods: {
    ...mapActions('auth', ['signOut', 'updateLocation']),
    ...mapActions("patient", ["prepareForSigningOut"]),
    logOut() {
      clearInterval(this.countdownTimer)
      this.prepareForSigningOut()
      this.signOut().then(() => {
        this.$router.push({ name: 'Login' });
        bus.$emit('toast', { type: 'success', text: 'Logged Out' })
        this.cancel()
      })
    },
    logOutAutomatic() {
      clearInterval(this.countdownTimer)
      this.signOut().then(() => {
        this.$router.push({ name: 'Login' });
        bus.$emit('toast', { type: 'success', text: 'Logged Out' })
      })
    },
    onOpen() {
    },
    onClose() {
      clearInterval(this.countdownTimer)
      this.cancel()
    }
  },
  computed: {
    ...mapState('auth', ['user']),
    loggedOut() {
      if (this.user) {
        return false
      }
      return true
    },
    showBuilding() {
      return this.options.unit && this.options.building
    }
  }
}
</script>
