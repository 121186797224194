export default {
  data() {
    return {
      months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
      days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
      shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec'],
      shortDays: ['Sun', 'Mon', 'Tues', 'Wed', 'Thurs', 'Fri', 'Sat'],
      stateList: [
        'AL',
        'AK',
        'AZ',
        'AR',
        'CA',
        'CO',
        'CT',
        'DE',
        'DC',
        'FM',
        'FL',
        'GA',
        'GU',
        'HI',
        'ID',
        'IL',
        'IN',
        'IA',
        'KS',
        'KY',
        'LA',
        'ME',
        'MH',
        'MD',
        'MA',
        'MI',
        'MN',
        'MS',
        'MO',
        'MT',
        'NE',
        'NV',
        'NH',
        'NJ',
        'NM',
        'NY',
        'NC',
        'ND',
        'MP',
        'OH',
        'OK',
        'OR',
        'PW',
        'PA',
        'PR',
        'RI',
        'SC',
        'SD',
        'TN',
        'TX',
        'UT',
        'VT',
        'VI',
        'VA',
        'WA',
        'WV',
        'WI',
        'WY'
      ]
    }
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false;
    }
  },
  methods: {
    getDateForHeader() {
      let month = new Date().getMonth()
      let year = new Date().getFullYear()
      let monthName = this.shortMonths[month]
      return monthName + ' ' + year
    },
    getTimestampOfStartOfDay(timestamp) {
      let referenceDate = new Date(timestamp)
      referenceDate.setHours(0, 0, 0, 0)
      let dayStartCutoff = referenceDate.getTime()
      return dayStartCutoff
    },
    timestampToLocalISO(timestamp) {
      let dateInArray = new Date(timestamp)
        let year = dateInArray.getFullYear()
        let month = dateInArray.getMonth() + 1
        let day = dateInArray.getDate()
        let dayString = ('0' + day).slice(-2)
        let monthString = ('0' + month).slice(-2)
        return year + '-' + monthString + '-' + dayString
    },
    timestampToLocalISOMonthYear(timestamp) {
      let dateInArray = new Date(timestamp)
        let year = dateInArray.getFullYear()
        let month = dateInArray.getMonth() + 1
        let monthString = ('0' + month).slice(-2)
        return year + '-' + monthString
    },
    getArrayOfDatesSince(timestamp) {
      let nowdate = new Date(timestamp)
      let dayStart = new Date(nowdate.getFullYear(), nowdate.getMonth(), nowdate.getDate())
      dayStart.setHours(0, 0, 0, 0)
      let dayEnd = new Date()
      // dayEnd.setHours(0, 0, 0, 0)

      let dataToReturn = []
      for (var i = dayStart.getTime(); i < dayEnd.getTime(); i++) {
        let dateInArray = new Date(i)
        let month = dateInArray.getMonth()
        let day = dateInArray.getDate()
        let monthName = this.shortMonths[month]
        let display = monthName + ' ' + day
        if (dateInArray.getTime() < dayEnd.getTime()) {
          dataToReturn.push({dateForSorting: this.timestampToLocalISO(i), dateForDisplay: display})
        }
        i = i + 86400000
      }
      return dataToReturn

    },
    getDaysSince(timestamp) {
      let start = new Date(timestamp)
      let today = new Date().getDate()
      let elapsed = today - start.getDate()
      return elapsed
    },
    shortDateFromDaysAgo(daysAgo) {
      let nowdate = new Date()
      let dayStart = new Date(nowdate.getFullYear(), nowdate.getMonth(), nowdate.getDate() - daysAgo)
      let month = dayStart.getMonth()
      let day = dayStart.getDate()
      let monthName = this.shortMonths[month]
      return monthName + ' ' + day
    },
    getShortDateFromTimestamp(timestamp) {
      let dayStart = new Date(timestamp)
      let month = dayStart.getMonth()
      let day = dayStart.getDate()
      let monthName = this.shortMonths[month]
      return monthName + ' ' + day
    },
    getTimestampFromTimePeriodStart() {
      let start = 1
      // the day is start

      let day = start
      let month = new Date().getMonth()
      let year = new Date().getFullYear()
      if (new Date().getDate() > day) {
        //go back to the last month
        month = month - 1
      }
      // create date and get time the start of the time period minus ten days... really this should just be a limit
      let currentPeriodStartStamp = new Date(year, month, day, 0, 0, 0, 0).getTime()
      return currentPeriodStartStamp - (10 * 86400 * 1000)
    },
    msToTime(s) {
      if (!s) {
        return '00:00:00'
      }
    let seconds = Math.floor((s / 1000) % 60)
    let minutes = Math.floor((s / (1000 * 60)) % 60)
    let hours = Math.floor((s / (1000 * 60 * 60)))

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds
    },
    getDateFromTimestamp(timestamp) {
      let d = new Date(timestamp)
      return ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear()
    },
    getDateAndTimeFromTimestamp(timestamp) {
      let d = new Date(timestamp)

      let hours = d.getHours();
      let minutes = d.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let strTime = hours + ':' + minutes + ' ' + ampm;
      return ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear() + ' ' + strTime
    },
    getConversationalDateAndTimeFromTimestamp(timestamp) {
      let d = new Date(timestamp)

      let hours = d.getHours();
      let minutes = d.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let strTime = hours + ':' + minutes + ' ' + ampm;
      return ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear() + ' at ' + strTime
    },
    getDateAndTimeObjectFromTimestamp(timestamp) {
      let d = new Date(timestamp)

      let hours = d.getHours();
      let minutes = d.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let strTime = hours + ':' + minutes + ' ' + ampm;
      let date = ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear()
      let time = strTime
      return {date: date, time: time}
    },
    daysSinceTimestamp(timestamp) {
      var Difference_In_Time = (new Date().getTime() / 1000) - timestamp

      let days = Difference_In_Time / (3600 * 24)
      return Math.floor(days)
    },
    parseSignupJWT(jwt) {
      let base64Url = jwt.split('.')[1]
      let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
      let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
      }).join(''))
      console.log(JSON.parse(jsonPayload))
      return JSON.parse(jsonPayload)
    },
    isoDateToTimestamp(birthdate) {
      let extractedNumbers = birthdate.match(/\d+/g).map(Number)
      let constructedDate = new Date(extractedNumbers[0], extractedNumbers[1], extractedNumbers[2])
      if (constructedDate.getTime()) {
        return Date.UTC(constructedDate.getFullYear(), constructedDate.getMonth() - 1, constructedDate.getDate(), 12) / 1000
      }
    },
    alternatingColors(index) {
      if (index % 2 == 0) {
        return 'whiteAlternate'
        } else {
        return 'grayAlternate'
      }
    },
    birthdateToTimestamp(birthdate) {
      let extractedNumbers = birthdate.replace(/\D/g, '')
      let constructedDate = new Date(extractedNumbers.slice(4, 8), extractedNumbers.slice(0, 2) - 1, extractedNumbers.slice(2, 4))
      if (constructedDate.getTime()) {
        return Date.UTC(constructedDate.getFullYear(), constructedDate.getMonth(), constructedDate.getDate(), 12) / 1000
      }
    },
    validateEmail(email) {
      if (email) {
        let regex = /(.+)@(.+){2,}\.(.+){2,}/
        if (regex.test(email.toLowerCase())) {
          return true
        } else {
          return false
        }
      }
      return false
    },
    recordTypeOptions() {
      return [
        'PRE_OP_NOTE',
        'POST_OP_NOTE',
        'OP_NOTE',
        'OFFICE_VISIT',
        'MESSAGE',
        'MRI',
        'IMAGE_READ',
        'XRAY',
        'OTHER_IMAGING',
        'IMAGING',
        'LAB_RESULT',
        'PROGRESS_NOTE',
        'DIAGNOSTIC_REPORT',
        'REFERRAL',
        'COMPLETED_PRIOR_AUTH',
        'UNKNOWN'
      ]
    },
    getTypeForFile(file) {
      let recordTypesDictionary = {
        'IMAGE_READ': 'MRI Report',
        'MRI': 'MRI Images',
        'PRE_OP_NOTE': 'Pre-Op Note',
        'POST_OP_NOTE': 'Post-Op Note',
        'OP_NOTE': 'Operative Note',
        'OFFICE_VISIT': 'Office Visit',
        'MESSAGE': 'Clinical Message',
        'XRAY': 'X-Ray',
        'OTHER_IMAGING': 'Imaging',
        'IMAGING': 'Medical Images',
        'LAB_RESULT': 'Lab Result',
        'PROGRESS_NOTE': 'Progress Note',
        'DIAGNOSTIC_REPORT': 'Diagnostic Report',
        'REFERRAL': 'Referral',
        'COMPLETED_PRIOR_AUTH': 'Completed Prior-Auth',
        'UNKNOWN': 'Medical Record',
        'BILLING': 'Billing',
        'ORDER': 'Order'
      }
      if (file.recordType) {
        if (recordTypesDictionary[file.recordType]) {
          return recordTypesDictionary[file.recordType]
        } else {
          return 'Medical Record'
        }
      } else {
        return 'Medical Record'
      }
    },
    getColorForFile(file) {
      let recordTypesDictionary = {
        'IMAGE_READ': '#4485ED',
        'MRI': '#607D8B',
        'PRE_OP_NOTE': '#9E9E9E',
        'POST_OP_NOTE': '#795547',
        'OP_NOTE': '#FE5722',
        'OFFICE_VISIT': '#FF9700',
        'MESSAGE': '#FEC107',
        'XRAY': '#FFEB3C',
        'OTHER_IMAGING': '#CDDC38',
        'IMAGING': '#607D8B',
        'LAB_RESULT': '#8BC249',
        'PROGRESS_NOTE': '#4CB050',
        'DIAGNOSTIC_REPORT': '#029788',
        'REFERRAL': '#00BCD5',
        'COMPLETED_PRIOR_AUTH': '#00BCD5',
        'UNKNOWN': '#03A9F5',
        'BILLING': '#2196F3',
        'ORDER': '#2196F3'
      }
      if (file.recordType) {
        if (recordTypesDictionary[file.recordType]) {
          return recordTypesDictionary[file.recordType]
        } else {
          return '#03A9F5'
        }
      } else {
        return '#03A9F5'
      }
    },
    getIconForFile(file) {
      let recordTypesDictionary = {
        'IMAGE_READ': 'art_track',
        'MRI': 'perm_media',
        'PRE_OP_NOTE': 'description',
        'POST_OP_NOTE': 'description',
        'OP_NOTE': 'description',
        'OFFICE_VISIT': 'description',
        'MESSAGE': 'chat',
        'XRAY': 'description',
        'OTHER_IMAGING': 'perm_media',
        'IMAGING': 'perm_media',
        'LAB_RESULT': 'bar_chart',
        'PROGRESS_NOTE': 'description',
        'DIAGNOSTIC_REPORT': 'description',
        'REFERRAL': 'description',
        'COMPLETED_PRIOR_AUTH': 'description',
        'UNKNOWN': 'description',
        'BILLING': 'attach_money',
        'ORDER': 'local_pharmacy'
      }
      if (file.recordType) {
        if (recordTypesDictionary[file.recordType]) {
          return recordTypesDictionary[file.recordType]
        } else {
          return 'description'
        }
      } else {
        return 'description'
      }
    },
    getTimestampOfStartOfThisMonth() {
      let nowdate = new Date()
      let monthStartDay = new Date(nowdate.getFullYear(), nowdate.getMonth(), 1)

      monthStartDay.setHours(0, 0, 0, 0)
      let monthStartDayCutoff = monthStartDay.getTime() / 1000
      return monthStartDayCutoff
    },
    getCurrentMonthName() {
      let nowdate = new Date()
      return this.shortMonths[nowdate.getMonth()]
    },
    getTimestampOfStartOfPreviousMonth() {
      let nowdate = new Date()
      let year = nowdate.getFullYear()
      let month = nowdate.getMonth()

      if (month === 0) {
        month = 11
        year = year - 1
      } else {
        month = month - 1
      }
      let monthStartDay = new Date(year, month, 1)

      monthStartDay.setHours(0, 0, 0, 0)
      let monthStartDayCutoff = monthStartDay.getTime() / 1000
      return monthStartDayCutoff
    },
    getPreviousMonthName() {
      let nowdate = new Date()
      let month = nowdate.getMonth()

      if (month === 0) {
        month = 11
      } else {
        month = month - 1
      }

      return this.shortMonths[month]
    },
    humanReadableDateToISO(dateString) {
      // mm/dd/yyyy
      let simplifiedDateString = dateString.replace('/', '').replace('/', '')
      console.log('simplified date string')
      console.log(simplifiedDateString)
      let isoDate = simplifiedDateString.substring(4, 8) + '-' + simplifiedDateString.substring(0, 2) + '-' + simplifiedDateString.substring(2, 4)
      return isoDate
    },
    getDateStringFromTimestamp(timestamp) {
      let d = new Date(timestamp * 1000)
      return d.getMonth() + 1 + '/' + d.getDate() + '/' + d.getFullYear()
    },
    isoToDisplayDate(dateFull) {
      let date = dateFull.replace('-', '').replace('-', '')
      let dateToReturn = date.substring(4, 6) + '/' +  date.substring(6, 8) + '/' + date.substring(0, 4)
      return dateToReturn
    },
    getSurveyDateStringFromTimestamp(timestamp) {
      // 1985-11-08
      let d = new Date(timestamp * 1000)
      let MyDateString = d.getFullYear() + '-' + ('0' + (d.getMonth() + 1)).slice(-2) + '-' + ('0' + d.getDate()).slice(-2)
      return MyDateString
    },
    getBirthdateStringFromTimestamp(timestamp) {
      let d = new Date(timestamp * 1000)
      let MyDateString = ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear()
      return MyDateString
    },
    getLongDateStringFromTimestamp(timestamp) {
      let d = new Date(timestamp * 1000)
      return this.days[d.getDay()] + ' ' + this.months[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear()
    },
    getMediumDateWithDayDateStringFromTimestamp(timestamp) {
      let d = new Date(timestamp)
      return this.shortDays[d.getDay()] + ' ' + this.shortMonths[d.getMonth()] + ' ' + d.getDate() + ', ' + d.getFullYear()
    },
    getMonthAndYearFromTimestamp(timestamp) {
      let d = new Date(timestamp)
      return this.shortMonths[d.getMonth()] + ' ' + d.getFullYear()
    },
    dateForSortingFromTimestamp(timestamp) {
      // this method takes a timestamp and returns a date string in the format yyyymmdd
      let d = new Date(timestamp * 1000)
      return d.getFullYear() + ('0' + (d.getMonth() + 1)).slice(-2) + ('0' + d.getDate()).slice(-2)
    },
    providerDisplayNameFromProviderObject(provider) {
      if (provider) {
        if (provider.firstName) {
          let name = provider.firstName + ' ' + provider.lastName

          if (provider.credentials) {
            name = name + ' ' + provider.credentials
          }
          return name
        }
        if (provider.organizationName) {
          return provider.organizationName
        }
        if (provider.coveredEntityName) {
          return provider.coveredEntityName
        }
      }
    },
    providerDisplayAddressFromProviderObject(provider) {
      if (provider.address) {
        let addressString = provider.address.line1
        if (provider.address.line2) {
          addressString = addressString + ' ' + provider.address.line2
        }

        addressString = addressString + ' ' + provider.address.city + ' ' + provider.address.state + ' ' + provider.address.zip

        return addressString
      }
      return ''
    },
    providerIconFromProviderObject(provider) {
      if (provider.type === 'INDIVIDUAL') {
        return 'person'
      }
      return 'business'
    },
    providerColorFromProviderObject(provider) {
      if (provider.type === 'INDIVIDUAL') {
        return '#4485ed'
      }
      return '#8DC249'
    },
    providerShortDisplayAddressFromProviderObject(provider) {
      if (provider.address) {
        let addressString = ''
        addressString = provider.address.city + ' ' + provider.address.state

        return addressString
      }
      return ''
    },
    patientDisplayAddressFromPatientObject(patient) {
      if (patient.address) {
        let addressString = patient.address.line1
        if (patient.address.line2) {
          addressString = addressString + ' ' + patient.address.line2
        }

        addressString = addressString + ' ' + patient.address.city + ' ' + patient.address.state + ' ' + patient.address.zip

        return addressString
      }
      return 'Missing Address'
    },
    shouldShowPendingProviderReview(timestamp) {
      let nowdate = new Date().getTime() / 1000

      let timestampOfWhenThePatientCanViewTheDocument = this.businessDaysFromDate(timestamp, 2)

      if (nowdate > timestampOfWhenThePatientCanViewTheDocument) {
        return false
      } else {
        return true
      }
    },
    businessDaysFromDate(d, businessDays) {
      let date = new Date(d * 1000)
      var counter = 0
      var tmp = new Date(date)
      while (businessDays >= 0) {
        tmp.setTime(date.getTime() + counter * 86400000)
        if (this.isBusinessDay(tmp)) {
          --businessDays
        }
        ++counter
      }
      return tmp.getTime() / 1000
    },
    isBusinessDay (date) {
      var dayOfWeek = date.getDay()
      if (dayOfWeek === 0 || dayOfWeek === 6) {
        // Weekend
        return false
      }
      let holidays = [
        '12/31+5',
        '1/1',
        '1/2+1',
        '1-3/1',
        '2-3/1',
        '5~1/1',
        '7/3+5',
        '7/4',
        '7/5+1',
        '9-1/1',
        '10-2/1',
        '11/10+5',
        '11/11',
        '11/12+1',
        '11-4/4',
        '12/24+5',
        '12/25',
        '12/26+1'
      ]
      var dayOfMonth = date.getDate()
      var month = date.getMonth() + 1
      var monthDay = month + '/' + dayOfMonth
      if (holidays.indexOf(monthDay) > -1) {
        return false
      }
      var monthDayDay = monthDay + '+' + dayOfWeek
      if (holidays.indexOf(monthDayDay) > -1) {
        return false
      }
      var weekOfMonth = Math.floor((dayOfMonth - 1) / 7) + 1
      var monthWeekDay = month + '-' + weekOfMonth + '/' + dayOfWeek
      if (holidays.indexOf(monthWeekDay) > -1) {
        return false
      }
      var lastDayOfMonth = new Date(date)
      lastDayOfMonth.setMonth(lastDayOfMonth.getMonth() + 1)
      lastDayOfMonth.setDate(0)
      var negWeekOfMonth = Math.floor((lastDayOfMonth.getDate() - dayOfMonth - 1) / 7) + 1
      var monthNegWeekDay = month + '~' + negWeekOfMonth + '/' + dayOfWeek
      if (holidays.indexOf(monthNegWeekDay) > -1) {
        return false
      }
      return true
    },
    transformPartnerIDToHumanReadableName(theID) {
      if (theID.includes('5a4fa82c8e4b74e028665d04')) {
        return 'SmartChoice'
      } else if (theID.includes('5acb94000495d7065c909bc2')) {
        return 'Grayhawk'
      } else if (theID.includes('5b73507c4aef2028740f662b')) {
        return 'ImagineMD'
      } else if (theID.includes('5a3f56ec92808ba8d3d5a391')) {
        return 'Newpark'
      } else {
        return 'invalidID: ' + theID
      }
    },
    getStateByName(name) {
      switch (name.ToUpper()) {
        case 'ALABAMA':
          return 'AL'

        case 'ALASKA':
          return 'AK'

        case 'AMERICAN SAMOA':
          return 'AS'

        case 'ARIZONA':
          return 'AZ'

        case 'ARKANSAS':
          return 'AR'

        case 'CALIFORNIA':
          return 'CA'

        case 'COLORADO':
          return 'CO'

        case 'CONNECTICUT':
          return 'CT'

        case 'DELAWARE':
          return 'DE'

        case 'DISTRICT OF COLUMBIA':
          return 'DC'

        case 'FEDERATED STATES OF MICRONESIA':
          return 'FM'

        case 'FLORIDA':
          return 'FL'

        case 'GEORGIA':
          return 'GA'

        case 'GUAM':
          return 'GU'

        case 'HAWAII':
          return 'HI'

        case 'IDAHO':
          return 'ID'

        case 'ILLINOIS':
          return 'IL'

        case 'INDIANA':
          return 'IN'

        case 'IOWA':
          return 'IA'

        case 'KANSAS':
          return 'KS'

        case 'KENTUCKY':
          return 'KY'

        case 'LOUISIANA':
          return 'LA'

        case 'MAINE':
          return 'ME'

        case 'MARSHALL ISLANDS':
          return 'MH'

        case 'MARYLAND':
          return 'MD'

        case 'MASSACHUSETTS':
          return 'MA'

        case 'MICHIGAN':
          return 'MI'

        case 'MINNESOTA':
          return 'MN'

        case 'MISSISSIPPI':
          return 'MS'

        case 'MISSOURI':
          return 'MO'

        case 'MONTANA':
          return 'MT'

        case 'NEBRASKA':
          return 'NE'

        case 'NEVADA':
          return 'NV'

        case 'NEW HAMPSHIRE':
          return 'NH'

        case 'NEW JERSEY':
          return 'NJ'

        case 'NEW MEXICO':
          return 'NM'

        case 'NEW YORK':
          return 'NY'

        case 'NORTH CAROLINA':
          return 'NC'

        case 'NORTH DAKOTA':
          return 'ND'

        case 'NORTHERN MARIANA ISLANDS':
          return 'MP'

        case 'OHIO':
          return 'OH'

        case 'OKLAHOMA':
          return 'OK'

        case 'OREGON':
          return 'OR'

        case 'PALAU':
          return 'PW'

        case 'PENNSYLVANIA':
          return 'PA'

        case 'PUERTO RICO':
          return 'PR'

        case 'RHODE ISLAND':
          return 'RI'

        case 'SOUTH CAROLINA':
          return 'SC'

        case 'SOUTH DAKOTA':
          return 'SD'

        case 'TENNESSEE':
          return 'TN'

        case 'TEXAS':
          return 'TX'

        case 'UTAH':
          return 'UT'

        case 'VERMONT':
          return 'VT'

        case 'VIRGIN ISLANDS':
          return 'VI'

        case 'VIRGINIA':
          return 'VA'

        case 'WASHINGTON':
          return 'WA'

        case 'WEST VIRGINIA':
          return 'WV'

        case 'WISCONSIN':
          return 'WI'

        case 'WYOMING':
          return 'WY'
      }
      return 'Not Available'
    }
  }
}
