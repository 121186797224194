import './plugins/axios'
import 'firebase/analytics'
import 'firebase/auth'
import 'firebase/firestore'

import App from './App.vue'
import PortalVue from 'portal-vue'
import Vue from 'vue'
import VueTheMask from 'vue-the-mask'
import firebase from 'firebase/app'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'

Vue.use(VueTheMask)
Vue.use(PortalVue)

Vue.config.productionTip = false

// TODO: Move this into .env
let firebaseConfig = {
  apiKey: 'AIzaSyD8RAiis0W0qO55eHZvsXR1rrylJuqa58Y',
  authDomain: 'onechart-monitor-prod.firebaseapp.com',
  databaseURL: 'https://onechart-monitor-prod.firebaseio.com',
  projectId: 'onechart-monitor-prod',
  storageBucket: 'onechart-monitor-prod.appspot.com',
  messagingSenderId: '258556006130',
  appId: '1:258556006130:web:a3ad065a185897d89b4a6b',
  measurementId: 'G-JL1DLLHBZJ',
}

// Initialize Firebase
firebase.initializeApp(firebaseConfig)
firebase.analytics()
firebase.auth().setPersistence(firebase.auth.Auth.Persistence.SESSION)
firebase.auth().onAuthStateChanged(user => {
  store.dispatch('auth/firebaseSignInReturn', user)
})

async function main() {
  // Clear out service workers - we aren't doing PWAs anymore
  const registeredServiceWorkers = await navigator.serviceWorker.getRegistrations()
  for (const r of registeredServiceWorkers) {
    const result = await r.unregister()
    console.log(`unregistering service worker (${r.active.scriptURL}) for ${r.scope}: ${result}`)
  }
  
  new Vue({
    router,
    store,
    vuetify,
    render: h => h(App),
  }).$mount('#app')
}
main()