import bus from '../helpers/bus'

const AUTH_CHANGED_HOOK = 'hook:auth_changed'
export const broadcastAuthChanged = authUser => {
  bus.$emit(AUTH_CHANGED_HOOK, authUser)
}

export const onAuthChanged = handler => {
  return bus.$on(AUTH_CHANGED_HOOK, handler)
}

const LOGOUT = 'hook:logout'
export const broadcastLogoutRequest = ({ error }) => {
  bus.$emit(LOGOUT, { error })
}

export const onLogoutRequest = handler => {
  return bus.$on(LOGOUT, handler)
}
