//import Vue from 'vue'
import get from 'lodash/get'
import consts from '@/consts'

export default {
  async sendMassTextToPatient({ rootState, dispatch }, { patient, message }) {
    // function delay(ms) {
    //   return new Promise(resolve => setTimeout(resolve, ms))
    // }

    console.log(patient)
    // first get the conversations

    let threeHours = 3600000 * 3
    let canSend = true

    if (get(patient, 'tele.messaging.lastMsg.created', null)) {
      let date = new Date(patient.tele.messaging.lastMsg.created).getTime()
      if (new Date().getTime() - date < threeHours) {
        canSend = false
      }
    }

    if (canSend) {
      console.log('dispatch a send message:', message)
      console.log(rootState)
      console.log(dispatch)

      let partnerID = rootState.auth.user.partnerID
      // console.log(partnerID)

      // return delay(3000).then(() => {
      //   return { status: 200 }
      // })

      try {
        await dispatch(
          'conversation/sendMessage',
          { patientId: patient._id, orgId: partnerID, content: message },
          { root: true }
        )
        return { status: 200 }
      } catch (_) {
        return { status: 400 }
      }
    } else {
      return { status: 400 }
    }
  },
  applyTextExpansionToString(_, { patient, message }) {
    let patientFirstNameParameter = '{patient_first_name}'

    let patientFullNameParameter = '{patient_full_name}'

    let measurementListParameter = '{vitals_list}'

    let firstNameReplacement = patient.firstName

    let fullNameReplacement = patient.firstName + ' ' + patient.lastName

    let vitalsListReplacement = ''

    let patientVitals = []
    Object.entries(consts.metricList).forEach(metric => {
      const [name, details] = metric
      if (patient.latestEvents[name] && details.primaryMetric) {
        patientVitals.push(details.commonName.toLowerCase())
      }
    })
    if (patientVitals.length > 1) {
      const last = patientVitals.pop()
      vitalsListReplacement = patientVitals.join(', ') + ' and ' + last
    } else if (patientVitals.length === 1) {
      vitalsListReplacement = patientVitals[0]
    } else {
      vitalsListReplacement = ''
    }

    let result = message.replaceAll(patientFirstNameParameter, firstNameReplacement)

    result = result.replaceAll(patientFullNameParameter, fullNameReplacement)

    result = result.replaceAll(measurementListParameter, vitalsListReplacement)

    return result
  },
  saveATextCampaignTemplate({ rootState, dispatch }, { title, content }) {
    let ObjectID = require('bson-objectid')

    let uniqueMongoID = ObjectID().toString()

    let messageTemplates = {}

    if (rootState.auth.configuration.messageTemplates) {
      messageTemplates = rootState.auth.configuration.messageTemplates
    }

    messageTemplates[uniqueMongoID] = {
      title: title,
      content: content,
      id: uniqueMongoID,
    }

    dispatch(
      'auth/updatePartnerSettings',
      {
        partner: rootState.auth.user.partnerID,
        update: { messageTemplates: messageTemplates },
      },
      { root: true }
    )
  },
  deleteACampaignTemplate({ rootState, dispatch }, template) {
    let messageTemplates = rootState.auth.configuration.messageTemplates

    delete messageTemplates[template.id]

    return dispatch(
      'auth/updatePartnerSettings',
      {
        partner: rootState.auth.user.partnerID,
        update: { messageTemplates: messageTemplates },
      },
      { root: true }
    )
  },
}
