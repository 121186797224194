import consts from '@/consts'

export default {
  applyTextExpansionToString({patient, message}) {
    let patientFirstNameParameter = '{patient_first_name}'

    let patientFullNameParameter = '{patient_full_name}'

    let measurementListParameter = '{vitals_list}'

    let firstNameReplacement = patient.firstName

    let fullNameReplacement = patient.firstName + ' ' + patient.lastName

    let vitalsListReplacement = ''

    let patientVitals = []
    Object.entries(consts.metricList).forEach(metric => {
      const [name, details] = metric
      if (patient.latestEvents[name] && details.primaryMetric) {
        patientVitals.push(details.commonName.toLowerCase())
        
      }
  });
  if (patientVitals.length > 1) {
    const last = patientVitals.pop()
    vitalsListReplacement = patientVitals.join(', ') + ' and ' + last
  } else if (patientVitals.length === 1) {
    vitalsListReplacement = patientVitals[0]
  } else {
    vitalsListReplacement = ''
  }
    

    let result = message.replaceAll(patientFirstNameParameter, firstNameReplacement)

    result = result.replaceAll(patientFullNameParameter, fullNameReplacement)

    result = result.replaceAll(measurementListParameter, vitalsListReplacement)

    return result
  },
  saveAPatientGroup({rootState, dispatch}, {title, filter, users}) {

    let ObjectID = require("bson-objectid")
  
    let uniqueMongoID = ObjectID().toString()
    let templateEntry = {}
    templateEntry[uniqueMongoID] = {title: title, filter: filter, users, id: uniqueMongoID}
  
    dispatch('auth/updatePartnerSettings',{partner: rootState.auth.user.partnerID, update: {patientGroups: templateEntry}}, {root:true})
  
      },

      deleteAPatientGroup({rootState, dispatch}, template) {

        let patientGroups = rootState.auth.configuration.patientGroups
  
        delete patientGroups[template.id]
  
        return dispatch('auth/updatePartnerSettings',{partner: rootState.auth.user.partnerID, update: {patientGroups: patientGroups}}, {root:true})
  
    
      },
}
