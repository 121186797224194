<template>
  <div v-if="user" class="text-center ml-1">
    <v-fade-transition>
      <v-btn
        v-if="false"
        v-show="userMentions.length + userAssignments.length > 0"
        @click="toggleTodayView()"
        icon
      >
        <v-badge
          :value="!todayTasksToggle"
          :content="userMentions.length + userAssignments.length"
          color="primary"
          overlap
        >
          <v-icon>mdi-at</v-icon>
        </v-badge>
      </v-btn>
    </v-fade-transition>

    <v-menu transition="slide-y-transition" v-model="menu" offset-y>
      <template v-slot:activator="{ on }">
        <v-btn depressed icon color="transparent" v-on="on" class="body-2">
          <v-avatar color="primary" size="38"
            ><span class="white--text">{{ userInitials }}</span>
          </v-avatar>
        </v-btn>
      </template>

      <v-card flat color="white" width="320">
        <v-card flat class="pa-2">
          <v-card height="30" color="primary lighten-5" flat>
            <v-row
              no-gutters
              v-if="user && user.partnerID"
              justify="center"
              align="center"
              class="fill-height text-body-2"
            >
              <span>{{ managedByText }}</span>
            </v-row>
          </v-card>
        </v-card>
        <v-layout row justify-center align-end style="height: 100px">
          <v-avatar color="primary" size="75"
            ><span class="white--text text-h4">{{ userInitials }}</span>
          </v-avatar>
        </v-layout>
        <v-layout justify-center class="pt-2 nameStyle" row>
          {{ userDisplayName }}
        </v-layout>
        <v-layout v-if="user" class="emailStyle" justify-center row>
          {{ user.email }}
        </v-layout>

        <v-layout v-if="partnerName" class="emailStyle" justify-center row>
          {{ partnerName }}
        </v-layout>

        <v-row justify="center">
          <v-btn class="ma-2" @click="navigateToAccount()" color="primary" text>
            Account Settings
          </v-btn>
        </v-row>

        <v-row
          justify="center"
          v-if="user && user.partnerID === '5e39227d1dd9b10d0bd42895'"
          no-gutters
        >
          <v-btn
            class="ma-2"
            @click="navigateToDempPrep()"
            color="primary"
            text
          >
            Environment Settings
          </v-btn>
        </v-row>

        <v-divider class="mt-6" />

        <v-btn @click="callSignout()" large tile text block>
          <v-icon left>mdi-logout</v-icon>
          Sign out
        </v-btn>
      </v-card>
    </v-menu>
  </div>
</template>

<style lang="scss">
.managedByChip {
  padding: 4px;
  border-radius: 3px;
  width: 100%;
  text-align: center;
  font-size: 14px;
  background-color: #e3f2fd;
}
.nameStyle {
  font-size: 18px;
}
.emailStyle {
  font-size: 14px;
  font-weight: 500;
  opacity: 0.8;
}
</style>

<script>
import { mapState, mapActions } from 'vuex'
import bus from '@/core/helpers/bus'

export default {
  components: {},
  data: () => ({
    whatsNew: false,
    fav: true,
    menu: false,
    message: false,
    hints: true,
  }),
  computed: {
    ...mapState('taskboard', ['todayTasks', 'todayTasksToggle']),
    ...mapState('auth', [
      'user',
      'partner',
      'userMentions',
      'userAssignments',
      'demoFeatures',
    ]),
    taskBadgeTotal() {
      let total = 0
      if (this.user.mentions) {
        total = this.user.mentions.length
      }

      if (this.user.assignments) {
        total = total + this.user.assignments.length
      }
      return total
    },
    managedByText() {
      // if the text is too long, shorten the name
      if (this.partner && this.partner.name) {
        let text = 'Account managed by ' + this.partner.name

        if (text.length < 40) {
          return text
        } else {
          return this.partner.name
        }
      }
      return null
    },
    partnerName() {
      if (this.partner && this.partner.name) {
        return this.partner.name
      }
      return null
    },
    userInitials() {
      if (this.user && this.user.firstName && this.user.lastName) {
        return this.user.firstName.charAt(0) + this.user.lastName.charAt(0)
      }
      return '-'
    },
    userDisplayName() {
      if (this.user && this.user.firstName && this.user.lastName) {
        return this.user.firstName + ' ' + this.user.lastName
      }
      return '-'
    },
    name() {
      return this.user.firstName + this.user.lastName || this.user.email
    },
    version() {
      return process.env.VUE_APP_VERSION
    },
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    ...mapActions('dashboard', ['prepareForSignout']),
    ...mapActions('patient', ['prepareForSigningOut']),
    toggleTodayView() {
      bus.$emit('toggleToday')
    },
    navigateToAccount() {
      this.$router.push({ name: 'Account', params: { id: this.user._id } })
    },
    navigateToDempPrep() {
      this.$router.push({ name: 'Demoprep', params: { id: this.user._id } })
    },
    async callSignout() {
      await this.signOut()
      this.prepareForSignout()
      this.prepareForSigningOut()
      if (this.$route.name !== 'Login') {
        this.$router.push({ name: 'Login' })
      }
    },
  },
}
</script>
