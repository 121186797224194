import Vue from 'vue'
import firebase from 'firebase/app'
import clone from 'lodash/clone'
import bus from '@/core/helpers/bus'
import get from 'lodash/get'

export default {
  setTheme({ commit }) {
    // set the default fallback theme
    // app theme must be null because the app wont load until it is set
    let appTheme = {
      buttonColor: '#46BAC1',
      navColor: '#253C4A',
      navAccent: '#FFFFFF',
      dark: true,
      shade: '#FFFFFF08',
      header: 'https://storage.googleapis.com/onechart-prod/themes/patient/header.png?nocache=' + new Date().getTime(),
      login: 'https://storage.googleapis.com/onechart-prod/themes/patient/login.png?nocache=' + new Date().getTime(),
      privacy:
        'https://storage.googleapis.com/onechart-prod/themes/patient/privacy.html?nocache=' + new Date().getTime(),
      terms:
        'https://storage.googleapis.com/onechart-prod/themes/patient/termsofuse.html?nocache=' + new Date().getTime(),
      tagline: 'Powered by OneChart Health',
    }

    let docRef = firebase
      .firestore()
      .collection('theme')
      .doc('monitor')

    docRef
      .get()
      .then(function(doc) {
        if (doc.exists) {
          // overwrite anything in the default theme with the new stuff
          let keys = Object.keys(doc.data())
          keys.forEach(key => {
            appTheme[key] = doc.data()[key]
          })

          // set the theme to dark or light
          if (appTheme.navColor) {
            let hex = appTheme.navColor.replace('#', '')
            let r = parseInt(hex.substring(0, 2), 16)
            let g = parseInt(hex.substring(2, 4), 16)
            let b = parseInt(hex.substring(4, 6), 16)

            let averageRed = r / 255
            let averageGreen = g / 255
            let averageBlue = b / 255

            let overallAverage = (averageRed + averageGreen + averageBlue) / 3
            if (overallAverage > 0.6) {
              appTheme.dark = false
              appTheme.navAccent = '#717171'
              appTheme.shade = '#0000001a'
            } else {
              appTheme.dark = true
              appTheme.navAccent = '#FFFFFF'
              appTheme.shade = '#FFFFFF1a'
            }
          }
          if (
            window.location.hostname.split('.')[0].includes('app') ||
            window.location.hostname.split('.')[0].includes('localhost')
          ) {
            appTheme.login =
              'https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FloginLogo.svg?alt=media&token=af56fb13-5c41-4a57-be3c-0d415b9f3b5b'
            appTheme.header =
              'https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FheaderLogo.svg?alt=media&token=1367e2f7-98f9-41f3-abf4-7e440f9f655f'
            appTheme.icon =
              'https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FminiHeaderTest.svg?alt=media&token=b85a1a2b-6933-4a2f-90ab-ed68316710df'
            appTheme.navColor = '#333449'
            appTheme.buttonColor = '#4285F4'
          }
          // handle kroger
          if (window.location.href.includes('kroger')) {
            appTheme.login = 'https://www.krogerhealth.com/images/logo_header.svg'
            appTheme.header = 'https://www.krogerhealth.com/images/logo_header.svg'
            appTheme.navColor = '#E7EFFB'
            appTheme.buttonColor = '#4471B7'
            appTheme.dark = false
            appTheme.kroger = true
          }

          if (window.location.href.includes('rehncare')) {
            appTheme.login =
              'https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2Frehncare_login_cropped.png?alt=media&token=95964975-b319-4429-b25b-e997c46aa9a4'
            appTheme.header =
              'https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FheaderLogo.svg?alt=media&token=1367e2f7-98f9-41f3-abf4-7e440f9f655f'
            appTheme.icon =
              'https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FminiHeaderTest.svg?alt=media&token=b85a1a2b-6933-4a2f-90ab-ed68316710df'
            appTheme.navColor = '#66A1B8'
            appTheme.buttonColor = '#66A1B8'
            appTheme.dark = true
            appTheme.kroger = false
          }

          if (window.location.href.includes('privis')) {
            appTheme.login =
              'https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FPrivis%20Login.svg?alt=media&token=7a9dfdd4-4b20-4c08-99b6-9ac1fb7daa84'
            appTheme.header =
              'https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FPrivis%20Nav%20Bar.svg?alt=media&token=30bbd122-60bd-4b65-92b0-b6f772eaa0e4'
            appTheme.icon =
              'https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FPrivis%20Icon.svg?alt=media&token=441935ec-66ed-4c91-afaa-3488d19ee6e6'
            appTheme.navColor = '#186BA5'
            appTheme.buttonColor = '#186BA5'
            appTheme.dark = true
            appTheme.kroger = false
          }

          commit('setAppTheme', appTheme)
          return doc.data()
        } else {
          // doc.data() will be undefined in this case
          commit('setAppTheme', appTheme)
          return appTheme
        }
      })
      .catch(function(error) {
        console.log('Error getting document:', error)
        commit('setAppTheme', appTheme)
        return appTheme
      })
  },
  getPartner({ commit }, id) {
    commit('extraCommit', null)
    return Vue.axios.get('/partners?ids=' + id).then(({ data }) => {
      console.log
      return data[0]
    })
  },
  setPartnerThresholdSettings({ commit, dispatch, state }, payload) {
    commit('extraCommit', null)
    let thresholdSet = payload.thresholds
    Vue.axios.put('/partners/' + payload.partner + '/defaultPatientEventRules', thresholdSet).then(() => {
      dispatch('getPartner', payload.partner).then(data => {
        state.rpmPartner.partnerObject = data
        state.rpmPartner.thresholds = data.defaultPatientEventRules
      })
      // get partner and set it as rpm partner
    })
  },
  check({ dispatch, state }) {
    return new Promise((resolve, reject) => {
      if (state.authToken && state.authExpiry > Date.now() && state.user) {
        return resolve()
      }
      return dispatch('autoSignIn').then(resolve, reject)
    })
  },
  autoSignIn({ commit, dispatch }) {
    // this is called when the page is refreshed
    return new Promise((resolve, reject) => {
      // check if user is already authentication
      return firebase.auth().onAuthStateChanged(user => {
        if (user) {
          commit('setAuthUser', {
            uid: user.uid,
            email: user.email,
          })
          // if user is authenticated
          if (user.email) {
            return dispatch('getAuthUserToken').then(resolve, reject)
          }
        } else {
          return reject(new Error('User not found'))
        }
      })
    })
  },
  rpmClear({ commit }) {
    // should be called when you leave a chart or sign out
    commit('setRPMCurrentSession', null)
  },
  setTemporaryToken({ commit }, token) {
    commit('setAuthUser', {
      uid: '0000',
      email: 'expiring',
    })

    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function(c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
        })
        .join('')
    )
    let info = JSON.parse(jsonPayload)

    commit('setTemporaryAuthToken', token)
    commit('setAuthToken', token)
    commit('setTemporaryAuthExpiration', info.exp * 1000)
  },
  userAction({ state }, payload) {
    let action = {}
    if (payload.type) {
      action.type = payload.type
    }
    if (payload.patient) {
      action.patient = payload.patient
    }

    if (payload.details) {
      action.details = payload.details
    }

    action.timestamp = new Date().getTime() / 1000

    if (state.user) {
      action.user = state.user._id
      action.email = state.user.email
      let db = firebase.firestore()
      return db
        .collection('userActions')
        .add(action)
        .then(function() {})
        .catch(function(error) {
          console.error('Error adding document: ', error)
        })
    } else {
      // check if authenticated, if you are, then go get the user
      return Vue.axios
        .get('/user')
        .then(({ data }) => {
          action.user = data._id
          action.email = data.email
          let db = firebase.firestore()
          return db
            .collection('userActions')
            .add(action)
            .then(function() {})
            .catch(function(error) {
              console.error('Error adding document: ', error)
            })
        })
        .catch(function(error) {
          console.error('couldnt get a user ', error)
          let db = firebase.firestore()
          return db
            .collection('userActions')
            .add(action)
            .then(function() {})
            .catch(function(error) {
              console.error('Error adding document: ', error)
            })
        })
    }
  },
  updateUser({ commit }, user) {
    const userCopy = clone(user)
    delete userCopy.authID
    return Vue.axios.patch('/user', userCopy).then(({ data }) => {
      commit('setUser', user)
      return data
    })
  },
  updateUserName({ commit, state }, { firstName, lastName }) {
    commit('extraCommit', null)
    let payload = {}
    payload.user = {}
    payload.user.lastName = lastName
    payload.user.firstName = firstName

    return Vue.axios.patch('/Users/' + state.user._id, payload).then(({ data }) => {
      console.log('updated name')
      console.log(data.user)
      commit('setUser', data.user)
      return data
    })
  },
  signIn({ commit }, { email, password, payload }) {
    // on signing in, store a token in case we need to apply it later in the post authentication actions
    if (payload) {
      commit('setPostAuthActionsPayload', payload)
    } else {
      commit('setPostAuthActionsPayload', null)
    }

    commit('setRPMCurrentSession', null)
    return new Promise((resolve, reject) => {
      return firebase
        .auth()
        .signInWithEmailAndPassword(email, password)
        .then(user => {
          // set the timestamp of login for auto-logout functionality
          sessionStorage.setItem('login', new Date().getTime() / 1000)
          const authUser = {
            uid: user.uid,
            email: user.email,
          }
          commit('setAuthUser', authUser)
          // update user document with the last login
          // go do something after signing in if there is a payload
          return resolve(authUser)
        })
        .catch(error => {
          return reject(error)
        })
    })
  },
  getAuthUserToken({ commit }) {
    return new Promise((resolve, reject) => {
      // get user token
      return firebase
        .auth()
        .currentUser.getIdToken(/* forceRefresh */ true)
        .then(function(authToken) {
          commit('setAuthToken', authToken)
          return resolve()
        }, reject)
    })
  },
  getUserGroupMembers({ commit }, id) {
    commit('setSelectedUserGroupMembers', null)
    return Vue.axios.get('/userGroups/' + id + '/members?limit=100&offset=0').then(({ data }) => {
      commit('setSelectedUserGroupMembers', data.members)
    })
  },
  removeUserFromGroup({ commit }, { userID, groupID }) {
    commit('extraCommit', null)
    let url = '/userGroups/' + groupID + '/members/' + userID
    return Vue.axios.delete(url).then(({ data }) => {
      return data
    })
  },
  signOut({ commit, state, dispatch }) {
    state.firebaseUnsubscribeFunctions.forEach(func => {
      func()
    })
    commit('setAuthUser', null)
    commit('setAuthToken', null)
    commit('setUserMentions', null)
    commit('setRPMCurrentSession', null)
    commit('setRPMPartner', null)
    dispatch('populationview/clearTaskboard', null, { root: true })
    commit('populationmap/resetCoord', null, { root: true })
    dispatch('patient/clearPatient', null, { root: true })
    commit('metrics/resetViability', null, { root: true })
    return firebase.auth().signOut()
  },
  resetPassword({ commit }, email) {
    return new Promise((resolve, reject) => {
      return firebase
        .auth()
        .sendPasswordResetEmail(email)
        .then(resolve)
        .catch(error => {
          commit('setError', error)
          reject(error)
        })
    })
  },
  // MONITOR METHODS //
  updateUserObject({ commit, state }) {
    commit('extraCommit')
    let payload = {}
    payload.user = {}
    if (state.user.firstName) {
      payload.user.firstName = state.user.firstName
    }
    if (state.user.lastName) {
      payload.user.lastName = state.user.lastName
    }
    if (state.user.settings) {
      payload.user.settings = state.user.settings
    }
    return Vue.axios.patch('/Users/' + state.user._id, payload).then(({ data }) => {
      return data
    })
  },
  updatePartnerObject({ commit, state }) {
    console.log('updating partner object')
    console.log(state.partner.defaultPatientEventRules)
    commit('extraCommit')
    let payload = {}
    payload.partner = {}
    if (state.partner.name) {
      payload.partner.name = state.partner.name
    }
    // update the thresholds if appropriate
    if (state.partner.defaultPatientEventRules) {
      payload.partner.defaultPatientEventRules = state.partner.defaultPatientEventRules
    }

    if (state.partner.patientInactivitySettings) {
      payload.partner.patientInactivitySettings = state.partner.patientInactivitySettings
    }
    return Vue.axios.patch('/Partners/' + state.partner._id, payload).then(({ data }) => {
      return data
    })
  },
  getUser({ commit, dispatch }) {
    return Vue.axios
      .get('/Users')
      .then(data => {
        let returnedUser = data.data.user
        if (returnedUser.partnerID) {
          // listen for notifications
          // get the partner
          Vue.axios.get('/Partners/' + returnedUser.partnerID).then(({ data }) => {
            if (data.partner && !data.partner.defaultPatientEventRules) {
              data.partner.defaultPatientEventRules = {}
            }
            // get the users for the partner here for tagging purposes?
            commit('setPartner', data.partner)
            dispatch('getOrgUsers', data.partner._id)
          })

          let docRef = firebase
            .firestore()
            .collection('configuration')
            .doc(returnedUser.partnerID)
          return docRef.get().then(function(doc) {
            let config = {}
            if (doc.exists) {
              let keys = Object.keys(doc.data())
              keys.forEach(key => {
                config[key] = doc.data()[key]
              })
              commit('setConfiguration', config)
              return returnedUser
            } else {
              console.log('doc doesnt exist')
              commit('setConfiguration', config)
              return returnedUser
            }
          })
        } else {
          commit('setConfiguration', {})
          return returnedUser
        }
      })
      .catch(function(error) {
        console.error('Error getting user: ', error)
      })
  },
  firebaseSignInReturn({ commit, dispatch }, user) {
    if (user) {
      return new Promise((resolve, reject) => {
        // get user token
        return firebase
          .auth()
          .currentUser.getIdToken()
          .then(function(authToken) {
            commit('setAuthToken', authToken)
            return dispatch('getUser').then(userReturned => {
              commit('setUser', userReturned)
              dispatch('listenForMentionsFromFirebase')
              if (userReturned.partnerID) {
                // go get the dashboard patients
                dispatch('listenForOrgConfigChangesInFirebase', null)
                dispatch('populationview/getEnrolledPatients', null, { root: true })
              } else {
                dispatch('taskboard/getUserPatients', { user: userReturned._id }, { root: true })
              }
              return resolve()
            })
          }, reject)
      })
    } else {
      commit('setAuthToken', null)
      commit('setUser', null)
    }
  },
  signUp({ commit }, { email, password }) {
    // this will create teh firebase user, and when returned, update the novo user name
    return new Promise((resolve, reject) => {
      firebase
        .auth()
        .createUserWithEmailAndPassword(email, password)
        .then(user => {
          const authUser = {
            uid: user.uid,
            email: user.email,
          }
          commit('setAuthUser', authUser)
          return resolve(authUser)
        })
        .catch(error => {
          console.log('error is caught')
          return reject(error)
        })
    })
  },
  userActivity({ commit }, { type, patient, file, payload }) {
    commit('extraCommit')
    let action = {}
    if (type) {
      action.type = type
    }
    if (patient) {
      action.patient = patient
    }
    if (file) {
      action.file = file
    }
    if (payload) {
      action.payload = payload
    }
    action.timestamp = new Date().getTime()

    if (firebase.auth().currentUser && firebase.auth().currentUser.uid) {
      action.user = firebase.auth().currentUser.uid
      let db = firebase.firestore()
      return db
        .collection('userActivity')
        .add(action)
        .then(function() {})
        .catch(function(error) {
          console.error('Error adding document: ', error)
        })
    }
  },
  rpmTrack({ commit, state, dispatch }, payload) {
    if (payload && payload.patient) {
      commit('extraCommit', null)
      let patient = payload.patient
      if (state.rpmCurrentSession) {
        return Vue.axios.post('/MonitoringSessions/' + state.rpmCurrentSession + '/heartbeat').then(data => {
          dispatch('registerLastChartReview')
          dispatch('patient/updatePatientRPMTotal', { patient: payload.patient }, { root: true })
          return data
        })
      } else {
        return Vue.axios.post('/MonitoringSessions', { patientID: patient }).then(data => {
          commit('setRPMCurrentSession', data.data.monitoringSession._id)
          return data
        })
      }
    }
  },
  registerLastChartReview({ rootState }) {
    // only register a last chart review if you've spent 30 seconds in the patient chart.
    // this should only be set if youre still in the chart so that quick glances dont register
    if (rootState.patient.patient && rootState.patient.patient._id) {
      let db = firebase.firestore()
      let lastReview = {
        user: rootState.auth.user._id,
        time: new Date().getTime(),
      }
      return db
        .collection('lastChartReview')
        .doc(rootState.patient.patient._id)
        .set(lastReview)
        .catch(function(error) {
          console.error('Error adding document: ', error)
        })
    }
  },
  registerLastChartNote({ rootState }) {
    // only register a last chart review if you've spent 30 seconds in the patient chart.
    // this should only be set if youre still in the chart so that quick glances dont register
    let lastNotesRef = firebase
      .firestore()
      .collection('lastNotes')
      .doc(rootState.auth.user.partnerID)
    lastNotesRef.get().then(docSnapshot => {
      if (docSnapshot.exists) {
        if (rootState.patient.patient && rootState.patient.patient._id && rootState.auth.user.partnerID) {
          let db = firebase.firestore()
          let noteEntry = {}
          noteEntry[rootState.patient.patient._id] = new Date().getTime()
          return db
            .collection('lastNotes')
            .doc(rootState.auth.user.partnerID)
            .update(noteEntry)
            .catch(function(error) {
              console.error('Error adding document: ', error)
            })
        }
      } else {
        if (rootState.patient.patient && rootState.patient.patient._id && rootState.auth.user.partnerID) {
          let db = firebase.firestore()
          let noteEntry = {}
          noteEntry[rootState.patient.patient._id] = new Date().getTime()
          return db
            .collection('lastNotes')
            .doc(rootState.auth.user.partnerID)
            .set(noteEntry)
            .catch(function(error) {
              console.error('Error adding document: ', error)
            })
        }
      }
    })
  },
  updatePartnerSettings({ commit }, payload) {
    console.log('check to see if the document exists')
    if (payload.partner) {
      console.log(payload)
      // check if the document exists
      let db = firebase.firestore()

      let documentReference = db.collection('configuration').doc(payload.partner)

      documentReference
        .get()
        .then(doc => {
          if (doc.exists) {
            return db
              .collection('configuration')
              .doc(payload.partner)
              .update(payload.update)
              .then(() => {
                let docRef = firebase
                  .firestore()
                  .collection('configuration')
                  .doc(payload.partner)
                docRef.get().then(function(doc) {
                  let config = {}
                  if (doc.exists) {
                    let keys = Object.keys(doc.data())
                    keys.forEach(key => {
                      config[key] = doc.data()[key]
                    })
                    commit('setConfiguration', config)
                  } else {
                    commit('setConfiguration', config)
                  }
                })
              })
              .catch(function(error) {
                console.error('Error adding document: ', error)
              })
          } else {
            return db
              .collection('configuration')
              .doc(payload.partner)
              .set({})
              .then(() => {
                return db
                  .collection('configuration')
                  .doc(payload.partner)
                  .update(payload.update)
                  .then(() => {
                    let docRef = firebase
                      .firestore()
                      .collection('configuration')
                      .doc(payload.partner)
                    docRef.get().then(function(doc) {
                      let config = {}
                      if (doc.exists) {
                        let keys = Object.keys(doc.data())
                        keys.forEach(key => {
                          config[key] = doc.data()[key]
                        })
                        commit('setConfiguration', config)
                      } else {
                        commit('setConfiguration', config)
                      }
                    })
                  })
                  .catch(function(error) {
                    console.error('Error adding document: ', error)
                  })
              })
          }
        })
        .catch(error => {
          console.log('Error getting document:', error)
        })
    }
  },
  getOrgUsers({ commit }, id) {
    commit('setOrgUsers', null)
    return Vue.axios.get('/Partners/' + id + '/Users?limit=500&offset=0').then(({ data }) => {
      let rawUsers = []
      data.items.forEach(user => {
        user.displayName = user.firstName + ' ' + user.lastName
        user.searchName = user.firstName + ' ' + user.lastName + ' ' + user.email
        rawUsers.push(user)
      })

      commit('setOrgUsers', rawUsers)
    })
  },
  refreshUserList({ commit }, partnerID) {
    // get the invites as well
    commit('extraCommit')
    return Vue.axios.get('/Partners/' + partnerID + '/Users?limit=100&offset=0').then(({ data }) => {
      let rawUsers = data.items

      rawUsers.forEach(user => {
        if (!user.partnerRoles) {
          user.partnerRoles = {}
        }
      })
      return rawUsers
    })
  },
  refreshPartnerInviteList({ commit }, partnerID) {
    // get the invites as well
    commit('extraCommit')
    return Vue.axios.get('/Partners/' + partnerID + '/PartnerInvites?limit=100&offset=0').then(({ data }) => {
      return data.items
    })
  },
  deleteInvite({ commit }, inviteID) {
    commit('extraCommit')
    return Vue.axios.delete('/PartnerInvites/' + inviteID).then(() => {
      return
    })
  },
  updatePartnerUserPermissions({ commit, state, dispatch }, { userID, partnerRoles }) {
    commit('extraCommit', null)
    // if this is the logged in user, you should refresh the user
    let url = '/Users/' + userID + '/partnerRoles'
    return Vue.axios.put(url, { partnerRoles }).then(({ data }) => {
      if (state.user._id === userID) {
        dispatch('getUser').then(userReturned => {
          commit('setUser', userReturned)
        })
      }
      return data
    })
  },
  removeUserFromPartner({ commit, state, dispatch }, { userID }) {
    commit('extraCommit', null)
    // if this is the logged in user, you should refresh the user
    let url = '/Users/' + userID + '/partnerID'
    return Vue.axios.delete(url).then(({ data }) => {
      if (state.user._id === userID) {
        dispatch('getUser').then(userReturned => {
          commit('setUser', userReturned)
        })
      }
      return data
    })
  },
  addUserToPartner({ commit }, { email, partnerID, roles }) {
    commit('extraCommit', null)
    let payload = {
      partnerInvite: {
        email: email,
        partnerID: partnerID,
        partnerRoles: roles,
      },
    }
    let url = '/PartnerInvites'
    return Vue.axios.post(url, payload).then(function(response) {
      return response
    })
  },
  addChartNote({ commit, state, dispatch }, payload) {
    commit('extraCommit', null)
    console.log
    if (payload.patient) {
      // now that you have a patient, see if there is a time payload on this or not.
      // if there is, add the time document, then add the note.
      // if not, add the note
      let userID = state.user._id
      let email = state.user.email
      let session = state.rpmCurrentSession
      if (payload.sessionNote) {
        // add RPM_NOTE
        let patientEvent = {}
        patientEvent.eventType = 'RPM_NOTE'
        patientEvent.timestamp = new Date().getTime()
        patientEvent.eventData = {
          partner: state.partner._id,
          user: userID,
          email: email,
          note: payload.sessionNote,
          type: payload.type,
          action: payload.action,
          referenceData: payload.referenceData,
        }
        patientEvent.eventData.authorName = state.user.firstName + ' ' + state.user.lastName
        patientEvent.eventData.authorOrganization = state.partner.name
        // here is where the patient note can have user id tags?
        if (get(payload, 'noteMentions.length', 0) > 0) {
          console.log('there is a mention')
          patientEvent.eventData.mentions = payload.noteMentions
        }
        patientEvent.eventData.sessionFirebaseDocumentID = session
        patientEvent.patientID = payload.patient
        patientEvent.peripheralName = 'Manual Entry'
        return Vue.axios.post('/PatientEvents', { patientEvent }).then(data => {
          dispatch('taskboard/dismissInactivity', payload.patient, {
            root: true,
          })
          if (get(payload, 'noteMentions.length', 0) > 0) {
            dispatch(
              'patient/notifyChartNoteTaggedUsers',
              {
                note: data.data.patientEvent,
                taggedUsers: payload.noteMentions,
                patient: payload.patient,
              },
              { root: true }
            )
          }
          return data
        })
      }
    }
    return 'error'
  },
  applyRegistrationToken({ commit, dispatch, state }, payload) {
    // always get the latest user to avoid any race conditions
    // the payload can apply a token or just update the user (with the first and last name in the case of a new registration)
    // this method returns the user after applying the token (but also sets the user in state)
    if (payload.loginToken) {
      // apply the login token
      let body = {}
      body.loginToken = payload.loginToken
      return Vue.axios
        .post('/Users/' + state.user._id + '/loginToken', body)
        .then(function(response) {
          if (response.status === 200) {
            if (response.data) {
              if (response.data.resultShortMsg && response.data.resultShortMsg === 'added_to_patient') {
                // go get user patients again
                // go get dashboard patients again
                return dispatch('getUser').then(userReturned => {
                  commit('setUser', userReturned)
                  bus.$emit('toast', {
                    type: 'success',
                    text: 'You now have access to a new patient chart',
                  })
                  return dispatch('taskboard/getUserPatients', { user: state.user._id }, { root: true })
                })
              } else if (response.data.resultShortMsg && response.data.resultShortMsg === 'added_to_partner') {
                return dispatch('getUser').then(userReturned => {
                  commit('setUser', userReturned)
                  bus.$emit('toast', {
                    type: 'success',
                    text: 'You now have access to a new organization',
                  })
                  dispatch('populationview/getEnrolledPatients', null, {
                    root: true,
                  })
                })
              }
            }
          }
          return response
        })
        .catch(function(error) {
          console.error('Error applying token: ', error)
          dispatch('taskboard/getUserPatients', { user: state.user._id }, { root: true })
          if (state.user.partnerID) {
            dispatch('populationview/getEnrolledPatients', null, { root: true })
          }
          if (error.response.status === 400) {
            // bus.$emit('toast', { type: 'error', text: 'Token could not be applied: 400' })
            //bus.$emit('postAuthNotification', {type: 'invalidToken', title: 'Token Error', text: 'Token could not be applied. Please reach out to the sender and request a new link.', data: error.data})
          } else if (error.response.status === 403) {
            bus.$emit('toast', {
              type: 'error',
              text: 'Token could not be applied: 403',
            })
            //bus.$emit('postAuthNotification', {type: 'userMismatch', title: 'Token Error', text: 'Token could not be applied. Please reach out to the sender and request a new link.', data: error.data})
          } else if (error.response.status === 409) {
            // do nothing. youre just re-applying a token
            //bus.$emit('postAuthNotification', {type: 'tokenError', title: 'Token Error', text: 'Token could not be applied. Please reach out to the sender and request a new link.', data: error.data})
          } else {
            bus.$emit('toast', {
              type: 'error',
              text: 'Token could not be applied:' + error.response.status,
            })
            //bus.$emit('postAuthNotification', {type: 'tokenError', title: 'Token Error', text: 'Token could not be applied. Please reach out to the sender and request a new link.', data: error.data})
          }
          return dispatch('getUser').then(userReturned => {
            commit('setUser', userReturned)
            return userReturned
          })
        })
    } else {
      return dispatch('getUser').then(userReturned => {
        commit('setUser', userReturned)
        return userReturned
      })
    }

    // there is always a payload
  },
  listenForOrgConfigChangesInFirebase({ commit, rootState }) {
    // on snapshot will be called whenever the config changes.
    //db.collection('userNotifications').doc(rootState.auth.user._id).collection('mentions').where("read", "==", null)
    var unsubscribe = firebase
      .firestore()
      .collection('configuration')
      .doc(rootState.auth.user.partnerID)
      .onSnapshot(doc => {
        let config = {}
        if (doc.exists) {
          let keys = Object.keys(doc.data())
          keys.forEach(key => {
            config[key] = doc.data()[key]
          })
          commit('setConfiguration', config)
          let chartNoteTemplates = []
          if (config.chartNoteTemplates) {
            let keys = Object.keys(config.chartNoteTemplates)

            keys.forEach(key => {
              chartNoteTemplates.push(config.chartNoteTemplates[key])
            })
          }
          commit('notes/setSavedNoteTemplates', chartNoteTemplates, {
            root: true,
          })

          //commit('setSavedTextTemplates', templates)

          let messageTemplates = []
          if (config.messageTemplates) {
            let keys = Object.keys(config.messageTemplates)

            keys.forEach(key => {
              messageTemplates.push(config.messageTemplates[key])
            })
          }
          commit('masstext/setSavedTextTemplates', messageTemplates, {
            root: true,
          })

          let patientGroups = []
          if (config.patientGroups) {
            let keys = Object.keys(config.patientGroups)

            keys.forEach(key => {
              patientGroups.push(config.patientGroups[key])
            })
          }
          commit('patientgroups/setPatientGroups', patientGroups, {
            root: true,
          })
        } else {
          console.log('doc doesnt exist')
          commit('setConfiguration', config)
        }
      })
    commit('auth/addFirebaseUnsubscribeFunction', unsubscribe, { root: true })
  },
  listenForMentionsFromFirebase({ commit, dispatch, rootState }) {
    commit('setUserMentions', [])
    let db = firebase.firestore()
    //db.collection('userNotifications').doc(rootState.auth.user._id).collection('mentions').where("read", "==", null)
    var unsubscribe = db
      .collection('userNotifications')
      .doc(rootState.auth.user._id)
      .collection('mentions')
      .where('read', '==', null)
      .onSnapshot(function(querySnapshot) {
        commit('setUserMentions', [])
        var unreadMentions = []
        let numberOfMentions = querySnapshot.docs.length
        let count = 0
        querySnapshot.forEach(function(doc) {
          // push the patient to this.
          let notificationInfo = doc.data()
          dispatch('patient/getPatientInfo', { id: doc.data().patient }, { root: true }).then(data => {
            notificationInfo.patient = data
            notificationInfo.IDForClearing = doc.data().patientEvent
            notificationInfo.note =
              "You've been mentioned in a note in " + data.firstName + ' ' + data.lastName + "'s chart."
            unreadMentions.push(notificationInfo)
            count = count + 1
            if (count == numberOfMentions) {
              commit('setUserMentions', unreadMentions)
              if (rootState.patient.patient && rootState.patient.patient._id === doc.data().patient) {
                // refresh the page if youre on it.
                bus.$emit('refreshNoteList')
              }
            }
          })
        })
      })
    commit('auth/addFirebaseUnsubscribeFunction', unsubscribe, { root: true })
  },
}
