import Vue from 'vue'
import Vuex from 'vuex'
import auth from './modules/auth'
import dashboard from './modules/dashboard'
import patient from './modules/patient'
import taskboard from './modules/taskboard'
import notes from './modules/notes'
import featureflags from './modules/featureflags'
import metrics from './modules/metrics'
import masstext from './modules/masstext'
import rpm from './rpm'
import patientdrawer from './modules/patientdrawer'
import patientgroups from './modules/patientgroups'
import telehealth from './modules/telehealth'
import populationmap from './modules/populationmap'
import populationview from './modules/populationview'
// :: v2 - using go services ::
import rpmv2 from './v2/rpm'
import enrollment from './v2/enrollment'
import patientv2 from './v2/patient'
import conversation from './v2/conversation'
import newmetrics from './modules/newmetrics'
import engagement from './v2/engagement'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    auth,
    dashboard,
    metrics,
    notes,
    featureflags,
    masstext,
    enrollment,
    patient,
    patientv2,
    taskboard,
    rpm,
    rpmv2,
    conversation,
    patientdrawer,
    patientgroups,
    telehealth,
    populationmap,
    newmetrics,
    populationview,
    engagement,
  },
})
