export default {
  enrolledPatients: [],
  unenrolledPatients: [],
  storedSelectedPatients: [],
  enrolledPatientsDictionary: {},
  refreshingTaskboard: false,
  userPatients: [],
  refreshingUserPatients: false,
  taskboardFreshness: 0,
  patientTags: [],
  patientConditions: [],
  patientZips: [],
  patientMetricTypes: [],
  patientDevices: [],
  patientLoadingProgress: 0,
}
