import store from '@/store'
import { getAccessToken } from '../../shared-ui/store/sso'
import { HttpClient } from '../../shared-ui/helpers/httpClient'

const requestHandler = async (ctx, request) => {
  const accessToken = await getAccessToken()
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`
  } else {
    store.dispatch('auth/logout', { error: 'Invalid refresh token' })
  }
  return request
}

const patientsClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_PATIENTS_API_URL,
  requestHandler,
})

export default {
  namespaced: true,
  state: {
    patient: null,
  },
  mutations: {
    setPatient(state, patient) {
      state.patient = patient
    },
  },
  actions: {
    async getPatient({ commit }, filter) {
      const { id = '', ownerId = '', ownerPatientId = '', smsPhone = '' } = filter
      try {
        let { data } = await patientsClient.post('/GetPatient', {
          id,
          ownerId,
          ownerPatientId,
          smsPhone,
        })
        commit('setPatient', data.patient)
        return data.patient
      } catch (e) {
        commit('setPatient', null)
        return null
      }
    },
    async createPatient({ commit }, patientInfo) {
      let { data } = await patientsClient.post('/CreatePatient', {
        owner: patientInfo.owner,
        ownerPatientId: patientInfo.ownerPatientId,
        firstName: patientInfo.firstName,
        lastName: patientInfo.lastName,
        gender: patientInfo.gender,
        birthdate: patientInfo.birthdate,
        primaryPhone: patientInfo.primaryPhone,
        smsPhone: patientInfo.smsPhone,
        email: patientInfo.email,
        address: patientInfo.address,
      })
      commit('setPatient', data.patient)
      return data.patient
    },
  },
}
