<template>
  <v-dialog
    v-model="show"
    :max-width="options.maxWidth"
    :transition="transition"
    scrollable
    :fullscreen="$vuetify.breakpoint.xsOnly"
  >
    <v-card class="pa-3 px-4">
      <v-row align="center" no-gutters justify="space-between" class="font-weight-bold text-h6">
        <span
          v-if="options.patient && options.patient.firstName"
        >Share {{this.options.patient.firstName}} {{this.options.patient.lastName}}'s Chart</span>
        <v-btn icon @click="cancel">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-row>

      <v-card-text class="pa-0">
        <v-form class="mb-5" ref="emailForm" v-model="valid">
          <v-text-field
            class="mt-3"
            flat
            v-model="emailAddress"
            required
            label="Recipient Email Address"
            :rules="emailRules"
            solo-inverted
          ></v-text-field>

          <v-list-item three-line class="px-0 mx-0 pr-2">
            <v-list-item-content>
              <v-list-item-title>Send Patient View Invitation</v-list-item-title>
              <v-list-item-subtitle>Permanent view-only access to the chart.</v-list-item-subtitle>
              <v-list-item-subtitle>Account creation required.</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-radio-group class="px-0" v-model="chartViewer" hide-details>
                <v-radio color="primary" class="px-0" :value="true" label></v-radio>
              </v-radio-group>
            </v-list-item-action>
          </v-list-item>

          <v-list-item three-line class="px-0 mx-0 pr-2">
            <v-list-item-content>
              <v-list-item-title>Send Quickshare Invitation</v-list-item-title>

              <v-list-item-subtitle>Immediate view-only access that expires in 72 hours.</v-list-item-subtitle>
              <v-list-item-subtitle style="color: red; font-weight:700">No sign in required.</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-radio-group class="px-0" v-model="quickshare" hide-details>
                <v-radio color="primary" class="px-0" :value="true" label></v-radio>
              </v-radio-group>
            </v-list-item-action>
          </v-list-item>

          <v-list-item v-if="false" two-line class="px-0 mx-0">
            <v-list-item-content>
              <v-list-item-title>Chart Administrator Access Invitation</v-list-item-title>
              <v-list-item-subtitle>Full access. Recipient can update, share and manage chart access.</v-list-item-subtitle>
              <v-list-item-subtitle>Account creation required.</v-list-item-subtitle>
            </v-list-item-content>
            <v-list-item-action>
              <v-radio-group class="px-0" v-model="chartAdministrator" hide-details>
                <v-radio color="primary" class="px-0" :value="true" label></v-radio>
              </v-radio-group>
            </v-list-item-action>
          </v-list-item>
        </v-form>
        <v-layout row class="mx-0 mt-2">
          <span style="text-align: justify">
            <span style="font-weight: 700">Note:</span>Patient health information is protected by federal and state privacy laws and is intended only for the use of authorized person(s). You are responsible for verifying that the recipient is authorized to view the shared information. Share only with trusted parties. Recipients will receive an invitation email with instructions and a link to access the information shared.
            For security, invitation emails will expire within 72 hours.
          </span>
        </v-layout>
      </v-card-text>

      <v-row class="mt-4" no-gutters>
        <v-btn depressed @click="cancelOption()">Cancel</v-btn>
        <v-spacer></v-spacer>
        <v-btn depressed color="primary" dark @click="addUser()">Send Invite</v-btn>
      </v-row>
    </v-card>
  </v-dialog>
</template>
<script>
import DialogMixin from '@/core/mixins/DialogMixin'
import HelperMixin from '@/core/mixins/HelperMixin'
import { mapState, mapActions } from 'vuex'
import bus from '@/core/helpers/bus'

export default {
  mixins: [DialogMixin, HelperMixin],
  components: {},
  data() {
    return {
      emailAddress: '',
      valid: false,
      quickshare: false,
      chartViewer: true,
      chartAdministrator: false,
      nameRules: [(v) => !!v || 'Required'],
      emailRules: [
        (v) => !!v || 'E-mail is required',
        (v) => this.validateEmail(v) || 'E-mail must be valid',
      ],
      inviteBulletPoints: [
        {
          icon: 'mdi-email',
          title: 'Invitation Email',
          text: 'A secure invite link is sent to the indicated email address.',
        },
        {
          icon: 'mdi-lock',
          title: 'Authenticated Access',
          text:
            'The recipient must follow the link and sign in or sign up to be granted access. Only the indicated email can be used.',
        },
        {
          icon: 'mdi-calendar-clock',
          title: 'Link Expires in 72 Hours',
          text:
            'The secure link embedded in the email will expire within 72 hours if unused.',
        },
      ],
    }
  },
  computed: {
    ...mapState('patient', ['patient']),
    transition() {
      return this.$vuetify.breakpoint.xsOnly
        ? 'dialog-bottom-transition'
        : 'scale-transition'
    },
    emailValid() {
      if (this.validateEmail(this.emailAddress)) {
        return true
      }
      return false
    },
  },
  watch: {
    quickshare(val) {
      if (val) {
        this.chartViewer = false
        this.chartAdministrator = false
      }
    },
    chartAdministrator(val) {
      if (val) {
        this.chartViewer = false
        this.quickshare = false
      }
    },
    chartViewer(val) {
      if (val) {
        this.quickshare = false
        this.chartAdministrator = false
      }
    },
  },
  mounted() {
    this.$on('open', this.onOpen)
    this.$on('close', this.OnClose)
  },
  methods: {
    ...mapActions('provider', ['shareChart']),
    ...mapActions('auth', ['userExists', 'addUserToPartner']),
    ...mapActions('patient', ['patientInvite', 'patientQuickshare']),
    toast(type, text) {
      bus.$emit('toast', { type, text })
    },
    cancelOption() {
      console.log('doing something')
      this.cancel()
    },
    checkUser() {},
    addUser() {
      let val = this.emailAddress
      val = val.trim()
      if (this.validateEmail(val)) {
        if (this.quickshare) {
          console.log('boom you are doing a quickshare')
          this.patientQuickshare({
            patient: this.options.patient._id,
            email: this.emailAddress,
          })
            .then((data) => {
              if (data.status === 201) {
                this.toast('success', 'Invite Sent')
                bus.$emit('refreshPatientInviteList')
              } else {
                this.toast('success', 'Invite Sent')
                bus.$emit('refreshPatientInviteList')
              }
              this.cancel()
            })
            .catch((error) => {
              console.log(error)
              this.toast('error', 'Please try again later')
            })
        } else {
          let permissions = {}

          if (this.chartViewer) {
            permissions.viewer = true
          } else {
            permissions.admin = true
          }
          this.patientInvite({
            patient: this.options.patient._id,
            email: this.emailAddress,
            permissions: permissions,
          })
            .then((data) => {
              console.log(data)
              if (data.status === 204) {
                console.log('user has been added directly')
                this.toast('success', 'User Added')
                bus.$emit('refreshCollaboratorList')
              }
              if (data.status === 201) {
                console.log('an invite has been sent')
                this.toast('success', 'Invite Sent')
                bus.$emit('refreshPatientInviteList')
              }

              this.cancel()
            })
            .catch((error) => {
              if (error.response.status === 409) {
                this.toast('error', 'User already associated with patient')
              } else {
                console.log(error)
                this.toast('error', 'Please try again later')
              }
            })
        }
      } else {
        this.toast('error', 'Invalid Email')
      }
    },
    onClose() {
      //this.$refs.emailForm.reset()
      //this.$refs.emailForm.resetValidation()
      this.$refs.emailForm.reset()
      this.$refs.emailForm.resetValidation()
    },
    close() {
      //this.cancel()
    },
    onOpen() {
      console.log('opening')
      console.log(this.options.patient)
      this.$nextTick(() => (this.chartViewer = true))
    },
  },
}
</script>
