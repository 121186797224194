<template>
  <v-app id="app" v-show="appTheme" :style="{ background: $vuetify.theme.themes[theme].background }">
    <idle-dialog ref="idleDialog" />
    <idle-dialog-chart ref="idleDialogChart" />
    <post-auth-notification-dialog ref="postAuthNotificationDialog" />
    <post-auth-notification-error-dialog ref="postAuthNotificationErrorDialog" />
    <SharedRecordsDisclaimerDialog ref="sharedRecordsDisclaimer" />
    <share-chart-dialog ref="shareChartDialog" />

    <!--//////APP TOOLBAR//////-->
    <app-drawer v-if="!this.$route.meta.hideHeader" />
    <v-app-bar
      flat
      style="border-bottom: 1px solid #eceff1; background-color: white"
      v-if="!this.$route.meta.hideHeader && appTheme && !navTest && !this.$route.meta.hideOnlyHeader"
      app
      v-mutate="onMutate"
      extension-height="70"
      clipped-right
      height="64"
    >
      <portal-target class="appBarContentLeft" name="appBarContentLeft" slim />
      <v-spacer></v-spacer>
      <portal-target name="appBarContentRight" slim />

      <portal-target v-if="this.$route.meta.appBarExtension" slot="extension" name="appBarContentExtension" slim />
      <ProfileMenu />
    </v-app-bar>
    <!--//////ROUTER VIEW//////-->

    <v-main>
      <router-view v-if="showPage"></router-view>
    </v-main>

    <!--//////TOAST//////-->
    <v-snackbar v-model="showSnackbar" :color="snackbarType" :timeout="3500">
      <v-layout class="mx-0" row align-center>
        <v-icon v-if="snackbarType == 'success'" class="mr-3" color="white">mdi-check</v-icon>
        <v-icon v-else class="mr-3" color="white">mdi-alert-circle</v-icon>
        <div class="mr-2">{{ snackbarText }}</div>
        <v-spacer></v-spacer>
        <v-btn small color="white" text @click="showSnackbar = false">Close</v-btn>
      </v-layout>
    </v-snackbar>

    <user-notification-card-manager />
  </v-app>
</template>

<style>
/* ::-webkit-scrollbar {
    width: 0px; 
} */
.grayAlternate {
  background-color: #ffffff;
  /* cursor: pointer; */
}
.whiteAlternate {
  background-color: #fafafc;
  /* cursor: pointer; */
}
.notification {
  margin-left: 60px;
}
</style>

<script>
import bus from '@/core/helpers/bus'
import { mapState, mapActions, mapMutations } from 'vuex'
import IdleDialog from '@/components/dialogs/IdleDialog'
import IdleDialogChart from '@/components/dialogs/IdleDialogChart'
import PostAuthNotificationDialog from '@/components/dialogs/PostAuthNotificationDialog'
import SharedRecordsDisclaimerDialog from '@/components/dialogs/SharedRecordsDisclaimerDialog'
import PostAuthNotificationErrorDialog from '@/components/dialogs/PostAuthNotificationErrorDialog'
import ShareChartDialog from '@/components/dialogs/ShareChartDialog'
import HelperMixin from '@/core/mixins/HelperMixin'
import ProfileMenu from '@/components/ProfileMenu'
import UserNotificationCardManager from './components/UserNotificationCardManager.vue'
import AppDrawer from './components/AppDrawer.vue'

export default {
  mixins: [HelperMixin],
  name: 'App',
  components: {
    IdleDialog,
    IdleDialogChart,
    SharedRecordsDisclaimerDialog,
    PostAuthNotificationDialog,
    PostAuthNotificationErrorDialog,
    ShareChartDialog,
    //AppNavigationDrawer,
    ProfileMenu,
    UserNotificationCardManager,
    AppDrawer,
  },

  data: () => ({
    warningTimer: null,
    logoutTimer: null,
    chartCloseWarningTimer: null,
    chartCloseTimer: null,
    activityEvents: ['click', 'mousemove', 'mousedown', 'scroll', 'keypress', 'load'],
    navTest: false,
    fileInputActive: false,
    darkNavBar: true,
    files: [],
    lastActiveTime: null,
    idleAway: null,
    activeTimeInterval: null,
    executeShowIdleDialog: null,
    drawer: false,
    showSnackbar: false,
    snackbarType: '',
    snackbarText: '',
    userMenuItems: [
      { title: 'Account', icon: 'mdi-account-box-outline', action: 'account' },
      {
        title: 'User Groups',
        icon: 'mdi-account-multiple',
        action: 'userGroups',
      },
      { title: 'Log Out', icon: 'mdi-logout', action: 'logOut' },
    ],
    notifications: false,
  }),
  computed: {
    ...mapState('auth', [
      'user',
      'appTitle',
      'authToken',
      'appLocation',
      'subdomainPartnerID',
      'appTheme',
      'subdomain',
      'headerURL',
      'patner',
      'loginURL',
      'themeLoaded',
      'configuration',
      'temporaryAuthToken',
      'tempAuthExpiration',
      'demoFeatures',
    ]),
    ...mapState('patient', ['activeVideoCall']),
    ...mapState('taskboard', ['enrolledPatients']),
    theme() {
      return this.$vuetify.theme.dark ? 'dark' : 'light'
    },

    version() {
      return process.env.VUE_APP_VERSION
    },

    viewerMode() {
      if ((this.user && !this.user.partnerID) || (!this.user && this.temporaryAuthToken)) {
        return true
      }
      return false
    },
    mdCol() {
      if (this.viewerMode) {
        return '10'
      }
      return '12'
    },
    lgCol() {
      if (this.viewerMode) {
        return '8'
      }
      return '12'
    },
    xlCol() {
      if (this.viewerMode) {
        return '6'
      }
      return '12'
    },
    appJustify() {
      if (this.viewerMode) {
        return 'center'
      }
      return 'start'
    },
    appClass() {
      if (this.$route.meta.borderless || this.isMobile) {
        return 'pa-0'
      }
      return null
    },
    appStyle() {
      return null
    },
    appFluid() {
      if (!this.$route.meta.borderless && !this.$route.meta.centerVertical) {
        return true
      }
      return true
    },
    showPage() {
      let show = true

      if (this.$route.meta.requireUser && !this.user && !this.temporaryAuthToken) {
        show = false
      }

      if (this.$route.requirePatient && !this.patient) {
        show = false
      }

      return show
    },
    mobileStyle() {
      if (this.isMobile) {
        return 'background-color: white'
      }
      return ''
    },
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    navBarMobileBottomPadding() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'margin-bottom: 110px;'
      } else {
        return ''
      }
    },
    marginsClass() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'pa-0'
      } else {
        return 'pa-5'
      }
    },
    ...mapState('patient', ['patient']),
    appMenuStyling() {
      if (this.$vuetify.breakpoint.smAndDown) {
        return 'margin-bottom: 110px'
      }
      return null
    },
    pageTitle() {
      return this.$route.meta.displayName
    },
    patientNameForPageTitle() {
      if (this.$route.meta.showPatientBar && this.patient) {
        return this.patient.firstName.charAt(0) + '. ' + this.patient.lastName
      }
      return null
    },
    userMenuTitle() {
      if (this.user) {
        if (this.user.firstName) {
          if (this.user.lastName) {
            return this.user.firstName + ' ' + this.user.lastName
          }
        }
      }
      return 'User Account'
    },
    detectIE() {
      var ua = window.navigator.userAgent
      var trident = ua.indexOf('Trident/')
      if (trident > 0) {
        // IE 11 => return version number
        console.log('this is internet explorer')
        return true
      }
      // other browser
      return false
    },
  },
  methods: {
    ...mapActions('auth', ['signOut', 'updateLocation', 'getSubdomainTheme', 'setTheme', 'setTemporaryToken']),
    ...mapActions('conversation', ['startCheckForUnreadCount', 'stopCheckForUnreadCount']),
    ...mapActions('file', ['addDroppedFiles']),
    ...mapMutations('file', ['setExtractedMetadata', 'setParsingFiles']),
    ...mapActions('patient', ['prepareForSigningOut', 'patientDataAccessLog']),
    ...mapMutations('auth', ['setDemoFeaturesToTrue', 'setViewerMode']),
    resetTimers() {
      clearTimeout(this.warningTimer)
      clearTimeout(this.logoutTimer)
      clearTimeout(this.chartCloseWarningTimer)
      clearTimeout(this.chartCloseTimer)
      this.warningTimer = null
      this.logoutTimer = null
      this.chartCloseTimer = null
      this.chartCloseWarningTimer = null
      this.setTimers()
    },
    setTimers() {
      if (!this.logoutTimer) {
        let vm = this
        this.logoutTimer = setTimeout(function() {
          if (vm.$route.name !== 'Login' && vm.$route.name !== 'Register' && vm.$route.name !== 'RevenueCalculator') {
            vm.callSignout()
          }
        }, 60000 * 30)
      }

      if (!this.warningTimer) {
        let vm = this
        this.warningTimer = setTimeout(function() {
          if (vm.$route.name !== 'Login' && vm.$route.name !== 'Register' && vm.$route.name !== 'RevenueCalculator') {
            vm.$refs.idleDialog.open()
          }
        }, 60000 * 29)
      }
      // thse should default to five

      let defaultChartInactivity = 5
      if (this.configuration && this.configuration.chartInactivity) {
        defaultChartInactivity = this.configuration.chartInactivity
      }

      if (!this.chartCloseTimer) {
        let vm = this
        this.chartCloseTimer = setTimeout(function() {
          if (vm.$route.meta.requirePatient && !vm.activeVideoCall) {
            vm.$router.push({ name: 'Taskboard' })
          }
        }, 60000 * defaultChartInactivity)
      }

      if (!this.chartCloseWarningTimer) {
        let vm = this
        this.chartCloseWarningTimer = setTimeout(function() {
          if (vm.$route.meta.requirePatient && !vm.activeVideoCall) {
            vm.$refs.idleDialogChart.open()
          }
        }, 60000 * (defaultChartInactivity - 1))
      }
    },

    navigateHome() {
      this.$router.push({ name: 'Taskboard' })
    },
    showNavigation() {
      bus.$emit('mobileNavigationToggle')
    },
    toast(text, type) {
      this.showSnackbar = true
      this.snackbarType = type
      this.snackbarText = text
    },
    callSignout() {
      this.prepareForSigningOut()
      this.signOut().then(() => {
        this.$router.push({ name: 'Login' })
        bus.$emit('toast', { type: 'success', text: 'Logged Out' })
      })
    },
    shareChart(patient) {
      this.$refs.shareChartDialog.open({
        patient: patient,
      })
    },
    onMutate() {
      let height = 0
      const toolbar = this.$refs.toolbar
      if (toolbar) {
        height = `${toolbar.$el.offsetHeight}px`
      }
      document.documentElement.style.setProperty('--toolbarHeight', height)
    },
  },
  watch: {
    configuration(val) {
      console.log('configuration is set')
      if (val) {
        this.resetTimers()
      }
    },
    viewerMode(val) {
      this.setViewerMode(val)
    },
    $route(val) {
      if (val.query.demo === 'true') {
        this.setDemoFeaturesToTrue()
      }
      if (val.query.navtest === 'true') {
        this.navTest = true
      }
      if (val.query.token) {
        console.log('this should set a token for the patient')
        console.log(this.parseSignupJWT(val.query.token))
        this.setTemporaryToken(val.query.token)
        this.$refs.sharedRecordsDisclaimer.open()
      }
      if (val.meta.showPatientBar && this.files.length > 0) {
        this.$refs.pendingUploads.open()
      }

      if (val.meta.requirePatient) {
        if (this.user) {
          this.patientDataAccessLog({
            patient: val.params.id,
            destination: val.name,
          })
        }
      }
    },
    appTheme(val) {
      if (val.buttonColor) {
        // this.$vuetify.theme.themes.light.primary = val.buttonColor
        //this.$vuetify.theme.themes.light.background = '#000000'
      }
      if (val.navColor) {
        //this.$vuetify.theme.themes.light.navColor = val.navColor
        //this.$vuetify.theme.themes.light.background = '#000000'
      }
      // set the light or dark
      // if (val.navColor) {
      //   let hex = val.navColor.replace('#', '')
      //   let r = parseInt(hex.substring(0, 2), 16)
      //   let g = parseInt(hex.substring(2, 4), 16)
      //   let b = parseInt(hex.substring(4, 6), 16)

      //   let averageRed = r / 255
      //   let averageGreen = g / 255
      //   let averageBlue = b / 255

      //   let overallAverage = (averageRed + averageGreen + averageBlue) / 3
      //   if (overallAverage > 0.6) {
      //     this.darkNavBar = false
      //   }
      // }
    },
    user(user) {
      if (user && user.partnerID) {
        this.startCheckForUnreadCount({
          orgId: user.partnerID,
          interval: 60 * 1000,
          checkImmediately: true,
        })
      } else {
        this.stopCheckForUnreadCount()
      }
    },
  },
  created() {
    this.setTheme()
    console.log('ver:', this.version)
  },
  mounted() {
    this.activityEvents.forEach(event => {
      window.addEventListener(event, this.resetTimers)
    }, this)

    this.resetTimers()

    bus.$on('toast', data => {
      this.toast(data.text, data.type)
    })
    bus.$on('newChart', () => {
      this.$refs.newChart.open()
    })
    bus.$on('assignChart', patient => {
      console.log(patient)
      this.$refs.assignChart.open({
        patient: patient,
      })
    })
    bus.$on('shareChart', patient => {
      this.shareChart(patient)
    })
    this.onMutate()
  },
}
</script>
