export default {
  version: '0.0.0.16',
  url: 'https://api.onecharthealth.com/patients/:patient/files',
  metricList: {
    MEASUREMENT_BLOOD_GLUCOSE: {
      abbreviation: 'GLU',
      chartType: 'line',
      colors: ['#07A880'],
      commonName: 'Glucose',
      device: 'Blood Glucose Meter',
      icon: 'mdi-water',
      iconColor: 'red',
      longName: 'Blood Glucose',
      order: 3,
      primaryMetric: true,
      units: 'mg/dL',
    },
    MEASUREMENT_BLOOD_PRESSURE: {
      abbreviation: 'BP',
      chartType: 'line',
      colors: ['#008FFB', '#00E396'],
      commonName: 'Blood Pressure',
      device: 'Blood Pressure Cuff',
      icon: 'mdi-heart',
      iconColor: 'red',
      longName: 'Blood Pressure',
      order: 0,
      primaryMetric: true,
      units: '',
    },
    MEASUREMENT_BODY_WEIGHT: {
      abbreviation: 'WT',
      chartType: 'line',
      colors: ['#2D302E'],
      commonName: 'Weight',
      device: 'Scale',
      icon: 'mdi-scale-bathroom',
      iconColor: 'grey darken-4',
      longName: 'Weight',
      order: 2,
      primaryMetric: true,
      units: 'lbs',
    },
    MEASUREMENT_ECG: {
      abbreviation: 'ECG',
      chartType: 'line',
      colors: ['#2D302E'],
      commonName: 'ECG',
      device: 'ECG',
      icon: 'mdi-clipboard-pulse',
      iconColor: 'grey darken-4',
      longName: 'ECG',
      order: 4,
      primaryMetric: true,
      units: '',
    },
    MEASUREMENT_PULSE: {
      abbreviation: 'HR',
      chartType: 'line',
      colors: ['#E80305'],
      commonName: 'Heart Rate',
      device: 'Blood Pressure Cuff',
      icon: 'mdi-heart-pulse',
      iconColor: 'red',
      longName: 'Heart Rate',
      order: 1,
      primaryMetric: false,
      units: 'bpm',
    },
    MEASUREMENT_BLOOD_OXYGEN: {
      abbreviation: 'SpO2',
      chartType: 'line',
      colors: ['#E80305'],
      commonName: 'Blood Oxygen',
      device: 'Pulse Oximiter',
      icon: 'mdi-lungs',
      iconColor: 'red',
      longName: 'Blood Oxygen',
      order: 5,
      primaryMetric: false,
      units: 'SpO2',
    },
  },
}
