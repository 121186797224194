export default {
  setRefreshingDashboard(state, refreshingDashboard) {
    state.refreshingDashboard = refreshingDashboard
  },
  setRefreshingMessages(state, refreshingMessages) {
    state.refreshingMessages = refreshingMessages
  },
  setExportingData(state, bool) {
    state.exportingData = bool
  },
  extraCommit() {
  },
  // user patients
  setUserPatients(state, userPatients) {
    state.userPatients = userPatients
  },
  clearUserPatients(state) {
    state.userPatients = []
    state.userPatientsPageInfo = null
  },
  setEnrolledPatients(state, patients) {
    state.enrolledPatients = patients
  },
  setEnrolledPatientsDictionary(state, patients) {
    state.enrolledPatientsDictionary = patients
  },
  setCounts(state, counts) {
    state.counts = counts
  },
  setPatientMessages(state, messages) {
    state.patientMessages = messages
  },
  setUnreadMessages(state, count) {
    state.unreadMessages = count
  },
  setRefreshingUserPatients(state, userPatients) {
    state.refreshingUserPatients = userPatients
  },
  setDashboardTab(state, tab) {
    state.dashboardTab = tab
  },
  setPatientTags(state, tags) {
    state.patientTags = tags
  },
  setTodayTasks(state, tasks) {
    state.todayTasks = tasks
  },
  setTodayTasksToggle(state, toggle) {
    state.todayTasksToggle = toggle
  },
  setPatientsWithUnreadMessages(state, num) {
    state.patientsWithUnreadMessages = num
  },
  setPatientsWithMessages(state, patients) {
    state.patientsWithMessages = patients
  },
  setDashboardFreshness(state, freshness) {
    state.dashboardFreshness = freshness
  },
  setPatientsWithUnseenBreaches(state, num) {
    state.patientsWithUnseenBreaches = num
  },
  setPatientsWithBreaches(state, patients) {
    state.patientsWithBreaches = patients
  },
  setPatientsNeedingOutreach(state, patients) {
    state.patientsNeedingOutreach = patients
  },
  setPatientsAtRisk(state, patients) {
    state.patientsAtRisk = patients
  }
}
