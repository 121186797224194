import Vue from 'vue'
import consts from '@/consts'

export default {
  generateVideoRoom({ commit }) {
    let url = '/VideoRooms'
    return Vue.axios.post(url).then(data => {
      let inviteURL = data.data.inviteeShortURL
      commit('setActiveVideoCallToken', data.data.selfToken)
      commit('setActiveVideoCallInviteeLink', inviteURL)
      commit('setInviteeToken', data.data.inviteeToken)
      return data
    })
  },
  clearVideoRoomInformation({ commit }) {
    commit('setActiveVideoCallToken', null)
    commit('setActiveVideoCallInviteeLink', null)
    commit('setInviteeToken', null)
  },
  // documentTelehealthSession({ commit }) {

  // },
  applyTextExpansionToString({ patient, message }) {
    let patientFirstNameParameter = '{patient_first_name}'

    let patientFullNameParameter = '{patient_full_name}'

    let measurementListParameter = '{vitals_list}'

    let firstNameReplacement = patient.firstName

    let fullNameReplacement = patient.firstName + ' ' + patient.lastName

    let vitalsListReplacement = ''

    let patientVitals = []
    Object.entries(consts.metricList).forEach(metric => {
      const [name, details] = metric
      if (patient.latestEvents[name] && details.primaryMetric) {
        patientVitals.push(details.commonName.toLowerCase())
      }
    })
    if (patientVitals.length > 1) {
      const last = patientVitals.pop()
      vitalsListReplacement = patientVitals.join(', ') + ' and ' + last
    } else if (patientVitals.length === 1) {
      vitalsListReplacement = patientVitals[0]
    } else {
      vitalsListReplacement = ''
    }

    let result = message.replaceAll(
      patientFirstNameParameter,
      firstNameReplacement
    )

    result = result.replaceAll(patientFullNameParameter, fullNameReplacement)

    result = result.replaceAll(measurementListParameter, vitalsListReplacement)

    return result
  },
  saveATextCampaignTemplate({ rootState, dispatch }, { title, content }) {
    let ObjectID = require('bson-objectid')

    let uniqueMongoID = ObjectID().toString()

    let messageTemplates = {}

    if (rootState.auth.configuration.messageTemplates) {
      messageTemplates = rootState.auth.configuration.messageTemplates
    }

    messageTemplates[uniqueMongoID] = {
      title: title,
      content: content,
      id: uniqueMongoID,
    }

    dispatch(
      'auth/updatePartnerSettings',
      {
        partner: rootState.auth.user.partnerID,
        update: { messageTemplates: messageTemplates },
      },
      { root: true }
    )
  },
  deleteACampaignTemplate({ rootState, dispatch }, template) {
    let messageTemplates = rootState.auth.configuration.messageTemplates

    delete messageTemplates[template.id]

    return dispatch(
      'auth/updatePartnerSettings',
      {
        partner: rootState.auth.user.partnerID,
        update: { messageTemplates: messageTemplates },
      },
      { root: true }
    )
  },
}
