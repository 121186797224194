export default {
  // UPDATED FOR TASKBOARD
  patient: null,
  // BELOW HERE IS LEGACY/NEEDS CLEANUP
  patients: [],
  sharedPatient: null,
  sharedFiles: null,
  temporaryAuthToken: null,
  shareExpired: false,
  files: [],
  isLoadingFiles: false,
  requests: null,
  medResults: [],
  probResults: [],
  permissions: [],
  individualPermissions: [],
  preliminaryPermissions: [],
  patientsCount: 0,
  patientsOffset: 0,
  patientsNext: 0,
  patientsTotal: 0,
  searchTermLastName: null,
  searchTermFirstName: null,
  searchTermBirthdate: null,
  searchOrder: null,
  externalUploadLink: null,
  sharedFilesExpiration: null,
  linkExpired: false,
  isLoadingPatients: false,
  requestForPublicUpload: null,
  selectedFileDetails: null,
  searchingForPatients: false,
  numberOfPatientsForUser: 0,
  partnerPatientIDSearchResults: null,
  isSearchingChart: false,
  chartSearchResults: null,
  chartSearchTerm: null,
  patientEditingMode: false,
  totalPatientCount: null,
  // patients list and search management
  totalAccessablePatients: null,
  dashboardPageInfo: null,
  refreshingDashboard: false,
  searchResultsPatients: [],
  searchResultsPageInfo: null,
  searchTerm: null,
  performingSearch: false,
  defaultSearchResultsPatientsPerPage: 50,
  // Request list management
  requestsList: [],
  loadingRequests: false,
  // Followers list management,
  loadingFollowers: false,
  // logs list management,
  patientLogs: [],
  patientLogsPageInfo: null,
  defaultLogsPerPage: 10,
  refreshingLogs: false,
  // patient info form
  gettingPatientInfo: false,
  // problemList
  lookingUpProblem: false,
  lookingUpMedication: false,
  updatingPatientThresholds: false,
  displayedChartNotes: 20,
  // video calls
  activeVideoCall: false,
  activeVideoCallSelfLink: null,
  activeVideoCallInviteeLink: null,
  lastChartReview: null,
}
