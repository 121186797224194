export default {
  preparedMetrics: [],
  loadingMetrics: false,
  viableList: [],
  gradingPatients: false,
  arrayOfDaysOfData: [],
  daysOfDataDict: {},
  daysOfDataWatcher: null,
  individualMetrics: [],
  combinedSeries: [],
}
