export default {
  setActiveVideoCall(state, active) {
    state.activeVideoCall = active
    if (!active) {
      state.activeVideoCallSelfLink = null
      state.activeVideoCallInviteeLink = null
    }
  },
  setActiveVideoCallToken(state, token) {
    state.activeVideoCallToken = token
  },
  setInviteeToken(state, token) {
    state.activeInviteeToken = token
  },
  setActiveVideoCallInviteeLink(state, link) {
    state.activeVideoCallInviteeLink = link
  },
}
