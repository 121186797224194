import Vue from 'vue'

export default {
  async getEvent({ commit, rootState }, eventType) {
    // this pulls the last 12 months of data for the given event type

    let yearStart = new Date()
    yearStart.setHours(0, 0, 0, 0)
    yearStart.setDate(yearStart.getDate() - 365)

    let { data } = await Vue.axios.get(
      `/Patients/${
        rootState.patient.patient._id
      }/PatientEvents?alt=json&order=-timestamp&eventType=${eventType}&start=${yearStart.getTime()}&end=${new Date().getTime()}`
    )
    // normalize the data into [timestamp, value]?
    let events = []
    let systolicEvents = []
    let diastolicEvents = []

    data.items.forEach(event => {
      let value
      let systolicValue
      let diastolicValue

      switch (event.eventType) {
        case 'MEASUREMENT_BLOOD_PRESSURE':
          value = event.eventData.systolicValue + '/' + event.eventData.diastolicValue
          systolicValue = event.eventData.systolicValue
          diastolicValue = event.eventData.diastolicValue
          break
        case 'MEASUREMENT_BODY_WEIGHT':
          value = Math.round(event.eventData.value * 2.205 * 10) / 10
          break
        case 'MEASUREMENT_BLOOD_GLUCOSE':
          value = Math.round(event.eventData.value * 100) / 100
          break
        case 'MEASUREMENT_BODY_TEMPERATURE':
          value = Math.round(((event.eventData.value * (9 / 5) + 32) * 100) / 100)
          break
        case 'MEASUREMENT_PULSE':
          value = Math.round(event.eventData.value)
          break
        case 'MEASUREMENT_ECG':
          value = 1
          break
        default:
          value = event.eventData.value
      }

      let pairedValue = {
        x: event.timestamp,
        y: value,
        fullEvent: event,
        alert: event.alert,
      }
      events.push(pairedValue)

      let startOfDate = new Date(event.timestamp)
      startOfDate.setHours(0, 0, 0, 0)
      commit('insertIntoDaysOfDataDict', {
        date: startOfDate.getTime(),
        type: event.eventType,
      })
      // handle the blood pressure case
      if (eventType === 'MEASUREMENT_BLOOD_PRESSURE') {
        systolicEvents.push({
          x: event.timestamp,
          y: systolicValue,
          fullEvent: event,
          alert: event.alert,
        })
        diastolicEvents.push({
          x: event.timestamp,
          y: diastolicValue,
          fullEvent: event,
          alert: event.alert,
        })
      }
    })

    if (eventType === 'MEASUREMENT_BLOOD_PRESSURE') {
      commit('addCombinedSeries', {
        name: eventType + '_SYSTOLIC',
        data: systolicEvents,
        latestEvent: rootState.patient.patient.latestEvents[eventType],
      })

      commit('addCombinedSeries', {
        name: eventType + '_DIASTOLIC',
        data: diastolicEvents,
        latestEvent: rootState.patient.patient.latestEvents[eventType],
      })

      commit('addIndividualMetrics', {
        series: [
          {
            name: eventType + '_SYSTOLIC',
            data: systolicEvents,
          },
          {
            name: eventType + '_DIASTOLIC',
            data: diastolicEvents,
          },
        ],
        latestEvent: rootState.patient.patient.latestEvents[eventType],
        eventType: eventType,
        hidden: false,
      })
    } else {
      commit('addCombinedSeries', {
        name: eventType,
        data: events,
        latestEvent: rootState.patient.patient.latestEvents[eventType],
      })
      commit('addIndividualMetrics', {
        series: [
          {
            name: eventType,
            data: events,
          },
        ],
        latestEvent: rootState.patient.patient.latestEvents[eventType],
        eventType: eventType,
        hidden: false,
      })
    }

    return rootState.patient.patient.latestEvents[eventType]
  },
  getEventsForRange({ commit, dispatch, rootState }, { id, metricName, end, start }) {
    let eventDict = {}
    if (
      rootState &&
      rootState.auth &&
      rootState.auth.appTheme.metricList &&
      rootState.auth.appTheme.metricList[metricName]
    ) {
      let keys = Object.keys(rootState.auth.appTheme.metricList[metricName])
      keys.forEach(key => {
        eventDict[key] = rootState.auth.appTheme.metricList[metricName][key]
      })
    }

    commit('extraCommit', null)
    let url =
      '/Patients/' +
      id +
      '/PatientEvents?/events?alt=json&order=-timestamp&eventType=' +
      metricName +
      '&start=' +
      start +
      '&end=' +
      end
    return Vue.axios.get(url).then(data => {
      // i should prepare the series right here
      // eventDict series switch

      eventDict.alert = false
      if (data.data.items.length > 0 && data.data.items[0].alert) {
        eventDict.alert = true
      }
      eventDict.series = []
      switch (metricName) {
        case 'MEASUREMENT_BLOOD_PRESSURE':
          eventDict.series.push({ name: 'systolic', data: [] })
          eventDict.series.push({ name: 'diastolic', data: [] })
          break
        case 'MEASUREMENT_BODY_WEIGHT':
          eventDict.series.push({ name: 'value', data: [] })
          break
        case 'MEASUREMENT_BLOOD_GLUCOSE':
          eventDict.series.push({ name: 'value', data: [] })
          break
        case 'MEASUREMENT_BODY_TEMPERATURE':
          eventDict.series.push({ name: 'value', data: [] })
          break
        case 'MEASUREMENT_PULSE':
          eventDict.series.push({ name: 'value', data: [] })
          break
      }
      eventDict.events = []
      eventDict.eventProtos = []
      eventDict.referenceValues = []
      data.data.items.forEach(event => {
        let startOfDate = new Date(event.timestamp)
        startOfDate.setHours(0, 0, 0, 0)
        commit('insertIntoDaysOfDataDict', {
          date: startOfDate.getTime(),
          type: event.eventType,
        })

        switch (event.eventType) {
          case 'MEASUREMENT_BLOOD_PRESSURE':
            event.systolic = event.eventData.systolicValue
            event.diastolic = event.eventData.diastolicValue
            event.displayValue = event.eventData.systolicValue + '/' + event.eventData.diastolicValue
            eventDict.series[0].data.push([event.timestamp, event.eventData.systolicValue, event.alert])
            eventDict.series[1].data.push([event.timestamp, event.eventData.diastolicValue, event.alert])
            break
          case 'MEASUREMENT_BODY_WEIGHT':
            event.displayValue = Math.round(event.eventData.value * 2.205 * 10) / 10
            eventDict.series[0].data.push([event.timestamp, event.displayValue, event.alert])
            break
          case 'MEASUREMENT_BLOOD_GLUCOSE':
            event.displayValue = Math.round(event.eventData.value * 100) / 100
            eventDict.series[0].data.push([event.timestamp, event.displayValue, event.alert])
            break
          case 'MEASUREMENT_BODY_TEMPERATURE':
            event.displayValue = Math.round(((event.eventData.value * (9 / 5) + 32) * 100) / 100)
            eventDict.series[0].data.push([event.timestamp, event.displayValue, event.alert])
            break
          case 'MEASUREMENT_PULSE':
            event.displayValue = Math.round(event.eventData.value)
            eventDict.series[0].data.push([event.timestamp, event.displayValue, event.alert])
            break
        }

        eventDict.events.push(event)
        eventDict.eventProtos.push(event)
        eventDict.referenceValues.push(event)
        // the most recent event should become the latest event
        if (!eventDict.latestEvent || eventDict.latestEvent.timestamp < event.timestamp) {
          eventDict.latestEvent = event
        }
      })

      eventDict.eventType = metricName

      dispatch('stats', eventDict).then(returned => {
        eventDict.stats = returned
      })

      return eventDict
    })
  },
  gradePatientOnEngagement({ commit, dispatch }, patient) {
    function msToTime(s) {
      if (!s) {
        return '00:00:00'
      }
      let seconds = Math.floor((s / 1000) % 60)
      let minutes = Math.floor((s / (1000 * 60)) % 60)
      let hours = Math.floor(s / (1000 * 60 * 60))

      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds

      return hours + ':' + minutes + ':' + seconds
    }

    // first decide what is the start of the period and the end of he period
    // this is if we are basing it off of when the patien took their first reading.
    if (patient.rpm.enrollment.firstData) {
      // this tells me when the next 99454 is up
      patient.firstData = new Date(patient.rpm.enrollment.firstData).getTime()
      patient.ranges = []

      let periodStart = new Date(patient.firstData)
      periodStart.setHours(0, 0, 0, 0)

      let rightNow = new Date()

      patient.monitoringPeriods = []

      while (periodStart.getTime() < rightNow.getTime()) {
        // this will add thirty days to the period start. when this ends, period start will be the start of NEXT period.
        let start = periodStart.getTime()
        periodStart.setDate(periodStart.getDate() + 30)
        let end = periodStart.getTime() - 1
        patient.monitoringPeriods.push({ start: start, end: end })
      }

      patient.startOfThisPeriod = patient.monitoringPeriods[patient.monitoringPeriods.length - 1].start
      patient.endOfThisPeriod = patient.monitoringPeriods[patient.monitoringPeriods.length - 1].end

      // what if periods were 30 days from the start of the month?
      // what about feb?
    }

    // this is if we are basing it off the month
    // not every month has 30 days (dang february) so we need to calculate 12 billing dates.
    // january billing date for 99454 is jan 1 - 30
    // feb billing dare for 99454 is Jan 31 - Mar 1
    // mar billing date for 99454 is mar 2 -

    // get the last year of periods.

    function getShortDateFromTimestamp(timestamp) {
      let d = new Date(timestamp)
      return d.getMonth() + 1 + '/' + d.getDate()
    }

    let viability = { patient: patient._id }

    if (patient.millisecondsThisPeriod > 1200000 - 120000 && patient.millisecondsThisPeriod < 1200000) {
      // youre close on time
      viability.closeOnTime = true

      viability.qhpActionNeeded =
        patient.firstName + ' has accrued ' + msToTime(patient.millisecondsThisPeriod) + ' QHP time so far this month. '
    }
    commit('extraCommit')
    // this will simply tell how many days of readings a patient has in the current period
    // we can eliminate a patient if the last reading is more than 16 days
    // we need to know today
    // let today = new Date()
    // today.setTime(23,59,59,999)
    // let todayStamp = today.getTime()

    // we need to know the end of the period
    // end of this period

    // we need to know the last data
    // let dateOfLastData = new Date(patient.rpmMostRecentData)
    // dateOfLastData.setTime(23, 59, 59, 999)

    // the difference in days

    let dayDict = {}
    let count = 0
    if (
      patient.startOfThisPeriod &&
      patient.endOfThisPeriod &&
      patient.patientMetricList &&
      patient.patientMetricList.length > 0
    ) {
      patient.patientMetricList.forEach(metric => {
        // go get the metrics during this time period
        let url =
          '/Patients/' +
          patient._id +
          '/PatientEvents?/events?alt=json&order=-timestamp&eventType=' +
          metric +
          '&start=' +
          patient.startOfThisPeriod +
          '&end=' +
          patient.endOfThisPeriod

        return Vue.axios
          .get(url)
          .then(data => {
            count = count + 1
            data.data.items.forEach(event => {
              // divide the data into days.
              let day = new Date(event.timestamp)

              day.setHours(12, 0, 0, 999)
              if (event.eventType != 'RPM_Note') {
                dayDict[day.getTime()] = 'reading'
              }
            })

            if (count === patient.patientMetricList.length) {
              // you ent through all the metrics

              let endOfToday = new Date()

              endOfToday.setHours(0, 0, 0, 0)

              var Difference_In_Time = endOfToday.getTime() - patient.startOfThisPeriod

              // To calculate the no. of days between two dates
              var Difference_In_Days = Difference_In_Time / (1000 * 3600 * 24)

              let percentElapsed = Difference_In_Days / 30

              let keys = Object.keys(dayDict)

              let percentCompleted = keys.length / 16

              let daysLeftInPeriod = Math.ceil(30 - Difference_In_Days)
              // if the patient took one today, take today off?

              let daysNeeded = 16 - keys.length

              viability.daysNeeded = daysNeeded

              viability.daysLeftInPeriod = daysLeftInPeriod

              let patientKeys = Object.keys(patient)

              patientKeys.forEach(key => {
                viability[key] = patient[key]
              })

              if (percentCompleted > percentElapsed) {
                viability.isOnTrack = true
              } else {
                viability.isOnTrack = false
              }

              if (daysLeftInPeriod >= daysNeeded) {
                viability.isViableThisPeriod = true
              } else {
                // this patient is lost
                viability.isViableThisPeriod = false
              }

              viability.rank = viability.daysNeeded / viability.daysLeftInPeriod

              viability.progress = percentCompleted * 100

              if (viability.rank < 0.8) {
                //rank should be the days left
                viability.rank = (1 - daysNeeded * 0.01) * 0.8
              }
              viability.urgency = 100 - viability.daysLeftInPeriod

              viability.firstName = patient.firstName
              viability.lastName = patient.lastName
              viability.age = patient.age
              viability.displayBirthdate = patient.displayBirthdate
              viability.firstData = new Date(patient.rpm.enrollment.firstData)

              let days = 'days'
              if (viability.daysNeeded === 1) {
                days = 'day'
              }

              let days2 = 'days'
              if (keys.length === 1) {
                days2 = 'day'
              }

              viability.actionNeeded =
                patient.firstName +
                ' needs ' +
                viability.daysNeeded +
                ' more ' +
                days +
                ' of data by ' +
                getShortDateFromTimestamp(patient.endOfThisPeriod) +
                '  to qualify.'

              if (keys.length > 0) {
                viability.lessUrgent =
                  patient.firstName +
                  ' only has ' +
                  keys.length +
                  ' ' +
                  days2 +
                  ' of data for the period ending on ' +
                  getShortDateFromTimestamp(patient.endOfThisPeriod) +
                  '.'
              } else {
                viability.lessUrgent =
                  patient.firstName +
                  " hasn't taken any data so far this period. The period ends on " +
                  getShortDateFromTimestamp(patient.endOfThisPeriod) +
                  '.'
              }
              // get the most recent rpm note?

              // determine if this patient is viable

              let beginningOfToday = new Date()
              beginningOfToday.setHours(0, 0, 0, 0)
              let timeLeftInCurrentBillingPeriodIncludingToday = patient.endOfThisPeriod - beginningOfToday.getTime()
              let rawDaysLeftInCurrentPeriodIncludingToday =
                timeLeftInCurrentBillingPeriodIncludingToday / (1000 * 3600 * 24)
              let daysLeftInCurrentPeriodIncludingToday = Math.ceil(rawDaysLeftInCurrentPeriodIncludingToday)
              viability.daysRemainingInCurrentPeriod = daysLeftInCurrentPeriodIncludingToday
              viability.daysToEnd = daysLeftInCurrentPeriodIncludingToday
              daysLeftInPeriod = daysLeftInCurrentPeriodIncludingToday
              // get the number of days elapsed

              let daysOfDataList = Object.keys(dayDict)
              viability.daysOfDataInCurrentPeriod = daysOfDataList.length
              viability.daysNeeded = 16 - daysOfDataList.length
              daysNeeded = 16 - daysOfDataList.length

              // if a patient has taken a reading today, days left in period should go down by one?
              let startOfToday = new Date()
              if (patient.rpmMostRecentData) {
                startOfToday.setHours(0, 0, 0, 0)
                let lastReading = new Date(patient.rpmMostRecentData).getTime()

                viability.tookReadingToday = startOfToday.getTime() < lastReading
                viability.tookReadingRecently = lastReading > startOfToday.getTime() - 86400000 * 30
              }
              if (viability.tookReadingToday) {
                daysLeftInPeriod = daysLeftInPeriod - 1
              }

              if (daysLeftInPeriod >= daysNeeded) {
                viability.isViableThisPeriod = true
              } else {
                // this patient is lost
                viability.isViableThisPeriod = false
              }

              percentCompleted = keys.length / 16

              //

              if (
                (viability.rank > 0.9 && viability.isViableThisPeriod && viability.daysNeeded > 0) ||
                (viability.daysNeeded < 5 && viability.daysNeeded > 0)
              ) {
                // this will be critical today
                return dispatch('notes/getJustTheLastNote', patient._id, {
                  root: true,
                })
                  .then(note => {
                    viability.lastNote = note
                    if (note.timestamp > startOfToday.getTime()) {
                      viability.noteToday = true
                    }
                    commit('newViable', viability)
                  })
                  .catch(error => {
                    console.log(error)
                    commit('newViable', viability)
                  })
              } else {
                commit('newViable', viability)
              }
            }
          })
          .catch(error => {
            console.log(error)
            commit('newViable', viability)
          })
      })
    } else {
      let patientKeys = Object.keys(patient)

      patientKeys.forEach(key => {
        viability[key] = patient[key]
      })
      commit('newViable', viability)
    }
  },
  getEventsForRange2({ commit, rootState }, { id, metricName, end, start }) {
    let eventDict = {}
    if (
      rootState &&
      rootState.auth &&
      rootState.auth.appTheme.metricList &&
      rootState.auth.appTheme.metricList[metricName]
    ) {
      eventDict = rootState.auth.appTheme.metricList[metricName]
    }

    commit('extraCommit', null)
    let url =
      '/Patients/' +
      id +
      '/PatientEvents?/events?alt=json&order=-timestamp&eventType=' +
      metricName +
      '&start=' +
      start +
      '&end=' +
      end
    return Vue.axios.get(url).then(data => {
      //let events = []
      // data.data.items.forEach(event => {
      //   return dispatch('prepareEventData', event,).then( preparedData => {
      //     events.push(preparedData)

      //    })
      // })

      eventDict.events = data.data.items
      eventDict.eventProtos = data.data.items
      eventDict.eventType = metricName

      // dispatch('stats', eventDict,).then( returned => {
      //     eventDict.stats = returned
      //    })

      return eventDict
    })
  },

  stats({ commit, dispatch }, metricWithData) {
    commit('extraCommit', null)
    // break this into one year stats, six month stats, 3 month and one month

    let events = metricWithData.eventProtos

    let sixMonthStart = new Date()
    sixMonthStart.setHours(0, 0, 0, 0)
    sixMonthStart.setDate(sixMonthStart.getDate() - 31 * 6)

    let threeMonthStart = new Date()
    threeMonthStart.setHours(0, 0, 0, 0)
    threeMonthStart.setDate(threeMonthStart.getDate() - 31 * 3)

    let oneMonthStart = new Date()
    oneMonthStart.setHours(0, 0, 0, 0)
    oneMonthStart.setDate(oneMonthStart.getDate() - 31 * 1)

    let todayEnd = new Date()
    todayEnd.setHours(23, 59, 59, 999)

    let yearStats = {
      xForRegression: [],
      yForRegression: [],
      title: 'Year Summary',
      average: null,
      highValue: null,
      lowValue: null,
      high: null,
      low: null,
      count: 0,
      total: 0,
      totalSys: 0,
      totalDi: 0,
      cutoff: null,
    }
    let sixMonthStats = {
      xForRegression: [],
      yForRegression: [],
      title: '6 Month Summary',
      average: null,
      highValue: null,
      lowValue: null,
      high: null,
      low: null,
      count: 0,
      total: 0,
      totalSys: 0,
      totalDi: 0,
      cutoff: sixMonthStart.getTime(),
    }
    let threeMonthStats = {
      xForRegression: [],
      yForRegression: [],
      title: '3 Month Summary',
      average: null,
      highValue: null,
      lowValue: null,
      high: null,
      low: null,
      count: 0,
      total: 0,
      totalSys: 0,
      totalDi: 0,
      cutoff: threeMonthStart.getTime(),
    }
    let oneMonthStats = {
      xForRegression: [],
      yForRegression: [],
      title: '1 Month Summary',
      average: null,
      highValue: null,
      lowValue: null,
      high: null,
      low: null,
      count: 0,
      total: 0,
      totalSys: 0,
      totalDi: 0,
      cutoff: oneMonthStart.getTime(),
    }
    let count = 0

    events.forEach(measurement => {
      count = count + 1
      // first see what date it falls into
      let statsBuckets = []
      statsBuckets.push(yearStats)

      if (measurement.timestamp > sixMonthStats.cutoff) {
        // this should be calculated as a year thing
        statsBuckets.push(sixMonthStats)
      }

      if (measurement.timestamp > threeMonthStats.cutoff) {
        // this should be calculated as a year thing
        statsBuckets.push(threeMonthStats)
      }

      if (measurement.timestamp > oneMonthStats.cutoff) {
        // this should be calculated as a year thing
        statsBuckets.push(oneMonthStats)
      }

      statsBuckets.forEach(statsBucket => {
        statsBucket.xForRegression.push(measurement.timestamp)

        if (metricWithData.eventType === 'MEASUREMENT_BLOOD_PRESSURE') {
          statsBucket.yForRegression.push(measurement.eventData.systolicValue)
          if (!statsBucket.high || measurement.eventData.systolicValue > statsBucket.high) {
            statsBucket.high = measurement.eventData.systolicValue
            statsBucket.highValue = measurement.eventData.systolicValue + '/' + measurement.eventData.diastolicValue
            statsBucket.correspondingHighDiastolic = measurement.eventData.diastolicValue
          }

          if (!statsBucket.low || measurement.eventData.systolicValue < statsBucket.low) {
            statsBucket.low = measurement.eventData.systolicValue
            statsBucket.lowValue = measurement.eventData.systolicValue + '/' + measurement.eventData.diastolicValue
            statsBucket.correspondingLowDiastolic = measurement.eventData.diastolicValue
          }

          statsBucket.totalSys = statsBucket.totalSys + measurement.eventData.systolicValue
          statsBucket.totalDi = statsBucket.totalDi + measurement.eventData.diastolicValue
          statsBucket.count = statsBucket.count + 1

          statsBucket.average = statsBucket.totalSys / statsBucket.count + '/' + statsBucket.totalDi / statsBucket.count
        } else {
          // expect one value
          statsBucket.yForRegression.push(measurement.eventData.value)
          if (!statsBucket.high || measurement.eventData.value > statsBucket.high) {
            statsBucket.high = measurement.eventData.value
            statsBucket.highValue = measurement.eventData.value
          }

          if (!statsBucket.low || measurement.eventData.value < statsBucket.low) {
            statsBucket.low = measurement.eventData.value
            statsBucket.lowValue = measurement.eventData.value
          }

          statsBucket.total = statsBucket.total + measurement.eventData.value
          statsBucket.count = statsBucket.count + 1

          statsBucket.average = statsBucket.total / statsBucket.count
        }
        // make the data prettier? this is copied from the prepare metrics thing....
        switch (metricWithData.eventType) {
          case 'MEASUREMENT_BLOOD_PRESSURE':
            statsBucket.highValue =
              Math.round(statsBucket.high * 10) / 10 +
              '/' +
              Math.round(statsBucket.correspondingHighDiastolic * 10) / 10
            statsBucket.lowValue =
              Math.round(statsBucket.low * 10) / 10 + '/' + Math.round(statsBucket.correspondingLowDiastolic * 10) / 10
            statsBucket.average =
              Math.round(((statsBucket.totalSys / statsBucket.count) * 10) / 10) +
              '/' +
              Math.round(((statsBucket.totalDi / statsBucket.count) * 10) / 10)

            break
          case 'MEASUREMENT_BODY_WEIGHT':
            statsBucket.highValue = Math.round((statsBucket.high * 2.205 * 10) / 10)
            statsBucket.lowValue = Math.round((statsBucket.low * 2.205 * 10) / 10)
            statsBucket.average = Math.round((statsBucket.average * 2.205 * 10) / 10)
            break
          case 'MEASUREMENT_BLOOD_GLUCOSE':
            statsBucket.highValue = Math.round((statsBucket.high * 10) / 10)
            statsBucket.lowValue = Math.round((statsBucket.low * 10) / 10)
            statsBucket.average = Math.round((statsBucket.average * 10) / 10)
            break
          case 'MEASUREMENT_BODY_TEMPERATURE':
            statsBucket.highValue = Math.round(((statsBucket.high * (9 / 5) + 32) * 100) / 100)
            statsBucket.lowValue = Math.round(((statsBucket.low * (9 / 5) + 32) * 100) / 100)
            statsBucket.average = Math.round(((statsBucket.average * (9 / 5) + 32) * 100) / 100)
            break
          case 'MEASUREMENT_PULSE':
            statsBucket.highValue = Math.round((statsBucket.high * 10) / 10)
            statsBucket.lowValue = Math.round((statsBucket.low * 10) / 10)
            statsBucket.average = Math.round((statsBucket.average * 10) / 10)
            break
        }
      })
    })

    dispatch('linearRegression', yearStats).then(data => {
      yearStats.r2 = data.r2
      yearStats.slope = data.slope
      yearStats.intercept = data.intercept
    })

    dispatch('linearRegression', sixMonthStats).then(data => {
      sixMonthStats.r2 = data.r2
      sixMonthStats.slope = data.slope
      sixMonthStats.intercept = data.intercept
    })

    dispatch('linearRegression', threeMonthStats).then(data => {
      threeMonthStats.r2 = data.r2
      threeMonthStats.slope = data.slope
      threeMonthStats.intercept = data.intercept
    })

    dispatch('linearRegression', oneMonthStats).then(data => {
      oneMonthStats.r2 = data.r2
      oneMonthStats.slope = data.slope
      oneMonthStats.intercept = data.intercept
    })
    return {
      yearStats: yearStats,
      sixMonthStats: sixMonthStats,
      threeMonthStats: threeMonthStats,
      oneMonthStats: oneMonthStats,
    }
  },
  linearRegression({ commit }, payload) {
    commit('extraCommit', null)
    let x = payload.xForRegression
    let y = payload.yForRegression
    var lr = {}
    var n = y.length
    var sum_x = 0
    var sum_y = 0
    var sum_xy = 0
    var sum_xx = 0
    var sum_yy = 0

    for (var i = 0; i < y.length; i++) {
      sum_x += x[i]
      sum_y += y[i]
      sum_xy += x[i] * y[i]
      sum_xx += x[i] * x[i]
      sum_yy += y[i] * y[i]
    }

    // so the x in this case is in milliseconds. to get it to days (which is actualy useful) i have to divide the slope by milisecondsin 24 hours

    lr['slope'] = (n * sum_xy - sum_x * sum_y) / (n * sum_xx - sum_x * sum_x)
    lr['daySlope'] = lr.slope / 86400000
    lr['intercept'] = (sum_y - lr.slope * sum_x) / n
    lr['r2'] = Math.pow(
      (n * sum_xy - sum_x * sum_y) / Math.sqrt((n * sum_xx - sum_x * sum_x) * (n * sum_yy - sum_y * sum_y)),
      2
    )

    return lr
  },
  prepareEventData({ commit, rootState }, event) {
    let eventDict = {}
    if (
      rootState &&
      rootState.auth &&
      rootState.auth.appTheme.metricList &&
      rootState.auth.appTheme.metricList[event.eventType]
    ) {
      eventDict = rootState.auth.appTheme.metricList[event.eventType]
    }
    commit('extraCommit', null)
    let objectToReturn = {}

    let keys = Object.keys(event)
    keys.forEach(key => {
      objectToReturn[key] = event[key]
    })

    objectToReturn.metric = event.eventType
    objectToReturn.name = event.eventType
    objectToReturn.displayValue = '-'
    objectToReturn.timestamp = event.timestamp
    objectToReturn.source = event.peripheralName
    objectToReturn.dashboardAbbreviation = eventDict.dashboardAbbreviation
    objectToReturn.commonName = eventDict.commonName
    // handle formatting for out of bound events
    if (event.alert) {
      objectToReturn.alert = true
    }
    if (event.alertMsg) {
      objectToReturn.alertMsg = event.alertMsg
    }

    // handle specific formatting for events
    if (event.eventType === 'MEASUREMENT_BLOOD_PRESSURE') {
      objectToReturn.systolic = 0
      objectToReturn.diastolic = 0
    }

    switch (event.eventType) {
      case 'MEASUREMENT_BLOOD_PRESSURE':
        objectToReturn.systolic = event.eventData.systolicValue
        objectToReturn.diastolic = event.eventData.diastolicValue
        objectToReturn.displayValue = event.eventData.systolicValue + '/' + event.eventData.diastolicValue
        break
      case 'MEASUREMENT_BODY_WEIGHT':
        objectToReturn.displayValue = Math.round(event.eventData.value * 2.205 * 10) / 10
        objectToReturn.sortValue = objectToReturn.displayValue
        break
      case 'MEASUREMENT_BLOOD_GLUCOSE':
        objectToReturn.displayValue = Math.round(event.eventData.value * 100) / 100
        break
      case 'MEASUREMENT_BODY_TEMPERATURE':
        objectToReturn.displayValue = Math.round(((event.eventData.value * (9 / 5) + 32) * 100) / 100)
        break
      case 'MEASUREMENT_PULSE':
        objectToReturn.displayValue = event.eventData.value
        break
    }
    objectToReturn.eventString = objectToReturn.displayValue
    if (!Array.isArray(eventDict.units)) {
      objectToReturn.eventString += ' ' + eventDict.units
    }
    let d = new Date(event.timestamp)
    let hours = d.getHours()
    let minutes = d.getMinutes()
    let ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    let strTime = hours + ':' + minutes + ' ' + ampm
    objectToReturn.readableDate =
      ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear() + ' ' + strTime
    return objectToReturn
  },
}
