//import store from '@/store'
// import { getAccessToken } from '../../shared-ui/store/sso'
// import { HttpClient } from '../../shared-ui/helpers/httpClient'

// const requestHandler = async request => {
//   const accessToken = await getAccessToken(true) // force to get new access token
//   if (accessToken) {
//     request.headers['Authorization'] = `Bearer ${accessToken}`
//   } else {
//     store.dispatch('auth/logout', { error: 'Invalid refresh token' })
//   }
//   return request
// }

// const rpmClient = HttpClient.create({
//   baseUrl: process.env.VUE_APP_RPM_API_URL,
//   requestHandler,
// })

export default {
  namespaced: true,
  state: {
    lastCategory: 0,
    dataTableOptions: null,
  },
  mutations: {
    setLastCategory(state, category) {
      state.lastCategory = category
    },
    setDataTableOptions(state, options) {
      state.dataTableOptions = options
    },
  },
  actions: {},
}
