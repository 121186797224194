import { getAccessToken } from '../shared-ui/store/sso'
const rpmApiUrl = process.env.VUE_APP_RPMS_API

export default {
  namespaced: true,
  actions: {
    /**
     * Call this when a patient is not found. If a candidate is found, use that data.
     * Otherwise the person will need to fill out the information.
     */
    async findPatient(_, { ownerPatientId }) {
      let accessToken = await getAccessToken()
      if (!accessToken) {
        console.warn('no access token found')
        return null
      }
      let response = await fetch(`${rpmApiUrl}/patient?ownerPatientId=${ownerPatientId}`, {
        headers: {
          accept: 'application/json',
          'cache-control': 'no-cache',
          authorization: `Bearer ${accessToken}`,
        },
        method: 'GET',
        mode: 'cors',
      })
      const data = await response.json()
      if (response.ok && !data.message) {
        return data.patient
      }
      return null
    },
  },
}
