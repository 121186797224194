import Vue from 'vue'
import parseUrl from '@/core/helpers/parseUrl'
import consts from '@/consts'

export default {
  postSomeBP({ commit }, payload) {
    commit('extraCommit', null)
    let diff = payload.diff
    let id = payload.id

    if (diff === 1) {
      let stamp = Math.round(new Date().getTime() / 1000)
      let payload = {}
      payload.eventType = 'MEASUREMENT_BLOOD_PRESSURE'
      payload.timestamp = stamp
      let systolic = Math.floor(Math.random() * (140 - 130 + 1) + 130)
      let diastolic = systolic - 40
      payload.eventData = { diastolicValue: diastolic, systolicValue: systolic }
      Vue.axios.post('/patients/' + id + '/events', payload)
    } else {
      // give me a month of bp readings.
      let now = Math.round(new Date().getTime() / 1000)
      let i = 33
      while (i > 0) {
        let stamp = now - 86400 * i
        i = i - 1
        let payload = {}
        payload.eventType = 'MEASUREMENT_BLOOD_PRESSURE'
        payload.timestamp = stamp
        let systolic = Math.floor(Math.random() * (140 - 130 + 1) + 130)
        let diastolic = systolic - 40
        payload.eventData = {
          diastolicValue: diastolic,
          systolicValue: systolic
        }
        Vue.axios.post('/patients/' + id + '/events', payload)
      }
    }
  },
  prepareEventData({ commit }, event) {
    let eventDict = {}
    if (consts.metricList[event.eventType]) {
      eventDict = consts.metricList[event.eventType]
    }
    commit('extraCommit', null)
    let objectToReturn = {}

    let keys = Object.keys(event)
      keys.forEach(key => {
        objectToReturn[key] = event[key]
      })

    objectToReturn.metric = event.eventType
    objectToReturn.name = event.eventType
    objectToReturn.displayValue = '-'
    objectToReturn.timestamp = event.timestamp
    objectToReturn.source = event.peripheralName
    objectToReturn.dashboardAbbreviation = eventDict.dashboardAbbreviation
    // handle formatting for out of bound events
    if (event.alert) {
      objectToReturn.alert = true
    }
    if (event.alertMsg) {
      objectToReturn.alertMsg = event.alertMsg
    }

    // handle specific formatting for events
    if (event.eventType === 'MEASUREMENT_BLOOD_PRESSURE') {
      objectToReturn.systolic = 0
      objectToReturn.diastolic = 0
    }

    switch (event.eventType) {
      case 'MEASUREMENT_BLOOD_PRESSURE':
        objectToReturn.systolic = event.eventData.systolicValue
        objectToReturn.diastolic = event.eventData.diastolicValue
        objectToReturn.displayValue = event.eventData.systolicValue + '/' + event.eventData.diastolicValue
        break
      case 'MEASUREMENT_BODY_WEIGHT':
        objectToReturn.displayValue = Math.round((event.eventData.value * 2.205) * 10) / 10
        objectToReturn.sortValue = objectToReturn.displayValue
        break
      case 'MEASUREMENT_BLOOD_GLUCOSE':
        objectToReturn.displayValue = Math.round(event.eventData.value * 100) / 100
        break
      case 'MEASUREMENT_BODY_TEMPERATURE':
        objectToReturn.displayValue = Math.round(((event.eventData.value * (9 / 5) + 32) * 100) / 100)
        break
        case 'MEASUREMENT_PULSE':
          objectToReturn.displayValue = event.eventData.value
          break
    }
    objectToReturn.eventString = objectToReturn.displayValue
    if (!Array.isArray(eventDict.units)) {
      objectToReturn.eventString += ' ' + eventDict.units
    }
    let d = new Date(event.timestamp)
      let hours = d.getHours();
      let minutes = d.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let strTime = hours + ':' + minutes + ' ' + ampm;
    objectToReturn.readableDate = ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear() + ' ' + strTime
    return objectToReturn
  },
  createPatientWithOptions(
    { commit },
    { patient, partnerID, partnerPatientID, partnerGroupID, userID }
  ) {
    commit('extraCommit', null)
    // post the patient, then add the partner, then remove the permissions from the user
    return Vue.axios.post('/patient', patient).then(({ data }) => {
      // patient created, now set partner
      if (partnerID && partnerPatientID) {
        let putURL = '/partners/' + partnerID + '/patients/' + partnerPatientID
        let payload = {}
        payload.patient = data._id
        return Vue.axios.put(putURL, payload).then(() => {
          // partner id set, now grant partner user group all permissions
          let changes = [
            'ALL_FILES_VIEW',
            'VIEW_INFO',
            'VIEW_REQUESTS',
            'EDIT_PERMISSIONS',
            'SHARE_FILES',
            'MAKE_REQUEST',
            'UPLOAD_FILES',
            'EDIT_INFO'
          ]

          let groups = [partnerGroupID]
          let payload2 = [{}]
          groups.forEach((element, index) => {
            payload2[index].userGroup = element
            payload2[index].allow = changes
          })
          let id = data._id
          const url = parseUrl('/patients/:id/acls', { id })
          return Vue.axios.patch(url, payload2).then(() => {
            // now remove the current user's permissions
            let payload3 = [{}]
            payload3[0].user = userID
            payload3[0].allow = []
            return Vue.axios.patch(url, payload3).then(() => {
              return data
            })
          })
        })
      }
    })
  },
  getPartner({ commit }, { id }) {
    commit('extraCommit', null)
    const url = parseUrl('/partners?ids', { ids: [id] })
    return Vue.axios.get(url).then(({ data }) => {
      return data
    })
  },
  clearPatient({ commit }) {
    commit('setPatient', null)
    commit('setFiles', [])
    commit('setPatientLogs', [])
    commit('setRequestsList', [])
  },
  // MONITOR METHODS //
  getUserPatients({ commit, state, dispatch }, requestedPageInformation) {
    // set the page information into the parameters of the window so that on a refresh you go back to the same info
    commit('setRefreshingUserPatients', true)
    let limit = state.defaultUserPatientsPerPage
    let offset = 0
    let url = 'Users/' + requestedPageInformation.user + '/Patients?limit=' + limit + '&offset=' + offset
    return Vue.axios.get(url).then(data => {
      let preparedPatients = []
      data.data.items.forEach( patient => {
        dispatch('patient/preparePatientObject', patient, {root: true}).then(normalizedPatient => {
          preparedPatients.push(normalizedPatient)
        })
      })
      commit('setRefreshingUserPatients', false)
      commit('setUserPatients', preparedPatients)
    })
    .catch(function(error) {
      console.log('Error getting patients: ', error)
      commit('setRefreshingUserPatients', false)
      commit('setUserPatients', [])
      return null
    })
  },
  //SIMPLIFIED DASHBOARD METHODS
  
  refreshUnreadPatientList({ commit, state, rootState, dispatch }) {
    console.log('refreshing the patient list')
    commit('setRefreshingMessages', true)
    let currentPage = state.unreadPatientPageInfo
    let limit = currentPage.count + currentPage.offset
    let offset = 0
    let url = 'Conversations?partnerID=' + rootState.auth.user.partnerID + '&limit=' + limit + '&offset=' + offset

    Vue.axios.get('Conversations?partnerID=' + rootState.auth.user.partnerID + '&limit=1&offset=0&unreadOnly=true').then(data => {
      commit('setActualUnreadPatientPageInfo', data.data.pageInfo)
    })
    return Vue.axios.get(url).then(data => {
      let preparedPatients = []
      let count = 0
      if (data.data.items.length > 0) {
        data.data.items.forEach( conversation => {
          preparedPatients.push(conversation)
          return dispatch('prepareMessageObject', conversation).then(normalizedMessage => {
            conversation.smsPhoneNumber = normalizedMessage.smsPhoneNumber
            conversation.patient = normalizedMessage.patient
            count = count + 1
            if (count === data.data.items.length) {
              commit('setUnreadPatientList', preparedPatients)
              commit('setUnreadPatientPageInfo', data.data.pageInfo)
              commit('setRefreshingMessages', false)
            }
          })
        })
      } else {
        commit('setRefreshingMessages', false)
        commit('setUnreadPatientList', [])
        commit('setUnreadPatientPageInfo', {count: 0, next: 0, offset: 0, total: 0})
      }
    })
    .catch(function() {
      commit('setRefreshingMessages', false)
        commit('setUnreadPatientList', [])
        commit('setUnreadPatientPageInfo', {count: 0, next: 0, offset: 0, total: 0})
      return null
    })
  },
  decrementUnreadMessages({ commit, state}) {
    commit('setPatientsWithUnreadMessages', state.patientsWithUnreadMessages - 1)
  },
  getUnreadPatients({ commit, state, rootState, dispatch }) {
    commit('setRefreshingMessages', true)
    let currentPage = state.unreadPatientPageInfo
    let limit = 25
    let offset = currentPage.next
    if (!offset) {
      offset = 0
    }

    Vue.axios.get('Conversations?partnerID=' + rootState.auth.user.partnerID + '&limit=1&offset=0&unreadOnly=true').then(data => {
      commit('setActualUnreadPatientPageInfo', data.data.pageInfo)
    })
    let url = 'Conversations?partnerID=' + rootState.auth.user.partnerID + '&limit=' + limit + '&offset=' + offset

    return Vue.axios.get(url).then(data => {
      let preparedPatients = []
      let count = 0
      if (data.data.items.length > 0) {
        data.data.items.forEach( conversation => {
          preparedPatients.push(conversation)
          return dispatch('prepareMessageObject', conversation).then(normalizedMessage => {
            conversation.smsPhoneNumber = normalizedMessage.smsPhoneNumber
            conversation.patient = normalizedMessage.patient
            count = count + 1
            if (count === data.data.items.length) {
              commit('addUnreadPatients', preparedPatients)
              commit('setUnreadPatientPageInfo', data.data.pageInfo)
              commit('setRefreshingMessages', false)
            }
          })
        })
      } else {
        commit('setRefreshingMessages', false)
        commit('setUnreadPatientList', [])
        commit('setUnreadPatientPageInfo', {count: 0, next: 0, offset: 0, total: 0})
      }
    })
    .catch(function() {
      commit('setRefreshingMessages', false)
        commit('setUnreadPatientList', [])
        commit('setUnreadPatientPageInfo', {count: 0, next: 0, offset: 0, total: 0})
      return null
    })
  },
  getPatientsByLastName({ commit, rootState }, searchText) {
    commit('extraCommit')
    let limit = 99
    let offset = 0
    let url = 'Partners/' + rootState.auth.user.partnerID + '/Patients?limit=' + limit + '&offset=' + offset
    url += '&lastName=' + searchText
    return Vue.axios.get(url).then(data => {
      return data.data.items
    })
    
  },
  getDashboardRangeForMetric({ commit, dispatch }, { id, metricName, end, start }) {
    commit('extraCommit', null)
    let url = '/Patients/' + id + '/PatientEvents?/events?alt=json&order=-timestamp&eventType=' + metricName + '&start=' + start + '&end=' + end
    return Vue.axios.get(url).then((data) => {
      
      let events = []
      data.data.items.forEach(event => {
        dispatch('prepareEventData', event).then( preparedData => {
          events.push(preparedData)
         })
      })
      return events
    })
  },
  generalBillingReport({commit}, {partner, period}) {
    commit('extraCommit', null)
    let url = '/Partners/' + partner + '/rpmBillingReport?period='+ period
    return Vue.axios.get(url).then(function (response) {
      console.log(response)
      return response
    })
    .catch(function(error) {
        console.log(error)
    })
  },
  checkOperations({commit}, operationID) {
    commit('extraCommit', null)
    return Vue.axios.get('/Operations/' + operationID).then(function (response) {
      return response
    })
  },

  //// IN USE
  createDashboardCSV({commit}, payload) {
    commit('setExportingData', false)
    function getShortDateFromTimestamp(timestamp) {
      if (timestamp == null || timestamp == 0) {
        return '-'
      }
      let d = new Date(timestamp)

      let hours = d.getHours();
      let minutes = d.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let strTime = hours + ':' + minutes + ' ' + ampm;
      return ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear() + ' ' + strTime
    }

    function msToTime(s) {
      if (!s) {
        return '00:00:00'
      }
    let seconds = Math.floor((s / 1000) % 60)
    let minutes = Math.floor((s / (1000 * 60)) % 60)
    let hours = Math.floor((s / (1000 * 60 * 60)))

  hours = (hours < 10) ? "0" + hours : hours;
  minutes = (minutes < 10) ? "0" + minutes : minutes;
  seconds = (seconds < 10) ? "0" + seconds : seconds;

  return hours + ":" + minutes + ":" + seconds
    }

    // the first line is the titles
    // REPORT_TITLE: DATE
    // first, last, dob, start date, bp, hr, weight, glucose, 
    let csv = '';
    csv = payload.title + ' ' +  getShortDateFromTimestamp(new Date().getTime()) + "\n"
    csv += 'Patient ID,First Name,Last Name, Gender, DOB, Enrolled,'

    if (payload.metrics.includes('hr')) {
      csv += 'Latest HR,'
    }
    if (payload.metrics.includes('bp')) {
    csv += 'Latest BP,'
    }
    if (payload.metrics.includes('wt')) {
    csv += 'Latest WT,'
    }
    if (payload.metrics.includes('glu')) {
    csv += 'Latest GLU,'
    }

    csv += 'Last Active, Time This Period\n'

    payload.array.forEach(patient => {
      csv += patient.ownerPatientID + ','
        csv += patient.firstName + ','
        csv += patient.lastName + ','
        csv += patient.gender + ','
        csv += patient.displayBirthdate + ','
        csv += patient.readableStartDate + ','
        if (payload.metrics.includes('hr')) {
          csv += patient.hr + ','
        }
        if (payload.metrics.includes('bp')) {
        csv += patient.bp + ','
        }
        if (payload.metrics.includes('wt')) {
        csv += patient.wt + ','
        }
        if (payload.metrics.includes('glu')) {
        csv += patient.glu + ','
        }
        csv += getShortDateFromTimestamp(patient.rpmMostRecentData) + ','
        csv += msToTime(patient.millisecondsThisPeriod) + ','
        //csv += row.join(',');
        csv += "\n";
      });
      var hiddenElement = document.createElement('a');
      hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
      hiddenElement.target = '_blank';
      hiddenElement.download = payload.title + ' ' +  getShortDateFromTimestamp(new Date().getTime()) + '_novorpm.csv';
      hiddenElement.click();
  },
  // This method prepares the patient for displaying in the dashboard. There is another method (in the patient module) that prepares the patient for viewing.
  preparePatientObjectForDashboard({commit }, item) {
    // dispatch('determineTodayTasksForPatient', item).then(alertSummary => {
    //   item.todayAlertSummary = alertSummary
    // })
    commit('extraCommit', null)

    function getShortDateFromTimestamp(timestamp) {
      let d = new Date(timestamp)

      let hours = d.getHours();
      let minutes = d.getMinutes();
      let ampm = hours >= 12 ? 'pm' : 'am';
      hours = hours % 12;
      hours = hours ? hours : 12; // the hour '0' should be '12'
      minutes = minutes < 10 ? '0'+minutes : minutes;
      let strTime = hours + ':' + minutes + ' ' + ampm;
      return ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear() + ' ' + strTime
    }


          // prepare the patient object

          // trim the last name. 

          item.readableStartDate = getShortDateFromTimestamp(item.rpm.enrollment.start)

          if (item.lastName) {
            item.lastName = item.lastName.trim()
          }

          if (item.gender) {
            item.shortGender = item.gender.charAt(0)
          }
          if (item.birthdate) {
            // change birthdate into display birthdate
            item.displayBirthdate = item.birthdate.substring(5, 7) + '/' + item.birthdate.substring(8, 10) + '/' + item.birthdate.substring(0, 4)
            
            let constructedDateTimestamp = new Date(item.birthdate.substring(0, 4), item.birthdate.substring(5, 7) - 1, item.birthdate.substring(8, 10), 0, 0, 0, 0).getTime()
        
            let ageDifMs = Date.now() - constructedDateTimestamp
            let ageDate = new Date(ageDifMs) // miliseconds from epoch
            item.age = Math.abs(ageDate.getUTCFullYear() - 1970)
          }


          item.fullName = item.firstName + ' ' + item.lastName.toUpperCase()
          item.nameForSearching = item.firstName.toLowerCase() + ' ' + item.lastName.toLowerCase()
          item.hr = '-'
          if (item.latestEvents && item.latestEvents.MEASUREMENT_PULSE && item.latestEvents.MEASUREMENT_PULSE.eventData ) {
            item.hr = item.latestEvents.MEASUREMENT_PULSE.eventData.value
            if (item.latestEvents.MEASUREMENT_PULSE.alert) {
              item.hrAlert = true
              item.alert = true
            }
            item.hrDate = getShortDateFromTimestamp(item.latestEvents.MEASUREMENT_PULSE.timestamp)
          }

          item.bp = '-'
          if (item.latestEvents && item.latestEvents.MEASUREMENT_BLOOD_PRESSURE && item.latestEvents.MEASUREMENT_BLOOD_PRESSURE.eventData ) {
            item.bp = item.latestEvents.MEASUREMENT_BLOOD_PRESSURE.eventData.systolicValue + '/' + item.latestEvents.MEASUREMENT_BLOOD_PRESSURE.eventData.diastolicValue
            if (item.latestEvents.MEASUREMENT_BLOOD_PRESSURE.alert) {
              item.bpAlert = true
              item.alert = true
            }
            item.bpDate = getShortDateFromTimestamp(item.latestEvents.MEASUREMENT_BLOOD_PRESSURE.timestamp)
          }

          item.glu = '-'
          if (item.latestEvents && item.latestEvents.MEASUREMENT_BLOOD_GLUCOSE && item.latestEvents.MEASUREMENT_BLOOD_GLUCOSE.eventData ) {
            item.glu = item.latestEvents.MEASUREMENT_BLOOD_GLUCOSE.eventData.value
            if (item.latestEvents.MEASUREMENT_BLOOD_GLUCOSE.alert) {
              item.gluAlert = true
              item.alert = true
            }
            item.gluDate = getShortDateFromTimestamp(item.latestEvents.MEASUREMENT_BLOOD_GLUCOSE.timestamp)
          }

          item.wt = '-'
          if (item.latestEvents && item.latestEvents.MEASUREMENT_BODY_WEIGHT && item.latestEvents.MEASUREMENT_BODY_WEIGHT.eventData ) {
            item.wt = Math.round((item.latestEvents.MEASUREMENT_BODY_WEIGHT.eventData.value * 2.205) * 10) / 10
            if (item.latestEvents.MEASUREMENT_BODY_WEIGHT.alert) {
              item.wtAlert = true
              item.alert = true
            }
            item.wtDate = getShortDateFromTimestamp(item.latestEvents.MEASUREMENT_BODY_WEIGHT.timestamp)
          }

          // set the qhp time

          if (item.qhpTimeThisPeriod) {
            let today = new Date()
            let monthToUse = today.getMonth() + 1
            let yearToUse = today.getFullYear()
            if (item.qhpTimeThisPeriod.start && item.qhpTimeThisPeriod.start.includes(yearToUse.toString() + '-' + ('0' + monthToUse.toString()).slice(-2) + '-01')) {
              item.millisecondsThisPeriod = item.qhpTimeThisPeriod.millisecondsThisPeriod
            } else {
              item.millisecondsThisPeriod = 0
            }
          }
          return item

  },
  // this gets the entire list of patients with devices. 
  getEnrolledPatients({ commit, rootState, dispatch }) {
    // this should load the entire patient list
    let limit = 200
    let offset = 0
    let preparedPatients = []
    let patientTags = []

    let alerts = 0
    let sevenDays = 0
    let thirtyDays = 0
    let sixtyDays = 0
    let sixtyDaysPlus = 0 
    let inactive = 0 // this is greater than 16 days without readings.
    let active = 0 // this is less than or equal to 16 days since the last reading
    let newPatients = 0 // this is a patient that has been enrolled for less than 7 days. 

    let newThresholdBreaches = []
    let recentIdlePatients = []
    let enrolledPatientsDictionary = {}

    let patientsWithUnseenBreaches = 0
  let patientsNeedingOutreach = []
  let patientsAtRisk = []
  let atRisk = 0
  let outreach = 0
  let onTrack = 0
  let noData = 0
  let finishOnboarding = 0

  let novoText = 0
  let novoCall = 0


    function getPatients(offset, limit, preparedPatients) {
      commit('setRefreshingDashboard', true)
      let url = 'Partners/' + rootState.auth.user.partnerID + '/Patients?limit=' + limit + '&offset=' + offset
      url += '&active=true&order=-rpmMostRecentData'

      return Vue.axios.get(url).then(data => {
        data.data.items.forEach(item => {
          dispatch('preparePatientObjectForDashboard', item).then(item => {

            if (item.tags.length > 0) {
              item.tags.forEach(tag => {
                if (!patientTags.includes(tag)) {
                  patientTags.push(tag)
                }
              })
            }

          if (item.alert) {
            
            alerts = alerts + 1
          }

          if (item.rpmUnseenAlerts) {
            patientsWithUnseenBreaches = patientsWithUnseenBreaches + 1
            item.thresholdBreachAlertNotifications = []

            let problemNote = ''

            item.rpmUnseenAlertsArray.forEach(alert => {

              let measurementName = consts.metricList[alert].commonName;
              let operator = 'above'

              if (!item.latestEvents[alert].alertMsg.includes('above')) {
                operator = 'below'
              }
              let noteFragment = item.firstName + '\'s' + ' most recent ' + measurementName + ' reading is ' + operator + ' the threshold. '
              item.thresholdBreachAlertNotifications.push({ event: item.latestEvents[alert], note: noteFragment})
              problemNote = problemNote + noteFragment
            })
            newThresholdBreaches.push({patient: item, taskNote: problemNote})
            
          }

          



          

          if (item.rpmMostRecentData) {
            // this is legacy active vs inactive

            var Difference_In_Time = (new Date().getTime() / 1000) - (new Date(item.rpmMostRecentData).getTime() / 1000)
            let days1 = Difference_In_Time / (3600 * 24)
            item.daysSinceLast = Math.floor(days1)

            if (item.daysSinceLast > 16) {
              item.inactive = true
              inactive = inactive + 1
            } else {
              item.active = true
              active = active + 1
            }

            // get the end of day for most recent day with readings. we will use this for the calculation. 
            let end = new Date(item.rpmMostRecentData)
            end.setHours(23,59,59,999)
            let secondsSinceLastReading = (new Date().getTime() / 1000) - (end.getTime() / 1000)
            let days = secondsSinceLastReading / (3600 * 24)
            item.activityTier = days

            if (item.activityTier > 5) {
              // if the patient has not taken a reading in more than 5 days, this is a huge issue.
              atRisk = atRisk + 1
              item.atRisk = true
              patientsAtRisk.push(item)
              
            } else if (item.activityTier > 2) {
              outreach = outreach + 1
              item.outreach = true
              patientsNeedingOutreach.push(item)
            } else if (item.activityTier > 1) {
              item.novoCall = true
              novoCall = novoCall + 1
            } else if (item.activityTier > 0) {
              novoText = novoText + 1
              item.novoText = true
            } else {
              onTrack = onTrack + 1
              item.onTrack = true
            }
          } else {
            // no data means tier three? or maybe a tier four?
            item.activityTier = 100
            noData = noData + 1
            item.noData = true
          }

          let howLongAPatient = (new Date().getTime() / 1000) - (Date.parse(item.rpm.enrollment.start) / 1000)
          let daysAPatient = howLongAPatient / (3600 * 24)
          if (daysAPatient < 7) {
            if (item.noData) {
              noData = noData - 1
            }
            item.newPatient = true
            item.noData = false
            newPatients = newPatients + 1

            // flag patients that have been given a device more than 4 days ago (but less than seven) but havent taken readings
            if (daysAPatient > 3 && !item.rpmMostRecentData) {
              item.finishOnboarding = true
              finishOnboarding = finishOnboarding + 1
            }
          }
          

            enrolledPatientsDictionary[item._id] = item
            preparedPatients.push(item)
          })
          
        })
        
        if (data.data.pageInfo.total > preparedPatients.length) {
          offset = offset + limit
          getPatients(offset, limit, preparedPatients)
        } else {
          
          commit('setRefreshingDashboard', false)
          // if the dashboard is being refreshed, to make the UI smooth, get the messages before you update the enrolled patients
          if (rootState.dashboard.enrolledPatients.length > 0) {
            dispatch('fetchMessagesForEnrolled', preparedPatients)
          } else {
            commit('setEnrolledPatients', preparedPatients)
            dispatch('fetchMessagesForEnrolled')
          }
          
          commit('setEnrolledPatientsDictionary', enrolledPatientsDictionary)
          commit('setPatientTags', patientTags)
          commit('setPatientsWithUnseenBreaches', patientsWithUnseenBreaches)
          commit('setPatientsNeedingOutreach', patientsNeedingOutreach)
          commit('setPatientsAtRisk', patientsAtRisk)
          commit('setTodayTasks', {newThresholdBreaches: newThresholdBreaches, recentIdlePatients: recentIdlePatients, total: recentIdlePatients.length + newThresholdBreaches.length})
          commit('setCounts', {newPatients: newPatients, finishOnboarding: finishOnboarding, outreach: patientsNeedingOutreach.length, atRisk: patientsAtRisk, all: preparedPatients.length, active: active, sevenDays: sevenDays, thirtyDays: thirtyDays, sixtyDays: sixtyDays, sixtyPlus: sixtyDaysPlus, noReadings: noData, alerts: alerts, inactive: inactive})
        }
      })
    }
    if (new Date().getTime() > rootState.dashboard.dashboardFreshness)  {
      // only refresh the dashboard if its been more than 15 seconds since the last refresh
      commit('setRefreshingDashboard', true)
      commit('setDashboardFreshness', new Date().getTime() + 10000)
     return getPatients(offset, limit, preparedPatients)
    }
     
  },
  fetchMessagesForEnrolled({ commit, rootState, state }, enrolledPatientsToPost) {
    commit('setRefreshingDashboard', false)
    // this should load the entire patient list
    let limit = 200
    let offset = 0
    let preparedMessages = []
    let unreadCount = 0
    let patientUnreadCount = 0
    let patientsWithUnreadMessages = []

    let partnerID = null

    if (rootState.auth.user.partnerID) {
      partnerID = rootState.auth.user.partnerID
    } else if (rootState.auth.partner._id) {
      partnerID = rootState.auth.partner._id
    }
    let finalEnrolledList = state.enrolledPatients
    if (enrolledPatientsToPost) {
      finalEnrolledList = enrolledPatientsToPost
    }

    function getConversationMessages(offset, limit, preparedMessages) {
      let url = 'Conversations?partnerID=' + partnerID + '&limit=' + limit + '&offset=' + offset + '&unreadOnly=false'
      return Vue.axios.get(url).then(data => {
        data.data.items.forEach(item => {
          item.participants.forEach(participant => {
            if (participant.kind === 'SMS') {
              item.smsPhoneNumber = participant.smsPhoneNumber
            } else {
              if (participant.partnerID && participant.unread) {
                item.unread = true
                unreadCount = unreadCount + 1
              }
            }
          })

          item.key = 0
          preparedMessages.push(item)
          let index = finalEnrolledList.indexOf(state.enrolledPatientsDictionary[item.patientID]);
          // only add the conversation to the patient if it matches the current phone for the patient
          if (index > -1 && item.smsPhoneNumber === state.enrolledPatients[index].smsPhone) {
            let patientHere = finalEnrolledList[index]
            patientHere.mostRecentMessage = item
            if (!patientHere.unreadMessage && item.unread) {
              patientUnreadCount = patientUnreadCount + 1
            }
            commit('setPatientsWithUnreadMessages', patientUnreadCount)
            patientHere.unreadMessage = item.unread
            if (patientHere.unreadMessage) {
              patientsWithUnreadMessages.push(patientHere)
              patientHere.newData = true
            }
            patientHere.messages = true
            patientHere.messageReceived = new Date(item.latestActivity).getTime()
            if (item.lastMessage && item.lastMessage.content)  {
              patientHere.lastMessage = item.lastMessage
            }
            
            patientHere.lastTimestamp = new Date(item.latestActivity).getTime()
          }
        })
        
        if (data.data.pageInfo.total > preparedMessages.length) {
          offset = offset + limit
          getConversationMessages(offset, limit, preparedMessages)
        } else {
          let bigCities = finalEnrolledList.filter(function (e) {
            return e.messages
        });

          commit('setPatientsWithUnreadMessages', patientUnreadCount)
          commit('setPatientsWithMessages', bigCities)
          if(enrolledPatientsToPost) {
            commit('setEnrolledPatients', finalEnrolledList)
          }
        }
      })
    }

     return getConversationMessages(offset, limit, preparedMessages)
  },
  // this should take a patient object, and insert the today tasks and somehow also return the count for the today view
  determineTodayTasksForPatient({dispatch }, item) {
    // this chould create an array on the patient object of preformed today tasks ready for conversion into a note to dismiss
    // today task should look like this {object: theoffendingmetric, note: text description of the problem}
    if (item.rpmUnseenAlerts) {

      let alertsForResolution = []
      let problemSummary = ''
      item.rpmUnseenAlertsArray.forEach(alert => {

        dispatch('prepareEventData', item.latestEvents[alert]).then(data => {
          let measurementName = consts.metricList[alert].displayName;
          let operator = 'above'

          if (!item.latestEvents[alert].alertMsg.includes('above')) {
            operator = 'below'
          }
          let noteFragment = item.firstName + '\'s' + ' most recent ' + measurementName + ' reading is ' + operator + ' the threshold. '
          problemSummary = problemSummary + noteFragment
          alertsForResolution.push({event: data, note: noteFragment})
        })

        
      })
      item.alertsForResolution = alertsForResolution

      // this is passed to the today panel
      return {patient: item, taskNote: problemSummary}
    }

  },
  prepareForSignout({ commit }) {
    commit('setPatientMessages', [])
    commit('setEnrolledPatients', [])
    commit('setCounts', {newPatients: 0, all: 0, active: 0, sevenDays: 0, thirtyDays: 0, sixtyDays: 0, sixtyPlus: 0, noReadings: 0, alerts: 0, inactive: 0})
  
  },
  getPatientMessages({ commit, rootState }) {

    commit('setRefreshingDashboard', false)
    // this should load the entire patient list
    let limit = 200
    let offset = 0
    let preparedMessages = []
    let unreadCount = 0

    let partnerID = null

    if (rootState.auth.user.partnerID) {
      partnerID = rootState.auth.user.partnerID
    } else if (rootState.auth.partner._id) {
      partnerID = rootState.auth.partner._id
    }

    function getConversationMessages(offset, limit, preparedMessages) {
      let url = 'Conversations?partnerID=' + partnerID + '&limit=' + limit + '&offset=' + offset + '&unreadOnly=false'

      return Vue.axios.get(url).then(data => {
        data.data.items.forEach(item => {

          item.participants.forEach(participant => {
            if (participant.kind === 'SMS') {
              item.smsPhoneNumber = participant.smsPhoneNumber
            } else {
              if (participant.partnerID && participant.unread) {
                item.unread = true
                unreadCount = unreadCount + 1
              }
            }
          })

          item.key = 0
          preparedMessages.push(item)
        })
        
        if (data.data.pageInfo.total > preparedMessages.length) {
          offset = offset + limit
          getConversationMessages(offset, limit, preparedMessages)
        } else {
          commit('setPatientMessages', preparedMessages)}
          commit('setUnreadMessages', unreadCount)
      })
    }

     return getConversationMessages(offset, limit, preparedMessages)
  }

}
