export default {
  refreshingMessages: false,
  patientMessages: [],
  unreadMessages: 0,
  patientsWithMessages: [],
  // these are the taskboard tab numbers
  patientsWithUnseenBreaches:0,
  patientsWithZeroDaysOfInactivity:0,
  patientsWithOneDayOfInactivity:0,
  patientsWithTwoDaysOfInactivity:0,
  patientsWithThreeDaysOfInactivity:0,
  patientsWithFourDaysOfInactivity:0,
  patientsWithFiveDaysOfInactivity:0,
  patientsWithSixDaysOfInactivity:0,
  patientsWithSevenPlusDaysOfInactivity:0,
  patientsWithNoData:0,
  lowerInactivityBucket: 0,
  upperInactivityBucket: 0,
// end taskboard tab numbers
  unreadThresholdAlerts: 0,
  patientsWithThresholdAlerts: [],
  unreadOutreachAlerts: 0,
  patientsNeedingOutreach: [],
  unreadAtRiskAlerts: 0,
  patientsAtRisk: [],
  unreadFinishOnboardingAlerts: 0,
  patientsFinishingOnboarding: [],
  patientsWithoutData: [],
  patientsWithoutDataAlerts: 0,
  dismissedAlertsDictionary: {},
  taskboardPage: 1,
  todayTasks: null,
  todayTasksToggle: false,
  taskboardKey: 0,
  taskboardTab: 0,
  /// V2 State
  enrolledPatients: [],
  unenrolledPatients: [],
  storedSelectedPatients: [],
  enrolledPatientsDictionary: {},
  refreshingTaskboard: false,
  userPatients: [],
  refreshingUserPatients: false,
  taskboardFreshness: 0,
  patientTags: [],
  patientConditions: [],
  patientZips: [],
  patientMetricTypes: [],
  patientDevices: []
}
