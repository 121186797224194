import Vue from 'vue'
import firebase from 'firebase/app'

export default {
  registerLastChartNote({ rootState }) {
    // only register a last chart review if you've spent 30 seconds in the patient chart.
    // this should only be set if youre still in the chart so that quick glances dont register
    let lastNotesRef = firebase
      .firestore()
      .collection('lastNotes')
      .doc(rootState.auth.user.partnerID)
    lastNotesRef.get().then(docSnapshot => {
      if (docSnapshot.exists) {
        if (rootState.patient.patient && rootState.patient.patient._id && rootState.auth.user.partnerID) {
          let db = firebase.firestore()
          let noteEntry = {}
          noteEntry[rootState.patient.patient._id] = new Date().getTime()
          return db
            .collection('lastNotes')
            .doc(rootState.auth.user.partnerID)
            .update(noteEntry)
            .catch(function(error) {
              console.error('Error adding document: ', error)
            })
        }
      } else {
        if (rootState.patient.patient && rootState.patient.patient._id && rootState.auth.user.partnerID) {
          let db = firebase.firestore()
          let noteEntry = {}
          noteEntry[rootState.patient.patient._id] = new Date().getTime()
          return db
            .collection('lastNotes')
            .doc(rootState.auth.user.partnerID)
            .set(noteEntry)
            .catch(function(error) {
              console.error('Error adding document: ', error)
            })
        }
      }
    })
  },
  addChartNote({ commit, rootState, dispatch }, payload) {
    /// SHOULDNT REFRESH ON COMMENTS
    commit('extraCommit', null)
    if (payload.patient) {
      // now that you have a patient, see if there is a time payload on this or not.
      // if there is, add the time document, then add the note.
      // if not, add the note
      let userID = rootState.auth.user._id
      let email = rootState.auth.user.email
      let session = rootState.auth.rpmCurrentSession
      if (payload.sessionNote) {
        // add RPM_NOTE
        let patientEvent = {}
        patientEvent.eventType = 'RPM_NOTE'
        patientEvent.timestamp = new Date().getTime()
        patientEvent.eventData = {
          partner: rootState.auth.partner._id,
          user: userID,
          email: email,
          note: payload.sessionNote,
          type: payload.type,
          action: payload.action,
          referenceData: payload.referenceData,
        }
        patientEvent.eventData.authorName = rootState.auth.user.firstName + ' ' + rootState.auth.user.lastName
        patientEvent.eventData.authorOrganization = rootState.auth.partner.name
        patientEvent.eventData.templateID = payload.templateID
        // here is where the patient note can have user id tags?
        if (payload.noteMentions.length > 0) {
          console.log('there is a mention')
          console.log(payload.noteMentions)
          patientEvent.eventData.mentions = payload.noteMentions
        }
        patientEvent.eventData.sessionFirebaseDocumentID = session
        patientEvent.patientID = payload.patient
        patientEvent.peripheralName = 'Manual Entry'
        return Vue.axios.post('/PatientEvents', { patientEvent }).then(data => {
          // if you post a note, remove the inactivity?
          if (payload.referenceData && payload.referenceData.thread) {
            // this is a reply. dont do anything at the moment
          } else {
            dispatch('taskboard/dismissInactivity', payload.patient, { root: true })
          }
          // if there is a template id, stre the occurance in firebase real fast? just to get a good idea of how much it is used?
          // what would need to go: the template ID, the timestamp of the note, the author of the note, the id of the note.
          // each client would have a template use collection. a document for each template? then entries for each use?
          if (payload.noteMentions.length > 0) {
            if (payload.referenceData && payload.referenceData.thread) {
              // this is a response, handle it carefully.
              dispatch('notifyUsersTaggedInNote', {
                noteID: payload.referenceData.thread,
                taggedUsers: payload.noteMentions,
                patient: payload.patient,
              })
            } else {
              dispatch('notifyUsersTaggedInNote', {
                noteID: data.data.patientEvent.timestamp + payload.patient,
                taggedUsers: payload.noteMentions,
                patient: payload.patient,
              })
            }
          }
          return data
        })
      }
    }
    return 'error'
  },
  notifyUsersTaggedInNote({ commit, rootState }, { noteID, taggedUsers, patient }) {
    commit('extraCommit')
    let userID = rootState.auth.user._id
    console.log('patient')
    console.log(patient)
    commit('extraCommit')
    let notification = {}
    notification.patient = patient
    notification.patientEvent = noteID
    notification.timestamp = new Date().getTime()
    notification.authorID = userID
    notification.read = null

    taggedUsers.forEach(tag => {
      console.log
      let db = firebase.firestore()
      return db
        .collection('userNotifications')
        .doc(tag.userID)
        .collection('mentions')
        .doc(notification.patientEvent)
        .set(notification)
        .catch(function(error) {
          console.error('Error adding document: ', error)
        })
    })
  },
  getPatientNotes({ commit, rootState }, patientID) {
    // if notes already exist, just fetch the most recent ones and add them?
    commit('setActiveUsersForPatientNotes', [])
    commit('setLoadingPatientNotes', true)

    commit('setPatientNotes', [])

    function compareTimestamps(a, b) {
      // Use toUpperCase() to ignore character casing
      const bandA = a.timestamp
      const bandB = b.timestamp

      let comparison = 0
      if (bandA < bandB) {
        comparison = 1
      } else if (bandA > bandB) {
        comparison = -1
      }
      return comparison
    }

    function compareTimestampsAscending(a, b) {
      // Use toUpperCase() to ignore character casing
      const bandA = a.timestamp
      const bandB = b.timestamp

      let comparison = 0
      if (bandA > bandB) {
        comparison = 1
      } else if (bandA < bandB) {
        comparison = -1
      }
      return comparison
    }

    // get the last 6 months of notes?
    let patientIdentification = patientID
    if (!patientIdentification) {
      patientIdentification = rootState.patient.patient._id
    }
    let sixMonthsAgo = new Date().getTime() - 86400 * 185 * 1000

    let url =
      '/Patients/' +
      patientIdentification +
      '/PatientEvents?/events?alt=json&order=-timestamp&eventType=RPM_NOTE&start=' +
      sixMonthsAgo +
      '&end=' +
      new Date().getTime()

    let arrayOfUnreadMentions = []
    if (rootState.auth.userMentions) {
      rootState.auth.userMentions.forEach(mention => {
        if (mention.patient._id === rootState.patient.patient._id) {
          arrayOfUnreadMentions.push(mention.patientEvent)
        }
      })
    }

    return Vue.axios.get(url).then(data => {
      // this should get each note then categorize it
      // auto (maybe discard for now)
      // event post (display the event in big bold glory)
      // medication post
      // condition post?
      // response post

      console.log('note data is back!')
      console.log(data)

      let activeParticipantsDict = {}
      let notes = []
      let notesDictionary = {}
      let noteTypesDictionary = {}

      data.data.items.forEach(note => {
        let noteObject = {
          id: note._id,
          author: note.eventData.authorName,
          timestamp: note.timestamp,
          fullNote: note,
          unprocessedNote: note,
          mentions: [],
          media: null,
          mediaType: null,
          text: note.eventData.note,
          replies: [],
          templateID: note.eventData.templateID,
        }

        if (note.eventData.templateID) {
          // this is the thing
          if (noteTypesDictionary[note.eventData.templateID]) {
            noteTypesDictionary[note.eventData.templateID] = noteTypesDictionary[note.eventData.templateID] + 1
          } else {
            noteTypesDictionary[note.eventData.templateID] = 1
          }
        }

        if (arrayOfUnreadMentions.includes(note._id)) {
          // remove the chart tag notification from user
          // just flag the note first.
          // dispatch('removeChartNoteTagNotification', note._id)
          noteObject.unread = true
          noteObject.IDToClear = note._id
          // on clikc, you should clear the note.
        }
        // set the media here?

        if (note.eventData && note.eventData.referenceData) {
          // this note contains media to be displayed
          noteObject.mediaType = note.eventData.referenceData.type
        }

        if (note.eventData && note.eventData.mentions && note.eventData.mentions.length > 0) {
          note.eventData.mentions.forEach(mention => {
            activeParticipantsDict[mention.userID] = {}
          })
          noteObject.mentionText = note.eventData.mentions[0].name

          let remaining = note.eventData.mentions.length - 1

          if (remaining > 0) {
            noteObject.additionalMentions = '+' + remaining
          }
          // this belongs in the array of another note
        }

        notes.push(noteObject)
        notesDictionary[note._id] = noteObject
        activeParticipantsDict[note.eventData.user] = {}

        // just put it in the dictionary.
        // console.log(note)
        // activeParticipantsDict[note.eventData.user] = {}
        // let noteObject = {author: 'Author', timestamp: 0, userTags: [], media: null, text: 'Body Text', replies: []}

        // noteObject.timestamp = note.timestamp
        // noteObject.fullNote = note
        // if (note.eventData && note.eventData.authorName) {
        //   noteObject.author = note.eventData.authorName
        // }

        // if (note.eventData && note.eventData.note) {
        //   noteObject.text = note.eventData.note
        // }

        // if (note.eventData && note.eventData.referenceData) {

        //   noteObject.mediaCardColor = 'grey lighten-5'
        //   noteObject.media = note.eventData.referenceData.type
        //   if (noteObject.media === 'medication') {
        //     noteObject.mediaSingleLine = note.eventData.referenceData.data.displayName
        //   }

        //   if (noteObject.media === 'metric') {
        //     // this should display a lot of cool things. this is the most common
        //     let timestampOfDataPoint = note.eventData.referenceData.data.timestamp
        //     // get the previous two weeks?
        //     let start = timestampOfDataPoint - (86400000 * 10)
        //     noteObject.rangeStart = start
        //     noteObject.rangeEnd = timestampOfDataPoint
        //     noteObject.metricName = note.eventData.referenceData.data.eventType
        //     if (note.eventData.referenceData.data.alert) {
        //       noteObject.outOfRange = true
        //     }
        //     noteObject.mediaText = note.eventData.referenceData.data.displayValue
        //     noteObject.mediaUnits = consts.metricList[note.eventData.referenceData.data.eventType].units
        //     noteObject.mediaDetail1 = consts.metricList[note.eventData.referenceData.data.eventType].commonName
        //     noteObject.mediaDetail2 = note.eventData.referenceData.data.timestamp
        //   }
        //   if (noteObject.media === 'condition') {
        //     noteObject.mediaSingleLine = note.eventData.referenceData.data.displayName
        //   }

        // }

        // noteObject.full = note
        // // the note should have the following:
        // // author string

        // // timestamp
        // // body multimedia
        // // body text
        // // array of responses that are all notes as well.
        // // dont push auto?
        // noteObject.id = note._id

        // if (note.eventData.referenceData.type !== 'auto') {
        //   if (note.eventData && note.eventData.referenceData && note.eventData.referenceData.thread) {
        //     // this belongs in the array of another note
        //     console.log('this note has a thread!!!')
        //     // this whole note belongs in another note
        //     notesDictionary[note.eventData.referenceData.thread].replies.push(noteObject)
        //     // order the array in

        //     notesDictionary[note.eventData.referenceData.thread].replies.sort(compareTimestamps);

        //   } else {
        //     notesDictionary[noteObject.id] = noteObject
        //   }
        // }
      })

      // run through the dictionary and pull out any that are replies
      let keys = Object.keys(notesDictionary)

      keys.forEach(key => {
        let note = notesDictionary[key]
        if (
          note.fullNote.eventData &&
          note.fullNote.eventData.referenceData &&
          note.fullNote.eventData.referenceData.type === 'response'
        ) {
          let theNoteThisBelongsTo = notesDictionary[note.fullNote.eventData.referenceData.thread]
          // if any message is unread, the converation is unread
          if (note.unread) {
            theNoteThisBelongsTo.unread = true
            note.unread = false
          }
          if (theNoteThisBelongsTo) {
            theNoteThisBelongsTo.replies.push(note)
            theNoteThisBelongsTo.replies.sort(compareTimestampsAscending)
            delete notesDictionary[note.id]
          }
        }
      })

      let noteTypesKeys = Object.keys(noteTypesDictionary)
      let matchedTypes = []

      noteTypesKeys.forEach(key => {
        // get the text name of the key.
        rootState.notes.savedNoteTemplates.forEach(type => {
          if (type.id === key) {
            matchedTypes.push({ title: type.title, count: noteTypesDictionary[key] })
          }
        })
      })

      // create an array from the dictionary
      let sortedNotes = Object.values(notesDictionary).sort(compareTimestamps)

      commit('setPatientNotes', sortedNotes)
      commit('setPatientNoteTypes', matchedTypes)
      commit('setActiveUsersForPatientNotes', Object.keys(activeParticipantsDict))
      commit('setLoadingPatientNotes', false)
      return notes
    })
  },
  respondToNote() {
    // this should create a normal note. but it has an extra reference of a previous note
  },
  removeChartNoteTagNotification({ commit, rootState }, patientEventID) {
    commit('extraCommit')

    let db = firebase.firestore()
    return db
      .collection('userNotifications')
      .doc(rootState.auth.user._id)
      .collection('mentions')
      .doc(patientEventID)
      .set({ read: new Date().getTime() }, { merge: true })
      .catch(function(error) {
        console.log('Error removing notification: ', error)
      })
  },
  getEventsForMetric({ commit, dispatch }, { id, metricName, end, start }) {
    commit('extraCommit', null)
    let url =
      '/Patients/' +
      id +
      '/PatientEvents?/events?alt=json&order=-timestamp&eventType=' +
      metricName +
      '&start=' +
      start +
      '&end=' +
      end
    return Vue.axios.get(url).then(data => {
      let events = []
      data.data.items.forEach(event => {
        dispatch('dashboard/prepareEventData', event, { root: true }).then(preparedData => {
          events.push(preparedData)
        })
      })
      return events
    })
  },
  getJustTheLastNote({ commit }, patientID) {
    commit('extraCommit', null)
    let url = '/Patients/' + patientID + '/PatientEvents?alt=json&order=-timestamp&eventType=RPM_NOTE' + '&limit=1'
    return Vue.axios.get(url).then(data => {
      let last = {}
      if (data.data.items.length > 0) {
        last = {
          author: data.data.items[0].eventData.authorName,
          timestamp: data.data.items[0].timestamp,
          note: data.data.items[0].eventData.note,
        }
      }
      return last
    })
  },
  getChartNotesForPatient({ commit, dispatch, rootState }, { patient, limit }) {
    commit('extraCommit', null)
    let url = '/Patients/' + patient + '/PatientEvents?alt=json&order=-timestamp&eventType=RPM_NOTE' + '&limit=' + limit
    let arrayOfUnreadMentions = []
    if (rootState.auth.userMentions) {
      console.log('there are some user mentions here')
      rootState.auth.userMentions.forEach(mention => {
        if (mention.patient._id === patient) {
          arrayOfUnreadMentions.push(mention.patientEvent)
        }
      })
    }

    return Vue.axios.get(url).then(data => {
      let events = []
      data.data.items.forEach(event => {
        // check to see if we need to remove any of these events from the mentions list
        if (arrayOfUnreadMentions.includes(event._id)) {
          // remove the chart tag notification from user
          dispatch('removeChartNoteTagNotification', event._id)
        }
        if (event.eventData.referenceData) {
          let lead = ''
          if (event.eventData.referenceData.title) {
            lead = event.eventData.referenceData.title
          }
          if (event.eventData.referenceData.value) {
            lead = lead + ' ' + event.eventData.referenceData.value
          }
          if (event.eventData.referenceData.subtitle) {
            lead = lead + ' ' + event.eventData.referenceData.subtitle
          }
          if (event.eventData.referenceData.timestamp) {
            let d = new Date(event.eventData.referenceData.timestamp)
            let hours = d.getHours()
            let minutes = d.getMinutes()
            let ampm = hours >= 12 ? 'pm' : 'am'
            hours = hours % 12
            hours = hours ? hours : 12 // the hour '0' should be '12'
            minutes = minutes < 10 ? '0' + minutes : minutes
            let strTime = hours + ':' + minutes + ' ' + ampm
            lead =
              lead +
              ' | Recorded: ' +
              ('0' + (d.getMonth() + 1)).slice(-2) +
              '/' +
              ('0' + d.getDate()).slice(-2) +
              '/' +
              d.getFullYear() +
              ' ' +
              strTime
          }
          event.referenceLine = lead
        }
        events.push(event)
      })
      return { notes: events.sort((a, b) => b.timestamp - a.timestamp), total: data.data.pageInfo.total }
    })
  },
  // this needs to save the note template to the partner object.
  saveANoteTemplate({ rootState, dispatch }, { title, content }) {
    let ObjectID = require('bson-objectid')

    let uniqueMongoID = ObjectID().toString()

    let chartNoteTemplates = {}

    if (rootState.auth.configuration.chartNoteTemplates) {
      chartNoteTemplates = rootState.auth.configuration.chartNoteTemplates
    }

    chartNoteTemplates[uniqueMongoID] = { title: title, content: content, id: uniqueMongoID }

    dispatch(
      'auth/updatePartnerSettings',
      { partner: rootState.auth.user.partnerID, update: { chartNoteTemplates: chartNoteTemplates } },
      { root: true }
    )

    //updatePartnerSettings({partner: rootState.auth.user.partnerID, update: {chartNoteTemplates: templateEntry}})

    // let chartNoteTemplatesRef = firebase.firestore().collection('chartNoteTemplates').doc(rootState.auth.user.partnerID)
    // chartNoteTemplatesRef.get()
    //       .then((docSnapshot) => {
    //         if (docSnapshot.exists) {
    //             let db = firebase.firestore();
    //             let templateEntry = {}
    //             templateEntry[uniqueMongoID] = {title: title, content: content, id: uniqueMongoID}
    //               return db.collection('chartNoteTemplates').doc(rootState.auth.user.partnerID).update(templateEntry)
    //               .catch(function(error) {
    //                   console.error("Error adding document: ", error);
    //               });
    //         } else {
    //             let db = firebase.firestore();
    //             let templateEntry = {}
    //             templateEntry[uniqueMongoID] = content
    //             return db.collection('chartNoteTemplates').doc(rootState.auth.user.partnerID).set(templateEntry)
    //               .catch(function(error) {
    //                   console.error("Error adding document: ", error);
    //               });
    //         }
    //     });
  },
  deleteANoteTemplate({ rootState, dispatch }, template) {
    let chartNoteTemplates = rootState.auth.configuration.chartNoteTemplates

    delete chartNoteTemplates[template.id]

    return dispatch(
      'auth/updatePartnerSettings',
      { partner: rootState.auth.user.partnerID, update: { chartNoteTemplates: chartNoteTemplates } },
      { root: true }
    )
  },
}
