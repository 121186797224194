var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.user)?_c('div',[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.user)?_c('v-list-item',_vm._g(_vm._b({attrs:{"to":_vm.accountRoute},model:{value:(_vm.isDisplayingAccount),callback:function ($$v) {_vm.isDisplayingAccount=$$v},expression:"isDisplayingAccount"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-account-circle")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName))])],1)],1):_vm._e()]}}],null,false,1788458525)},[_vm._v(" "+_vm._s(_vm.user.firstName)+" "+_vm._s(_vm.user.lastName)+" ")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({directives:[{name:"show",rawName:"v-show",value:(_vm.user.partnerRoles && _vm.user.partnerRoles.admin),expression:"user.partnerRoles && user.partnerRoles.admin"}],attrs:{"exact":"","to":_vm.partnerRoute},model:{value:(_vm.isDisplayingPartner),callback:function ($$v) {_vm.isDisplayingPartner=$$v},expression:"isDisplayingPartner"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{staticClass:"rotate"},[_vm._v("mdi-domain")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(_vm.orgName))])],1)],1)]}}],null,false,2844150358)},[_vm._v(" Organization ")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":_vm.enrollmentRoute},model:{value:(_vm.isDisplayingEnrollment),callback:function ($$v) {_vm.isDisplayingEnrollment=$$v},expression:"isDisplayingEnrollment"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',{staticClass:"rotate"},[_vm._v("mdi-account-plus")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Enrollment")])],1)],1)]}}],null,false,301659696)},[_vm._v(" Enrollment ")]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [(_vm.user.partnerRoles && _vm.user.partnerRoles.admin)?_c('v-list-item',_vm._g(_vm._b({on:{"click":function($event){return _vm.openNewBillingPlatform()}},model:{value:(_vm.isDisplayingReports),callback:function ($$v) {_vm.isDisplayingReports=$$v},expression:"isDisplayingReports"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-google-spreadsheet")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Reports")])],1)],1):_vm._e()]}}],null,false,1023042926)},[_vm._v(" Go to reports ")]),_c('v-list-item',{directives:[{name:"show",rawName:"v-show",value:(_vm.isMobile),expression:"isMobile"}],attrs:{"color":_vm.itemColor},on:{"click":_vm.callSignout}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-logout")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Log Out")])],1)],1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }