var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-navigation-drawer',{attrs:{"app":"","fixed":"","mini-variant-width":"60","width":"330","mini-variant":!_vm.$route.meta.expandedDrawer,"color":"white","mobile-breakpoint":"0","permanent":"","touchless":"","stateless":""}},[_c('v-row',{staticClass:"fill-height flex-nowrap",staticStyle:{"overscroll-behavior":"contain"},attrs:{"no-gutters":""}},[_c('v-navigation-drawer',{attrs:{"dark":"","color":"navColor","floating":"","mini-variant":"","mini-variant-width":"60","permanent":"","stateless":""}},[_c('v-list-item',{on:{"click":function($event){return _vm.navigateHome()}}},[_c('v-list-item-icon',[_c('v-img',{attrs:{"src":"https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FminiHeaderTest.svg?alt=media&token=b85a1a2b-6933-4a2f-90ab-ed68316710df"}})],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Dashboard")])],1)],1),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":{ name: 'Taskboard' }},model:{value:(_vm.isDisplayingDashboard),callback:function ($$v) {_vm.isDisplayingDashboard=$$v},expression:"isDisplayingDashboard"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-view-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Taskboard")])],1)],1)]}}])},[_c('span',[_vm._v("Taskboard")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":{ name: 'Conversations' }},model:{value:(_vm.isDisplayingMessaging),callback:function ($$v) {_vm.isDisplayingMessaging=$$v},expression:"isDisplayingMessaging"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-badge',{attrs:{"value":_vm.unreadConversationsCount,"content":_vm.unreadConversationsCount,"color":"primary","overlap":""}},[_c('v-icon',[_vm._v("mdi-message-reply-text")])],1)],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Conversations")])],1)],1)]}}])},[_c('span',[_vm._v("Conversations")])]),_c('app-drawer-user-navigation-list')],1),_c('v-list',{attrs:{"nav":"","dense":""}},[_c('v-row',{staticClass:"mb-2",attrs:{"no-gutters":"","justify":"center"}},[_c('span',{staticClass:"text-caption"},[_vm._v("BETA")])]),_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":{ name: 'Engagement' }},model:{value:(_vm.isDisplayingActivity),callback:function ($$v) {_vm.isDisplayingActivity=$$v},expression:"isDisplayingActivity"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-chart-donut")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Patient Engagement")])],1)],1)]}}])},[_c('span',[_vm._v("Patient Engagement")])]),(false)?_c('v-tooltip',{attrs:{"right":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-list-item',_vm._g(_vm._b({attrs:{"to":{ name: 'Device Management' }},model:{value:(_vm.isDisplayingDeviceManagement),callback:function ($$v) {_vm.isDisplayingDeviceManagement=$$v},expression:"isDisplayingDeviceManagement"}},'v-list-item',attrs,false),on),[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-devices")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Devices")])],1)],1)]}}],null,false,108335263)},[_c('span',[_vm._v("Devices")])]):_vm._e()],1)],1),_c('v-col',{staticStyle:{"overflow-y":"auto","overflow-x":"hidden"}},[_c('v-card',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.meta.expandedDrawer),expression:"$route.meta.expandedDrawer"}],attrs:{"flat":"","color":"transparent","max-width":"270"}},[_c('portal-target',{directives:[{name:"show",rawName:"v-show",value:(_vm.$route.meta.expandedDrawer),expression:"$route.meta.expandedDrawer"}],attrs:{"name":"subdrawer","slim":""}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }