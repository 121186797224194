export default {
  getCoordinatesForBatchAddresses({ commit, rootState }, addresses) {
    addresses.forEach(address => {
      let token =
        'pk.eyJ1IjoiZGJyb2RlZ2FyZCIsImEiOiJja21qdWVpNTgwcm1mMnZsZjBma2ltczh3In0.fiEoNlKkN34OZVH6yJ4W0A'
      //go get the address from that geocode thing.
      if (address.text !== 'None') {
        let match = rootState.populationmap.coordinates.find(
          coord => coord.zip == address.text
        )

        if (!match) {
          let url =
            'https://api.mapbox.com/geocoding/v5/mapbox.places/' +
            address.text +
            '.json?country=US&access_token=' +
            token

          fetch(url)
            .then(resp => resp.json())
            .then(function(data) {
              if (data.features.length > 0) {
                let point = {
                  center: data.features[0].center,
                  name: data.features[0].place_name,
                  zip: address.text,
                  count: address.count,
                }
                commit('setCoordinates', point)
              }
            })
            .catch(error => {
              // handle the error
              console.log(error)
            })
        }
      }
    })
  },
}
