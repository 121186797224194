import merge from 'lodash/merge'
import bus from '@/core/helpers/bus'

export default {
  data() {
    return {
      show: false,
      options: {},
      defaultOptions: {
        fullscreen: false,
        maxWidth: 520
      },
      skipNextAction: false
    }
  },
  computed: {
    fullscreen() {
      return this.$vuetify.breakpoint.xsOnly
    },
    transition() {
      return this.fullscreen ? 'dialog-bottom-transition' : undefined
    }
  },
  methods: {
    open(options = {}) {
      let alias = options.alias || this.name
      this.show = true
      this.skipNextAction = false
      this.options = merge({}, this.defaultOptions, options)
      this.onOpen()
      this.$emit('open')

      bus.$on(`close${alias}Dialog`, () => {
        this.cancel()
      }) // subscribe to event openNAMEDialog
    },
    ok(data = {}) {
      this.show = false
      if (!this.skipNextAction) {
        this.skipNextAction = true
        if (this.options.ok) {
          this.options.ok(data)
        }
        this.onOk()
        let payload = { type: 'ok', data }
        this.onClose()
        this.$emit('close', payload)
      }
    },
    cancel() {
      this.show = false
      if (!this.skipNextAction) {
        this.skipNextAction = true
        if (this.options.cancel) {
          this.options.cancel()
        }
        this.onCancel()
        let payload = { type: 'cancel' }
        this.onClose()
        this.$emit('close', payload)
      }
    },
    onOpen() {},
    onOk() {},
    onCancel() {},
    onClose() {},
    getQueryString() {
      return this.$router.currentRoute.query[this.className]
    },
    serializeQuery() {
      return true
    },
    deserializeQuery() {
      return true
    }
  },
  created() {
    let name = this.$options.name
    bus.$on(`open${name}`, this.open) // subscribe to event open{ComponentName}
  },
  watch: {
    /**
     * This is here when you click overlay and the dialog closes. Only perform
     * is ignoreNext is false (meaning it hasn't already been processed by cancel()
     * @param {Boolean} val
     */
    show(val) {
      if (!val) {
        if (!this.skipNextAction) {
          this.cancel()
        }
      }
      this.skipNextAction = false
    }
  }
}
