export default {
  // patients list and dashboard Managment
  refreshingDashboard: false,
  refreshingMessages: false,
  refreshingUserPatients: false,
  exportingData: false,
  // User patients
  userPatients: [],
  enrolledPatients: [],
  enrolledPatientsDictionary: {},
  counts: {all: 0, newPatients: 0, outreach: 0, atRisk: 0, active: 0, sevenDays: 0, thirtyDays: 0, sixtyDays: 0, sixtyPlus: 0, noReadings: 0, alerts: 0, inactive: 0},
  todayTasks: null,
  patientMessages: [],
  unreadMessages: 0,
  messageTableKey: 0,
  dashboardTab: 0,
  patientTags: [],
  todayTasksToggle: false,
  patientsWithUnreadMessages: 0,
  patientsWithMessages: [],
  dashboardFreshness: 0,
  patientsWithUnseenBreaches: 0,
  patientsWithBreaches: [],
  patientsNeedingOutreach: [],
  patientsAtRisk: [],
}
