<template>
  <v-navigation-drawer
    app
    fixed
    mini-variant-width="60"
    width="330"
    :mini-variant="!$route.meta.expandedDrawer"
    color="white"
    mobile-breakpoint="0"
    permanent
    touchless
    stateless
  >
    <v-row style="overscroll-behavior: contain" no-gutters class="fill-height flex-nowrap">
      <v-navigation-drawer dark color="navColor" floating mini-variant mini-variant-width="60" permanent stateless>
        <v-list-item @click="navigateHome()">
          <v-list-item-icon>
            <v-img
              src="https://firebasestorage.googleapis.com/v0/b/onechart-monitor-prod.appspot.com/o/UX%2FminiHeaderTest.svg?alt=media&token=b85a1a2b-6933-4a2f-90ab-ed68316710df"
            ></v-img>
          </v-list-item-icon>
          <v-list-item-content>
            <v-list-item-title>Dashboard</v-list-item-title>
          </v-list-item-content>
        </v-list-item>

        <v-list nav dense>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingDashboard" :to="{ name: 'Taskboard' }">
                <v-list-item-icon>
                  <v-icon>mdi-view-dashboard</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Taskboard</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>Taskboard</span>
          </v-tooltip>

          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingMessaging" :to="{ name: 'Conversations' }">
                <v-list-item-icon>
                  <v-badge
                    :value="unreadConversationsCount"
                    :content="unreadConversationsCount"
                    color="primary"
                    overlap
                  >
                    <v-icon>mdi-message-reply-text</v-icon>
                  </v-badge>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Conversations</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>Conversations</span>
          </v-tooltip>
          <app-drawer-user-navigation-list />
        </v-list>

        <v-list nav dense>
          <v-row no-gutters justify="center" class="mb-2">
            <span class="text-caption">BETA</span>
          </v-row>
          <v-tooltip right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item v-bind="attrs" v-on="on" v-model="isDisplayingActivity" :to="{ name: 'Engagement' }">
                <v-list-item-icon>
                  <v-icon>mdi-chart-donut</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Patient Engagement</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>Patient Engagement</span>
          </v-tooltip>

          <v-tooltip v-if="false" right>
            <template v-slot:activator="{ on, attrs }">
              <v-list-item
                v-bind="attrs"
                v-on="on"
                v-model="isDisplayingDeviceManagement"
                :to="{ name: 'Device Management' }"
              >
                <v-list-item-icon>
                  <v-icon>mdi-devices</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Devices</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </template>
            <span>Devices</span>
          </v-tooltip>
        </v-list>
      </v-navigation-drawer>
      <v-col style="overflow-y: auto; overflow-x:hidden">
        <v-card v-show="$route.meta.expandedDrawer" flat color="transparent" max-width="270">
          <portal-target v-show="$route.meta.expandedDrawer" name="subdrawer" slim></portal-target>
        </v-card>
      </v-col>
    </v-row>
  </v-navigation-drawer>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import get from 'lodash/get'
import AppDrawerUserNavigationList from './AppDrawerUserNavigationList.vue'

export default {
  components: { AppDrawerUserNavigationList },
  methods: {
    ...mapActions('conversation', ['getUnreadConversationsCount', 'stopCheckForUnreadCount']),
    ...mapMutations('taskboard', ['setTaskboardTab']),
    navigateHome() {
      // if you're already on the taskboard, move to the first tab.
      // if youre not on the taskboard, go to the taskboard
      if (this.$route.name === 'Taskboard') {
        this.setTaskboardTab(0)
      } else {
        this.$router.push({ name: 'Taskboard' })
      }
    },
  },
  computed: {
    ...mapState('auth', ['user', 'authToken', 'appTheme', 'partner']),
    ...mapState('conversation', ['unreadConversationsCount']),
    isDisplayingAlerts() {
      return 'Alerts' === this.$route.name
    },
    isDisplayingActivity() {
      return 'Engagement' === this.$route.name
    },
    isDisplayingDeviceManagement() {
      return 'Device Management' === this.$route.name
    },
    isDisplayingDashboard() {
      return 'Dashboard' === this.$route.name
    },
    isDisplayingMessaging() {
      return 'Messaging' === this.$route.name
    },
    isDisplayingConversations() {
      return 'Conversations' === this.$route.name
    },
  },
  watch: {
    partner(partner) {
      if (this.authToken) {
        this.getUnreadConversationsCount({ orgId: get(partner, '_id', 0) })
      } else {
        this.stopCheckForUnreadCount()
      }
    },
    user(val) {
      if (!val) {
        this.stopCheckForUnreadCount()
      }
    },
  },
  mounted() {
    this.convoCountTimer = setInterval(() => {
      if (this.authToken) {
        this.getUnreadConversationsCount({ orgId: get(this.partner, '_id', 0) })
      }
    }, 60000)
  },
  destroyed() {
    clearInterval(this.convoCountTimer)
  },
  // watch: {
  //   partner(partner) {
  //     if (this.authToken) {
  //       this.getUnreadConversationsCount({ orgId: get(partner, '_id', 0) })
  //     }
  //   },
  // },
  // mounted() {
  //   this.convoCountTimer = setInterval(() => {
  //     if (this.authToken) {
  //       this.getUnreadConversationsCount({ orgId: get(this.partner, '_id', 0) })
  //     }
  //   }, 60000)
  // },
  // destroyed() {
  //   clearInterval(this.convoCountTimer)
  // },
}
</script>

<style></style>
