export default {
  setPreparedMetrics(state, preparedMetrics) {
    state.preparedMetrics = preparedMetrics
  },
  setLoadingMetrics(state, loading) {
    state.loadingMetrics = loading
  },
  resetViability(state) {
    state.viableList = []
  },
  newViable(state, patient) {
    state.viableList.push(patient)
  },
  setGradingPatients(state, grading) {
    state.gradingPatients = grading
  },
  setArrayOfDaysOfData(state, arrayOfDaysOfData) {
    state.arrayOfDaysOfData = arrayOfDaysOfData
  },
  clearDaysOfDataDict(state) {
    state.daysOfDataDict = {}
  },
  insertIntoDaysOfDataDict(state, element) {
    if (state.daysOfDataDict[element.date]) {
      if (!state.daysOfDataDict[element.date].includes(element.type)) {
        state.daysOfDataDict[element.date].push(element.type)
        state.daysOfDataWatcher = JSON.stringify(state.daysOfDataDict)
      }
    } else {
      state.daysOfDataDict[element.date] = [element.type]
      state.daysOfDataWatcher = JSON.stringify(state.daysOfDataDict)
    }
  },
  extraCommit() {
  }
}
