import authGuard from './authGuard'

export default {
  beforeEnter(to, from, next) {
    authGuard.beforeEnter(to, from, () => {
      to.name === 'Taskboard' && from.query.redirect_after_login ? location.href = from.query.redirect_after_login : console.log()
      next()
    })
  }
}
