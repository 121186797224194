import Vue from 'vue'
import { getAccessToken } from '../../shared-ui/store/sso'
import clone from 'lodash/clone'
import consts from '@/consts'
import get from 'lodash/get'
import store from '@/store'
import { HttpClient } from '../../shared-ui/helpers/httpClient'

const requestHandler = async (ctx, request) => {
  const accessToken = await getAccessToken()
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`
  } else {
    store.dispatch('auth/logout', { error: 'Invalid refresh token' })
  }
  return request
}

const enrollmentsClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_ENROLLMENTS_API_URL,
  requestHandler,
})

const patientsClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_PATIENTS_API_URL,
  requestHandler,
})

const ordersClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_ORDERS_API_URL,
  requestHandler,
})

function populateLastNote(candidate) {
  let logs = candidate.logs || []
  let log = logs[logs.length - 1] || {}
  candidate.lastNote = log.note || ''
}

export default {
  namespaced: true,
  state: {
    currentCandidate: null,
    candidates: null,
    startCallTime: 0,
    currentPatient: null,
  },
  mutations: {
    setCurrentCandidate(state, candidate) {
      if (candidate) {
        if (!state.currentCandidate || state.currentCandidate.id !== candidate.id) {
          populateLastNote(candidate)
          state.currentCandidate = clone(candidate)
        }
      } else {
        state.currentCandidate = null
      }
    },
    setCandidates(state, candidates) {
      state.candidates = candidates
    },
    setStartCallTime(state, time) {
      state.startCallTime = time
    },
    updateCandidate(state, candidate) {
      let candidates = state.candidates
      for (let i = 0; i < candidates.length; i++) {
        if (candidates[i].id === candidate.id) {
          populateLastNote(candidate)
          Vue.set(candidates, i, candidate)
          break
        }
      }
    },
    setCurrentPatient(state, patient) {
      state.currentPatient = patient
    },
  },
  actions: {
    async initCallTime({ commit }) {
      commit('setStartCallTime', new Date())
    },
    async getCandidates({ commit }) {
      let { data } = await enrollmentsClient.post('/GetCandidates', {
        status: [consts.STATUS_TYPES.CALLBACK, consts.STATUS_TYPES.NO_ANSWER, consts.STATUS_TYPES.NOT_CONTACTED],
      })
      if (data && data.candidates) {
        for (let i = 0; i < data.candidates.length; i++) {
          let patient = data.candidates[i]
          populateLastNote(patient)
        }
        commit('setCandidates', data.candidates)
      } else {
        commit('setCandidates', [])
      }
    },
    async getCandidate({ commit }, { id }) {
      try {
        let { data } = await enrollmentsClient.post('/GetCandidate', { id })
        commit('setCurrentCandidate', data.candidate)
        return data.candidate
      } catch (e) {
        return null
      }
    },
    async findCandidate({ commit }, { ownerId, ownerPatientId }) {
      try {
        let { data } = await enrollmentsClient.post('/FindCandidate', {
          ownerId,
          ownerPatientId,
        })
        commit('setCurrentCandidate', data.candidate)
        return data.candidate
      } catch (e) {
        return null
      }
    },
    async getPatient({ commit }, { id = '', ownerId = '', ownerPatientId = '', smsPhone = '' }) {
      try {
        let { data } = await patientsClient.post('/GetPatient', {
          id,
          ownerId,
          ownerPatientId,
          smsPhone,
        })
        commit('setCurrentPatient', data.patient)
        return data.patient
      } catch (e) {
        commit('setCurrentPatient', null)
        return null
      }
    },
    async createPatient({ commit }, candidate) {
      let { data } = await patientsClient.post('/CreatePatient', {
        owner: candidate.owner,
        ownerPatientId: candidate.ownerPatientId,
        firstName: candidate.firstName,
        lastName: candidate.lastName,
        gender: candidate.gender,
        birthdate: candidate.birthdate,
        primaryPhone: candidate.primaryPhone,
        smsPhone: candidate.smsPhone,
        email: candidate.email,
        address: candidate.address,
      })
      commit('setCurrentPatient', data.patient)
      return data.patient
    },
    async createOrder(_, { patient, deviceName }) {
      let { data } = await ordersClient.post('/CreateOrder', {
        patientId: patient.id,
        owner: patient.owner,
        ownerPatientId: patient.ownerPatientId,
        shippingRecipientName: patient.firstName + ' ' + patient.lastName,
        shippingAddress: patient.address,
        deviceName,
      })
      return data.order
    },
    async getEnrollmentStatus({ dispatch, state }, { candidate: filter }) {
      await dispatch('getCandidate', {
        id: filter.id,
      })
      if (get(state.currentCandidate, 'status') === 'enrolled') {
        return true
      }
      if (get(state.currentPatient, 'ownerPatientId') == get(state.currentCandidate, 'ownerPatientId')) {
        await dispatch('getPatient', {
          id: filter.patientId,
          ownerId: filter.owner.id,
          ownerPatientId: filter.ownerPatientId,
        })
      }
      return !!get(state.currentPatient, 'rpm.candidate.enrolled')
    },
    async updateCandidateStatus({ commit }, { id, log }) {
      let { data } = await enrollmentsClient.post('/UpdateCandidateStatus', {
        id,
        ...log,
      })
      let candidate = data.candidate
      commit('setCurrentCandidate', null)
      commit('updateCandidate', candidate)
    },
    async enrollCandidate({ dispatch, state }, { candidate, devices, log }) {
      try {
        if (!state.currentPatient) {
          await dispatch('createPatient', candidate)
        }
        devices.forEach(async device => {
          await dispatch('createOrder', {
            candidate,
            patientId: state.currentPatient.id,
            deviceName: device,
          })
        })
        dispatch('updateCandidateStatus', { id: candidate.id, log })
      } catch (e) {
        return e
      }
    },
    async markPatientAsEnrolled(_, patientId) {
      try {
        await patientsClient.post('/MarkPatientAsEnrolled', { id: patientId })
        return true
      } catch (e) {
        return false
      }
    },
    setCurrentPatient({ commit }, patient) {
      commit('setCurrentPatient', patient)
    },
    setCurrentCandidate({ commit }, candidate) {
      commit('setCurrentCandidate', candidate)
    },
  },
}
