export default {
  setPatients(state, patients) {
    state.patients = patients
  },
  setPatient(state, patient) {
    state.patient = patient
  },
  setPatientEditingMode(state, bool) {
    state.patientEditingMode = bool
  },
  setSharedPatient(state, patient) {
    state.sharedPatient = patient
  },
  setShareExpired(state, expiredBool) {
    state.shareExpired = expiredBool
  },
  setSharedFiles(state, sharedFiles) {
    state.sharedFiles = sharedFiles
  },
  setPartnerPatientIDSearchResults(state, searchResults) {
    state.partnerPatientIDSearchResults = searchResults
  },
  setTemporaryAuthToken(state, token) {
    state.temporaryAuthToken = token
  },
  setIsLoadingFiles(state, bool) {
    state.isLoadingFiles = bool
  },
  setIsLoadingPatients(state, bool) {
    state.isLoadingPatients = bool
  },
  setIsSearchingChart(state, bool) {
    state.isSearchingChart = bool
  },
  setPatientsOffset(state, offset) {
    state.patientsOffset = offset
  },
  setPatientsCount(state, count) {
    state.patientsCount = count
  },
  extraCommit() {
  },
  setPatientsTotal(state, total) {
    state.patientsTotal = total
  },
  setPatientsNext(state, next) {
    state.patientsNext = next
  },
  setSearchTermLastName(state, lastName) {
    state.searchTermLastName = lastName
  },
  setRequestForPublicUpload(state, request) {
    state.requestForPublicUpload = request
  },
  setExternalUploadLink(state, link) {
    state.externalUploadLink = link
  },
  setSearchTermFirstName(state, firstName) {
    state.searchTermFirstName = firstName
  },
  setSearchOrder(state, order) {
    state.searchOrder = order
  },
  setSearchTermBirthdate(state, birthdate) {
    state.searchTermBirthdate = birthdate
  },
  setFiles(state, files) {
    state.files = files
  },
  setRequests(state, requests) {
    state.requests = requests
  },
  setMedResults(state, medResults) {
    state.medResults = medResults
  },
  setProbResults(state, probResults) {
    state.probResults = probResults
  },
  setUserGroupsPermissions(state, permissions) {
    state.userGroups = permissions
  },
  setSharedFilesExpiration(state, expiration) {
    state.sharedFilesExpiration = expiration
  },
  setIndividualPermissions(state, permissions) {
    state.individualPermissions = permissions
  },
  setPreliminaryPermissions(state, permissions) {
    state.preliminaryPermissions = permissions
  },
  setLinkExpired(state, expired) {
    state.linkExpired = expired
  },
  setSelectedFileDetails(state, file) {
    state.selectedFileDetails = file
  },
  setChartSearchResults(state, results) {
    state.chartSearchResults = results
  },
  setChartSearchTerm(state, term) {
    state.chartSearchTerm = term
  },
  setTotalPatientCount(state, count) {
    state.totalPatientCount = count
  },
  setRefreshingDashboard(state, refreshingDashboard) {
    state.refreshingDashboard = refreshingDashboard
  },
  setSearchTerm(state, searchTerm) {
    state.searchTerm = searchTerm
  },
  setSearchResultsPatients(state, searchResultsPatients) {
    state.searchResultsPatients = searchResultsPatients
  },
  setSearchResultsPageInfo(state, searchResultsPageInfo) {
    state.searchResultsPageInfo = searchResultsPageInfo
  },
  setPerformingSearch(state, performingSearch) {
    state.performingSearch = performingSearch
  },
  clearPatientSearch(state) {
    state.searchResultsPatients = []
    state.searchResultsPageInfo = null
    state.searchTerm = null
    state.performingSearch = false
  },
  prepareForLogout(state) {
    state.searchResultsPatients = []
    state.searchResultsPageInfo = null
    state.searchTerm = null
    state.performingSearch = false
    state.dashboardPageInfo = null
    state.patientLogs = []
    state.patientLogsPageInfo = null
  },
  // request list management
  setLoadingRequests(state, loading) {
    state.loadingRequests = loading
  },
  setRequestsList(state, requestsList) {
    state.requestsList = requestsList
  },
  // followers management
  setPermissions(state, permissions) {
    state.permissions = permissions
  },
  setLoadingFollowers(state, loading) {
    state.loadingFollowers = loading
  },
  // set log list
  setPatientLogsPageInfo(state, pageInfo) {
    state.patientLogsPageInfo = pageInfo
  },
  setPatientLogs(state, logs) {
    state.patientLogs = logs
  },
  setRefreshingLogs(state, refreshing) {
    state.refreshingLogs = refreshing
  },
  // patient info form
  setGettingPatientInfo(state, gettingInfo) {
    state.gettingPatientInfo = gettingInfo
  },
  setLookingUpProblem(state, looking) {
    state.lookingUpProblem = looking
  },
  setLookingUpMedication(state, looking) {
    state.lookingUpMedication = looking
  },
  setUpdatingThresholds(state, updating) {
    state.updatingPatientThresholds = updating
  },
  setDisplayedChartNotes(state, number) {
    state.displayedChartNotes = number
  },
  // video calls
  setActiveVideoCall(state, active) {
    state.activeVideoCall = active
    if (!active) {
      state.activeVideoCallSelfLink = null
      state.activeVideoCallInviteeLink = null
    }
  },
  setActiveVideoCallSelfLink(state, link) {
    state.activeVideoCallSelfLink = link
  },
  setActiveVideoCallInviteeLink(state, link) {
    state.activeVideoCallInviteeLink = link
  },
  setLastChartReview(state, time) {
    state.lastChartReview = time
  },
}
