import { HttpClient } from '../../shared-ui/helpers/httpClient'
import { getAccessToken } from '../../shared-ui/store/sso'
import store from '@/store'

const requestHandler = async (ctx, request) => {
  const accessToken = await getAccessToken(true) // force to get new access token
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`
  } else {
    store.dispatch('auth/logout', { error: 'Invalid refresh token' })
  }
  return request
}

const rpmClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_RPM_API_URL,
  requestHandler,
})

export default {
  namespaced: true,
  state: {
    patient: null,
    patientDevices: [],
    orgDevices: [],
  },
  mutations: {
    setPatient(state, patient) {
      state.patient = patient
    },
    setPatientDevices(state, devices) {
      state.patientDevices = devices
    },
    setOrgDevices(state, devices) {
      state.orgDevices = devices
    },
  },
  actions: {
    async assignDevice(_, { id, patientId }) {
      let { data } = await rpmClient.post('/AssignDevice', {
        id,
        patientId,
      })
      return data.device
    },
    async getOrCreateDevice(_, { kind, refId }) {
      let { data } = await rpmClient.post('/GetOrCreateDevice', {
        kind,
        refId,
      })
      return data.device
    },
    async getPatient({ commit }, { patientId, orgId, orgPatientId }) {
      try {
        let { data } = await rpmClient.post('/GetPatient', {
          patientId,
          orgId,
          orgPatientId,
        })
        commit('setPatient', data.patient)
        return data.patient
      } catch (e) {
        commit('setPatient', null)
        return null
      }
    },
    async getPatientDevices({ commit }, patientId) {
      let { data } = await rpmClient.post('/GetPatientDevices', {
        patientId,
        pageSize: 0,
        pageToken: '',
      })
      commit('setPatientDevices', data.devices)
      return data.devices
    },
    async getOrgDevices({ commit }, orgId) {
      let { data } = await rpmClient.post('/GetOrgDevices', {
        pageSize: 0,
        pageToken: '',
        orgId: orgId,
      })
      console.log(data)
      commit('setOrgDevices', data.devices)
      return data.devices
    },
  },
}
