export default {
  setPatientNotes(state, notes) {
    state.patientNotes = notes
  },
  setPatientNoteTypes(state, types) {
    state.patientNoteTypes = types
  },
  setActiveUsersForPatientNotes(state, users) {
    state.activeUsersForPatientNotes = users
  },
  setSavedNoteTemplates(state, templates) {
    state.savedNoteTemplates = templates
  },
  setLoadingPatientNotes(state, loading) {
    state.loadingPatientNotes = loading
  },
  extraCommit() {
  }
}
