export default {
  updateTaskboardKey(state) {
    state.taskboardKey = state.taskboardKey + 1
  },
  setRefreshingMessages(state, refreshing) {
    state.refreshingMessages = refreshing
  },
  setPatientMessages(state, messages) {
    state.patientMessages = messages
  },
  setUnreadMessages(state, count) {
    if (count < 0) {
      count = 0
    }
    state.unreadMessages = count
  },
  setPatientsWithMessages(state, patients) {
    state.patientsWithMessages = patients
  },
  // set the taskboard tab numbers
  setUnreadThresholdAlerts(state, count) {
    state.patientsWithUnseenBreaches = count
  },
  setZeroDaysInactivity(state, count) {
    state.patientsWithZeroDaysOfInactivity = count
  },
  setOneDayInactivity(state, count) {
    state.patientsWithOneDayOfInactivity = count
  },
  setTwoDaysInactivity(state, count) {
    state.patientsWithTwoDaysOfInactivity = count
  },
  setThreeDaysInactivity(state, count) {
    state.patientsWithThreeDaysOfInactivity = count
  },
  setFourDaysInactivity(state, count) {
    state.patientsWithFourDaysOfInactivity = count
  },
  setFiveDaysInactivity(state, count) {
    state.patientsWithFiveDaysOfInactivity = count
  },
  setSixDaysInactivity(state, count) {
    state.patientsWithSixDaysOfInactivity = count
  },
  setSevenPlusDaysInactivity(state, count) {
    state.patientsWithSevenPlusDaysOfInactivity = count
  },
  setLowerInactivityBucket(state, count) {
    state.lowerInactivityBucket = count
  },
  setUpperInactivityBucket(state, count) {
    state.upperInactivityBucket = count
  },
  setNoData(state, count) {
    state.patientsWithNoData = count
  },
  // end set taskboard tab numbers
  setPatientsWithThresholdAlerts(state, patients) {
    state.patientsWithThresholdAlerts = patients
  },
  setUnreadOutreachAlerts(state, count) {
    state.unreadOutreachAlerts = count
  },
  setPatientsNeedingOutreach(state, patients) {
    state.patientsNeedingOutreach = patients
  },
  setUnreadAtRiskAlerts(state, count) {
    state.unreadAtRiskAlerts = count
  },
  setPatientsAtRisk(state, patients) {
    state.patientsAtRisk = patients
  },
  setPatientsWithoutData(state, patients) {
    state.patientsWithoutData = patients
  },
  setPatientsWithoutDataAlerts(state, count) {
    state.patientsWithoutDataAlerts = count
  },
  setUnreadFinishOnboardingAlerts(state, count) {
    state.unreadFinishOnboardingAlerts = count
  },
  setPatientsFinishingOnboarding(state, patients) {
    state.patientsFinishingOnboarding = patients
  },
  setTaskboardTab(state, tab) {
    state.taskboardTab = tab
  },
  setTaskboardPage(state, page) {
    state.taskboardPage = page
  },
  setDismissedAlertsDictionary(state, dismissedAlerts) {
    state.dismissedAlertsDictionary = dismissedAlerts
  },
  setSavedTextTemplates(state, templates) {
    state.savedTextTemplates = templates
  },
  setTodayTasks(state, tasks) {
    state.todayTasks = tasks
  },
  setTodayTasksToggle(state, toggle) {
    state.todayTasksToggle = toggle
  },
  /// V2 Mutations
  setEnrolledPatients(state, patients) {
    state.enrolledPatients = patients
  },
  setUnenrolledPatients(state, patients) {
    state.unenrolledPatients = patients
  },
  setStoredSelectedPatients(state, patients) {
    state.storedSelectedPatients = patients
  },
  setEnrolledPatientsDictionary(state, patients) {
    state.enrolledPatientsDictionary = patients
  },
  setRefreshingTaskboard(state, refreshing) {
    state.refreshingTaskboard = refreshing
  },
  setUserPatients(state, userPatients) {
    state.userPatients = userPatients
  },
  setRefreshingUserPatients(state, userPatients) {
    state.refreshingUserPatients = userPatients
  },
  setTaskboardFreshness(state, freshness) {
    state.taskboardFreshness = freshness
  },
  setPatientTags(state, patientTags) {
    state.patientTags = patientTags
  },
  addASinglePatientTag(state, tag) {
    state.patientTags.push(tag)
  },
  setPatientMetricTypes(state, patientMetricTypes) {
    state.patientMetricTypes = patientMetricTypes
  },
  setPatientConditions(state, patientConditions) {
    state.patientConditions = patientConditions
  },
  setPatientZips(state, patientZips) {
    state.patientZips = patientZips
  },
  setPatientDevices(state, patientDevices) {
    state.patientDevices = patientDevices
  },
  extraCommit() {},
}
