<template>
  <div v-if="user">
    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item
          v-bind="attrs"
          v-on="on"
          v-if="user"
          v-model="isDisplayingAccount"
          :to="accountRoute"
        >
          <v-list-item-icon>
            <v-icon>mdi-account-circle</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title
              >{{ user.firstName }} {{ user.lastName }}</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
      </template>
      {{ user.firstName }} {{ user.lastName }}
    </v-tooltip>

    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item
          v-bind="attrs"
          v-on="on"
          v-model="isDisplayingPartner"
          v-show="user.partnerRoles && user.partnerRoles.admin"
          exact
          :to="partnerRoute"
        >
          <v-list-item-icon>
            <v-icon class="rotate">mdi-domain</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>{{ orgName }}</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      Organization
    </v-tooltip>

    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item
          v-bind="attrs"
          v-on="on"
          v-model="isDisplayingEnrollment"
          :to="enrollmentRoute"
        >
          <v-list-item-icon>
            <v-icon class="rotate">mdi-account-plus</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Enrollment</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      Enrollment
    </v-tooltip>

    <v-tooltip right>
      <template v-slot:activator="{ on, attrs }">
        <v-list-item
          v-bind="attrs"
          v-on="on"
          @click="openNewBillingPlatform()"
          v-model="isDisplayingReports"
          v-if="user.partnerRoles && user.partnerRoles.admin"
        >
          <v-list-item-icon>
            <v-icon>mdi-google-spreadsheet</v-icon>
          </v-list-item-icon>

          <v-list-item-content>
            <v-list-item-title>Reports</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      Go to reports
    </v-tooltip>

    <v-list-item :color="itemColor" v-show="isMobile" @click="callSignout">
      <v-list-item-icon>
        <v-icon>mdi-logout</v-icon>
      </v-list-item-icon>

      <v-list-item-content>
        <v-list-item-title>Log Out</v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  props: {},
  computed: {
    ...mapState('auth', [
      'user',
      'appTheme',
      'partner',
      'temporaryAuthToken',
      'tempAuthExpiration',
    ]),
    ...mapState('patient', ['patient']),

    isDisplayingReports() {
      return 'Reports' === this.$route.name
    },
    isDisplayingAccount() {
      return 'Account' === this.$route.name
    },
    isDisplayingEnrollment() {
      return 'Enrollment' === this.$route.name
    },
    isDisplayingDevices() {
      return 'Devices' === this.$route.name
    },
    isDisplayingPartner() {
      return 'Partner' === this.$route.name
    },
    orgName() {
      if (this.partner && this.partner.name) {
        return this.partner.name
      }
      return 'Organization'
    },
    itemColor() {
      if (this.appTheme.kroger) {
        return '#4471B7'
      } else {
        return 'white'
      }
    },

    accountRoute() {
      return { name: 'Account', params: { id: this.user._id } }
    },
    partnerRoute() {
      return {
        name: 'Partner',
        params: { id: this.user.partnerID },
      }
    },
    enrollmentRoute() {
      return {
        name: 'Enrollment',
        params: { id: this.user.partnerID },
      }
    },
    reportsRoute() {
      return {
        name: 'Reports',
        params: { id: this.user.partnerID },
      }
    },

    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
  },
  methods: {
    openNewBillingPlatform() {
      this.child = window.open('http://partners.novorpm.com', 'null', null)
    },
    callSignout() {
      this.prepareForSigningOut()
      this.signOut().then(() => {
        this.$router.push({ name: 'Login' })`bus`.$emit('toast', {
          type: 'success',
          text: 'Logged Out',
        })
      })
    },
  },
}
</script>

<style></style>
