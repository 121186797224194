import Vue from 'vue'
import consts from '@/consts'

export default {
  getDashboardRangeForMetric({ commit, dispatch }, { id, metricName, end, start }) {
    commit('extraCommit', null)
    let url =
      '/Patients/' +
      id +
      '/PatientEvents?/events?alt=json&order=-timestamp&eventType=' +
      metricName +
      '&start=' +
      start +
      '&end=' +
      end
    return Vue.axios.get(url).then(data => {
      let events = []
      data.data.items.forEach(event => {
        dispatch('taskboard/prepareEventData', event, { root: true }).then(preparedData => {
          events.push(preparedData)
        })
      })
      return events
    })
  },
  exportFacesheet({ dispatch }, { patient: patient, start: start }) {
    let startStamp = start

    let metricOrder = [
      'MEASUREMENT_BLOOD_PRESSURE',
      'MEASUREMENT_PULSE',
      'MEASUREMENT_BLOOD_GLUCOSE',
      'MEASUREMENT_BODY_WEIGHT',
      'MEASUREMENT_ECG',
    ]
    // this should also set the order. BP goes first, then HR, then glucose, then weight.
    let displayedMetrics = []
    metricOrder.forEach(metric => {
      if (patient.patientMetricList.includes(metric)) {
        displayedMetrics.push({
          name: metric,
          metricReference: consts.metricList[metric],
        })
      }
    })

    // this function creates the table headers, and calculates the column widths specific to the metrics this particular patient has.
    function createHeaders(keys) {
      var result = []
      for (var i = 0; i < keys.length; i += 1) {
        let standardWidth = 80
        let w = standardWidth
        if (i == 0) {
          // width - 50 * number of items
          w = 735 - standardWidth * displayedMetrics.length
        }
        let headerTitle = 'Date'
        if (consts.metricList[keys[i]]) {
          headerTitle = consts.metricList[keys[i]].dashboardAbbreviation
        }

        result.push({
          id: keys[i],
          name: keys[i],
          prompt: headerTitle,
          width: w,
          align: 'left',
          padding: 10,
        })
      }
      return result
    }

    function getDateAndTimeFromTimestamp(timestamp) {
      let d = new Date(timestamp)

      let hours = d.getHours()
      let minutes = d.getMinutes()
      let ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      hours = hours ? hours : 12 // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes
      let strTime = hours + ':' + minutes + ' ' + ampm
      return (
        ('0' + (d.getMonth() + 1)).slice(-2) +
        '/' +
        ('0' + d.getDate()).slice(-2) +
        '/' +
        d.getFullYear() +
        ' ' +
        strTime
      )
    }

    // this section fetches the latest metrics within the date range for each of the metrics that are available for this patient
    let mainDict = {}
    let numberOfMetrics = displayedMetrics.length
    displayedMetrics.forEach(metric => {
      console.log(metric)
      dispatch('getDashboardRangeForMetric', {
        id: patient._id,
        metricName: metric.name,
        start: startStamp,
        end: new Date().getTime(),
      }).then(data => {
        if (data[0]) {
          // there is data
          data.forEach(dataPoint => {
            if (mainDict[dataPoint.timestamp]) {
              mainDict[dataPoint.timestamp][dataPoint.name] = dataPoint.displayValue.toString()
            } else {
              let objectForPlacement = {}
              displayedMetrics.forEach(metric => {
                objectForPlacement[metric.name] = '-'
              })
              objectForPlacement[dataPoint.eventType] = dataPoint.displayValue.toString()
              objectForPlacement.date = getDateAndTimeFromTimestamp(dataPoint.timestamp)
              mainDict[dataPoint.timestamp] = objectForPlacement
            }
          })
        } else {
          //no data
        }
        numberOfMetrics = numberOfMetrics - 1

        if (numberOfMetrics === 0) {
          // when the counter reaches zero, each of the metrics for this patient have been downloaded, we can sort them into a dictionary now

          let keys = Object.keys(mainDict)

          keys.sort(function(a, b) {
            return b - a
          })

          let data = []
          let id = 0
          keys.forEach(key => {
            let obj = mainDict[key]
            obj.id = id.toString()
            data.push(obj)
            id = id + 1
          })

          let headersProto = ['date']

          console.log('metric name')
          console.log(metric.metricReference.abbreviation)

          displayedMetrics.forEach(metric => {
            headersProto.push(metric.name)
          })
          var headers = createHeaders(headersProto)

          dispatch(
            'patient/generateFacesheet',
            {
              patient: patient,
              startDate: startStamp,
              endDate: new Date().getTime(),
              metrics: displayedMetrics,
              data: data,
              headers: headers,
            },
            { root: true }
          )
        }
      })
    })
  },
}
