import Vue from 'vue'
import firebase from 'firebase/app'
import moment from 'moment-timezone'
import clone from 'lodash/clone'
import get from 'lodash/get'
import consts from '@/consts'

export default {
  getEnrolledPatients({ commit, dispatch, rootState }) {
    function relativeTime(value) {
      return moment(value)
        .tz(moment.tz.guess())
        .fromNow()
    }
    // also store the unenrolled patients
    // this firebase collection lists the dismissed inactivity alerts
    // registering as a listener here updates the local list for alerts dismissed so that when the user fetches new data, it will correctly show the latest 'unread' patients
    // let's kill the dismissed alerts. we dont care about dismissin inactivity alerts anymore.

    // this should load the entire patient list
    let limit = 200
    let offset = 0
    let preparedPatients = []
    let preparedUnenrolledPatients = []

    let enrolledPatientsDictionary = {} // for easy lookup

    // the counts should really just be for the header. they should be easily updatable without calling
    // a Day of inactivity means from 12:00am yesterday to 11:59pm yesterday there were no readings.
    // the numbers here are used only for UNREAD counts. if a patient is in the dismisssed inactivity list, they will not add to the count.

    // patients are tagged with a 'daysOfInactivity' for quick sorting (and displaying). this is the status and doesnt care about the dismissed inactivity alert list
    // patients are tagged with inactivityUnread for making their rows read vs unread (white vs grey) on the unread tab
    // patients are tagged with thresholdBreach for quick sorting
    // patients are tagged with thresholdBreachUnread for making their rows read vs unread on the breach tab
    // patients are tagged with messageUnread for making their rows read vs unread on the messages tab

    // patients are tagged with 'finishOnboarding' if they have not taken a reading?
    // there is no read vs unread for finish onboarding

    function getPatients(offset, limit, preparedPatients, unenrolled) {
      commit('setRefreshingTaskboard', true)
      let url = 'Partners/' + rootState.auth.user.partnerID + '/Patients?limit=' + limit + '&offset=' + offset
      url += '&active=false&order=-rpmMostRecentData'

      return Vue.axios.get(url).then(data => {
        data.data.items.forEach(item => {
          // set name for searching

          item.fullName = item.firstName + ' ' + item.lastName

          item.displayBirthdate =
            item.birthdate.substring(5, 7) +
            '/' +
            item.birthdate.substring(8, 10) +
            '/' +
            item.birthdate.substring(0, 4)

          if (get(item, 'rpm.enrollment.enrolled', true)) {
            item.archived = false
            enrolledPatientsDictionary[item._id] = item
            preparedPatients.push(item)
          } else {
            item.archived = true
            unenrolled.push(item)
          }

          // just get the stuff you need for filtering and the stuff you need for displaying on the taskboard.
          item.conditionFilters = {}
          item.icd10Array = []
          item.conditions.forEach(condition => {
            let icd10 = condition.split('|')[0]
            item.conditionFilters[icd10] = true
            item.icd10Array.push(icd10)
          })

          Object.keys(item.latestEvents).forEach(key => {
            let eventHere = item.latestEvents[key]
            if (eventHere.integrationID) {
              item.deviceAssigned = true
            }
            item[key] = item.latestEvents[key]
            item[key].timestamp = item.latestEvents[key].timestamp

            let metricDetails = consts.metricList[key]
            if (metricDetails) {
              Object.entries(consts.metricList[key]).forEach(metric => {
                const [name, details] = metric
                item[key][name] = details
              })
            }

            if (eventHere.eventType === 'MEASUREMENT_PULSE') {
              item[key].value = Math.round(eventHere.eventData.value)
            } else if (eventHere.eventType === 'MEASUREMENT_BLOOD_GLUCOSE') {
              item[key].value = eventHere.eventData.value
            } else if (eventHere.eventType === 'MEASUREMENT_BLOOD_OXYGEN') {
              item[key].value = eventHere.eventData.value
            } else if (eventHere.eventType === 'MEASUREMENT_BODY_WEIGHT') {
              // weight is stored in kg so we need to convert and make it pretty
              item[key].value = Math.round(eventHere.eventData.value * 2.205 * 10) / 10
            } else if (eventHere.eventType === 'MEASUREMENT_BLOOD_PRESSURE') {
              // weight is stored in kg so we need to convert and make it pretty
              item[key].value = eventHere.eventData.systolicValue + '/' + eventHere.eventData.diastolicValue
            } else if (eventHere.eventType === 'MEASUREMENT_ECG') {
              // weight is stored in kg so we need to convert and make it pretty
              if (eventHere.eventData.afib) {
                item[key].value = 'Possible Afib'
              } else {
                item[key].value = 'No Afib'
              }
            }
            if (eventHere.alert) {
              item.alert = true
            }
          })

          let latestEventTypeArray = Object.keys(item.latestEvents)
          // this also happens to be the list of vitals for the patient
          // some patients have metrics that are not considered "active" (legacy weird event types.) I'll clean this list by only looking at the event types we are currently targeting
          // the current novo event list source of truth is consts.metricList. its a dictionary
          let monitoredEvents = []
          let devicesInUse = []
          latestEventTypeArray.forEach(event => {
            if (consts.metricList[event]) {
              if (!devicesInUse.includes(consts.metricList[event].deviceType)) {
                devicesInUse.push(consts.metricList[event].deviceType)
              }
              monitoredEvents.push(event)

              // if this is a primaryMetric, (as in its not pulse), then it has a device associated with it, so add the device information to the patient object
            }
          })
          item.patientMetricList = monitoredEvents
          item.devicesInUse = devicesInUse

          // this sets the data for the new alerts information

          if (item.rpmMostRecentData) {
            // this patient has data, not tag it with how long it has been since the reading
            item.mostRecentDataTimestamp = Date.parse(item.rpmMostRecentData)

            let startOfToday = new Date()
            startOfToday.setHours(0, 0, 0, 0)

            let sevenDays = new Date()
            sevenDays.setDate(sevenDays.getDate() - 7)
            sevenDays.setHours(0, 0, 0, 0)

            if (item.mostRecentDataTimestamp > startOfToday.getTime()) {
              item.tookReadingToday = true
            } else if (item.mostRecentDataTimestamp > sevenDays.getTime()) {
              item.tookReadingLastSeven = true
            }
          } else {
            // this patient needs to finish onboarding
            item.noData = true
            item.tookReadingToday = false
          }

          // is this a high priority patient?

          // return patients that have recently stopped taking readings?
          // like patients that took one within the last 3 days but not the last two?

          let timeSinceLastReadingCliff = new Date()
          timeSinceLastReadingCliff.setDate(timeSinceLastReadingCliff.getDate() - 30)
          timeSinceLastReadingCliff.setHours(0, 0, 0, 0)

          let timeSinceLastReadingDeadline = new Date()
          timeSinceLastReadingDeadline.setDate(timeSinceLastReadingDeadline.getDate() - 2)
          timeSinceLastReadingDeadline.setHours(0, 0, 0, 0)

          if (
            item.mostRecentDataTimestamp > timeSinceLastReadingCliff.getTime() &&
            item.mostRecentDataTimestamp < timeSinceLastReadingDeadline.getTime()
          ) {
            //item.highPriority = true
          }

          // set the QHP time this period

          if (item.qhpTimeThisPeriod) {
            let today = new Date()
            let monthToUse = today.getMonth() + 1
            let yearToUse = today.getFullYear()
            if (
              item.qhpTimeThisPeriod.start &&
              item.qhpTimeThisPeriod.start.includes(
                yearToUse.toString() + '-' + ('0' + monthToUse.toString()).slice(-2) + '-01'
              )
            ) {
              item.millisecondsThisPeriod = item.qhpTimeThisPeriod.millisecondsThisPeriod
            } else {
              item.millisecondsThisPeriod = 0
            }
          }

          // is this patient close to qualifying? for 454? for 457?

          if (item.millisecondsThisPeriod > 60000 * 16 && item.millisecondsThisPeriod < 60000 * 20) {
            item.close = true
            item.closeOnTime = true
          }
          // determine period end and how close the patient is to qualifying in the current period

          if (item.rpm.enrollment.firstData) {
            // this tells me when the next 99454 is up

            let periodStart = new Date(item.rpm.enrollment.firstData)
            periodStart.setHours(0, 0, 0, 0)

            let rightNow = new Date()

            let monitoringPeriods = []

            while (periodStart.getTime() < rightNow.getTime()) {
              // this will add thirty days to the period start. when this ends, period start will be the start of NEXT period.
              let start = periodStart.getTime()
              periodStart.setDate(periodStart.getDate() + 30)
              let end = new Date(periodStart.getTime() - 1).getTime()
              monitoringPeriods.push({ start: start, end: end })
            }

            //let endDateObject = new Date(monitoringPeriods[monitoringPeriods.length - 1].end)

            item.currentRPMPeriodEnd = monitoringPeriods[monitoringPeriods.length - 1].end
            item.currentRPMPeriodStart = monitoringPeriods[monitoringPeriods.length - 1].start

            let Difference_In_Time = item.currentRPMPeriodEnd - new Date().getTime()

            // To calculate the no. of days between two dates
            item.daysUntilPeriodEnd = Difference_In_Time / (1000 * 3600 * 24)

            item.daysUntilPeriodEndNotCountingToday = Math.floor(Difference_In_Time / (1000 * 3600 * 24))

            let internalActivityArray = []
            if (item.rpm && item.rpm.activity && item.rpm.activity.dates) {
              // dates are 12:00am in teh timezone of the patient.
              // as a dirty fix, add 12 hours
              item.rpm.activity.dates.forEach(date => {
                internalActivityArray.push(Date.parse(date) + 43200000)
              })
            }

            item.arrayOfActivityDates = internalActivityArray

            item.daysThisPeriodWithReadings = internalActivityArray.filter(function(e) {
              return e >= item.currentRPMPeriodStart
            }).length

            // GO SEE HOW THEY WERE DOING THE LAST THREE PERIODS?

            let daysOfReadingsPerPeriod = []

            monitoringPeriods.forEach(period => {
              let periodStart = period.start
              let periodEnd = period.end

              let numberThisPeriod = internalActivityArray.filter(function(e) {
                return e >= periodStart && e <= periodEnd
              }).length

              daysOfReadingsPerPeriod.push(numberThisPeriod)
              period.actualReadings = numberThisPeriod
            })

            let lastThreePeriods = daysOfReadingsPerPeriod.slice(Math.max(daysOfReadingsPerPeriod.length - 3, 0))

            let total = 0

            lastThreePeriods.forEach(periodTotal => {
              total = total + periodTotal
            })

            let numberOfQualifiedPeriodsInTheLastThree = lastThreePeriods.filter(function(e) {
              return e >= 16
            }).length

            item.recentQualifications = numberOfQualifiedPeriodsInTheLastThree

            item.onFire = item.recentQualifications == lastThreePeriods.length

            let averageOfLastThreePeriods = total / lastThreePeriods.length

            if (averageOfLastThreePeriods >= 16) {
              item.highCompliance = true
              item.complianceStatement =
                item.firstName +
                ' has averaged ' +
                averageOfLastThreePeriods +
                ' days of readings per period recently. Active for ' +
                monitoringPeriods.length +
                ' period(s)'
            }

            if (averageOfLastThreePeriods <= 3) {
              item.lowCompliance = true
              item.complianceStatement =
                item.firstName +
                ' has averaged ' +
                averageOfLastThreePeriods +
                ' days of readings per period recently. Active for ' +
                monitoringPeriods.length +
                ' period(s)'
            }

            //compliance is the

            // what if periods were 30 days from the start of the month?
            // what about feb?

            // a high priority patient has NOT taken a reading today.
            // a high priority patient has fewer than 20 days left in the period.
            // a high priority patient has more days left in the period than he or she needs
            // a patient is higher priority if they have fewer days left in the period.

            if (!item.tookReadingToday && item.daysUntilPeriodEnd < 20 && item.daysThisPeriodWithReadings < 16) {
              // now make sure the patient CAN get the days needed
              if (16 - item.daysThisPeriodWithReadings < item.daysUntilPeriodEnd) {
                let days = ' more days '
                if (16 - item.daysThisPeriodWithReadings === 1) {
                  days = ' more day '
                }

                let Difference_In_Time_Last_Reading = item.mostRecentDataTimestamp - new Date().getTime()

                // To calculate the no. of days between two dates
                item.daysSinceLastReading = Difference_In_Time_Last_Reading / (1000 * 3600 * 24)

                item.priorityStatement =
                  item.firstName +
                  ' needs ' +
                  (16 - item.daysThisPeriodWithReadings) +
                  days +
                  'of data by ' +
                  (new Date(item.currentRPMPeriodEnd).getMonth() + 1) +
                  '/' +
                  new Date(item.currentRPMPeriodEnd).getDate() +
                  ' to qualify. Last reading was ' +
                  relativeTime(item.mostRecentDataTimestamp)
                item.highPriority = true
                item.daysProgress = (item.daysThisPeriodWithReadings / 16) * 100
              }
            }
          }

          // set the alert statement

          if (item.rpmUnseenAlertsArray) {
            item.thresholdBreachAlertNotifications = []
            item.rpmUnseenAlertsArray.forEach(alert => {
              item.unseenAlert = true
              item.alertMetric = alert
              let measurementName = consts.metricList[alert].commonName
              let operator = 'above'

              dispatch('prepareEventData', item.latestEvents[alert]).then(processedEvent => {
                if (!item.latestEvents[alert].alertMsg.includes('above')) {
                  operator = 'below'
                }
                let noteFragment =
                  item.firstName +
                  "'s" +
                  ' most recent ' +
                  measurementName +
                  ' reading is ' +
                  operator +
                  ' the threshold. '
                item.thresholdBreachAlertNotifications.push({
                  event: processedEvent,
                  note: noteFragment,
                })
              })
            })
          }

          // is this a new patient (added in the last 30 days)

          let thirtyDays = new Date()
          thirtyDays.setDate(thirtyDays.getDate() - 30)
          thirtyDays.setHours(0, 0, 0, 0)

          if (item.rpm && item.rpm.enrollment && item.rpm.enrollment.start) {
            let startDate = Date.parse(item.rpm.enrollment.start)
            if (startDate > thirtyDays.getTime()) {
              item.newPatient = true
            }
            // get enrollment age of patient

            let msSinceEnrollment = new Date().getTime() - startDate

            // To calculate the no. of days between two dates
            item.enrollmentAge = msSinceEnrollment / (1000 * 3600 * 24)
          }

          if (get(item, 'address.zip', null)) {
            item.zip = item.address.zip.substring(0, 5)
            let address =
              get(item, 'address.line1', '') +
              ' ' +
              get(item, 'address.line2', '') +
              ' ' +
              get(item, 'address.city', '') +
              ' ' +
              get(item, 'address.state', '') +
              ' ' +
              get(item, 'address.zip', '')
            item.fullAddress = address
          } else {
            item.zip = 'None'
          }
          // determine if ther eis an active conversation

          let threeHours = 3600000 * 3
          if (get(item, 'tele.messaging.lastMsg.created', null)) {
            let date = new Date(item.tele.messaging.lastMsg.created).getTime()
            item.lastMessageTimestamp = new Date(item.tele.messaging.lastMsg.created).getTime()
            if (new Date().getTime() - date < threeHours) {
              item.activeConversation = true
            }
          }

          if (item.birthdate) {
            // change birthdate into display birthdate
            item.displayBirthdate =
              item.birthdate.substring(5, 7) +
              '/' +
              item.birthdate.substring(8, 10) +
              '/' +
              item.birthdate.substring(0, 4)

            let constructedDateTimestamp = new Date(
              item.birthdate.substring(0, 4),
              item.birthdate.substring(5, 7) - 1,
              item.birthdate.substring(8, 10),
              0,
              0,
              0,
              0
            ).getTime()

            let ageDifMs = Date.now() - constructedDateTimestamp
            let ageDate = new Date(ageDifMs) // miliseconds from epoch
            item.age = Math.abs(ageDate.getUTCFullYear() - 1970)
          }

          // some patients have spaces at the end of their name. this will clean that
          if (item.lastName) {
            item.lastName = item.lastName.trim()
          }
          // create the short gender for the subtitle on the patient row
          if (item.gender) {
            item.shortGender = item.gender.charAt(0)
          }

          item.conditionsForMatching = []

          item.conditions.forEach(condition => {
            item.conditionsForMatching.push(condition.split('|')[0])
          })
          dispatch('categorizePatientByActivity', item)
        })

        if (data.data.pageInfo.total > preparedPatients.length + unenrolled.length) {
          offset = offset + limit
          getPatients(offset, limit, preparedPatients, unenrolled)
          commit(
            'setPatientLoadingProgress',
            ((preparedPatients.length + unenrolled.length) / data.data.pageInfo.total) * 100
          )
          if (rootState.populationview.enrolledPatients.length === 0) {
            commit('setEnrolledPatients', preparedPatients)
          }
        } else {
          commit('setUnenrolledPatients', preparedUnenrolledPatients)
          //commit('setTodayTasks', {newThresholdBreaches: unseenBreachesArray, recentIdlePatients: [], total: unseenBreachesArray.length})
          commit('setEnrolledPatientsDictionary', enrolledPatientsDictionary)

          commit('setEnrolledPatients', preparedPatients)
          commit('taskboard/setEnrolledPatients', preparedPatients, { root: true })

          commit('setRefreshingTaskboard', false)

          commit('setPatientLoadingProgress', 100)
        }
      })
    }
    if (new Date().getTime() > rootState.populationview.taskboardFreshness) {
      // only refresh the dashboard if its been more than 15 seconds since the last refresh
      commit('setRefreshingTaskboard', true)
      // prevent another call to refresh the dashboard if it has been les than 30 seconds
      commit('setTaskboardFreshness', new Date().getTime() + 30000)
      commit('setPatientLoadingProgress', 0)
      return getPatients(offset, limit, preparedPatients, preparedUnenrolledPatients)
    }
  },

  categorizePatientByActivity(_, item) {
    let thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)
    thirtyDaysAgo.setHours(0, 0, 0, 0)

    let startOfToday = new Date()
    startOfToday.setHours(0, 0, 0, 0)

    // unenrollCandidate
    // if the patient has been enrolled for more than 30 days, and the last reading was more than 30 days ago (or never) they should be pulled

    if (get(item, 'rpm.enrollment.enrolled', true)) {
      let enrollmentDate = Date.parse(item.rpm.enrollment.start)

      if (enrollmentDate < thirtyDaysAgo.getTime()) {
        // this patient has been enrolled for more than a month...
        if (item.rpmMostRecentData) {
          if (Date.parse(item.rpmMostRecentData) < thirtyDaysAgo.getTime()) {
            item.unenrollCandidate = true
          }
        } else {
          item.unenrollCandidate = true
        }
      }
      // so now deal with the patients that have taken a reading
    }

    item.daysUntilPeriodEndNotCountingToday = Math.floor(item.daysUntilPeriodEnd)

    item.daysUntilPeriodEndCountingToday = Math.ceil(item.daysUntilPeriodEnd)

    // if (item.tookReadingToday) {
    //   item.daysLeftToUse = Math.floor(item.daysUntilPeriodEnd)
    // } else {
    // }

    item.daysLeftToUse = Math.ceil(item.daysUntilPeriodEnd)

    if (item.daysLeftToUse < 22.5 && item.daysThisPeriodWithReadings < 16) {
      item.possibleOffTrack = true

      let daysElapsed = 30 - item.daysLeftToUse

      item.risk = daysElapsed / item.daysThisPeriodWithReadings

      item.shouldBeAt = Math.ceil((30 - item.daysLeftToUse) / 2)

      item.severity = Math.ceil(((item.shouldBeAt - item.daysThisPeriodWithReadings) / item.shouldBeAt) * 100)
      if (Math.floor(item.daysLeftToUse < 16 - item.daysThisPeriodWithReadings)) {
        item.hopeless = true
        item.severity = 101
      }

      // days you can miss of the days remaining and still make it

      if (item.shouldBeAt <= item.daysThisPeriodWithReadings) {
        item.possibleOffTrack = false
      }
    }

    item.daysUntilPeriodEndNotCountingToday = Math.floor(item.daysUntilPeriodEnd)

    item.daysYouCanMissOfTheDaysLeft = item.daysLeftToUse - (16 - item.daysThisPeriodWithReadings)

    item.howLazyCanYouBe = Math.floor((item.daysYouCanMissOfTheDaysLeft / item.daysLeftToUse) * 100)

    item.perfectionNeeded = 100 - item.howLazyCanYouBe
  },
  dismissInactivityLocal({ dispatch, state }, patientID) {
    // go find the patient. set unread inactivity to false
    // then redo the unready inactivity count

    const found = state.enrolledPatients.find(patient => patient._id === patientID)
    if (found) {
      found.unreadInactivity = false
      dispatch('processTheCounts')
    }
  },
  removeTagLocal({ dispatch, state }, { patientID, tag }) {
    // go find the patient. set unread inactivity to false
    // then redo the unready inactivity count

    const found = state.enrolledPatients.find(patient => patient._id === patientID)
    if (found) {
      let index = found.tags.indexOf(tag)
      if (index > -1) {
        found.tags.splice(index, 1)
      }
      dispatch('processTheCounts')
    }
  },
  addTagLocal({ dispatch, state }, { patientID, tag }) {
    // go find the patient. set unread inactivity to false
    // then redo the unready inactivity count

    const found = state.enrolledPatients.find(patient => patient._id === patientID)
    if (found) {
      if (found.tags) {
        found.tags.push(tag)
      } else {
        found.tags = [tag]
      }
      dispatch('processTheCounts')
    }
  },
  dismissUnreadMessageLocal({ dispatch, state }, patientID) {
    // go find the patient. set unread messages to false
    // then redo the unready inactivity count

    const found = state.enrolledPatients.find(patient => patient._id === patientID)
    if (found) {
      found.unreadMessage = false
      dispatch('processTheCounts')
    }
  },
  removeUnseenAlertFromPatient({ dispatch, state }, { patientID, event }) {
    let url = '/Patients/' + patientID + '/unseenAlerts?eventType=' + event
    return Vue.axios.delete(url).then(data => {
      // refresh the dashboard and the today view

      const found = state.enrolledPatients.find(patient => patient._id === patientID)
      if (found) {
        let index = found.rpmUnseenAlertsArray.indexOf(event)
        if (index > -1) {
          found.rpmUnseenAlertsArray.splice(index, 1)
          if (found.rpmUnseenAlertsArray.length === 0) {
            found.rpmUnseenAlerts = false
          }
        }
        dispatch('processTheCounts')
      }

      return data
    })
  },
  updateFilterOptions({ commit, dispatch, state }) {
    // this method runs on the enrolled patients and processes the counts at the top of the taskboard.
    // this should be run when the enrolled are updated and when an alert is removed
    // or when a tag is added or removed

    // item.conditionFilter = {}
    // item.conditions.forEach(condition => {
    //   let clean = condition.split('|')[0].replace(/./g, "_")
    //   item.conditionFilter[clean] = true
    // })
    // item.tagFilter = {}
    // item.tags.forEach(tag => {
    //   let clean = tag.replace(/./g, "_").replace(/./g, "_")
    //   item.tagFilter[clean] = true
    // })

    let patientTags = []
    let patientConditions = []
    let patientZips = []
    let patientMetricTypes = []
    let patientDevices = []

    state.enrolledPatients.forEach(processedItem => {
      if (processedItem.tags.length > 0) {
        processedItem.tags.forEach(tag => {
          let tagObject = { text: tag, count: 1 }
          let existing = patientTags.find(tagRes => tagRes.text === tagObject.text)

          if (!existing) {
            patientTags.push(tagObject)
          } else {
            existing.count = existing.count + 1
          }
        })
      }

      if (processedItem.devicesInUse.length > 0) {
        processedItem.devicesInUse.forEach(device => {
          let deviceObject = { text: device, count: 1 }
          let existing = patientDevices.find(deviceRes => deviceRes.text === deviceObject.text)

          if (!existing) {
            patientDevices.push(deviceObject)
          } else {
            existing.count = existing.count + 1
          }
        })
      }

      if (processedItem.zip) {
        let zipObject = {
          text: processedItem.zip,
          count: 1,
          ex: processedItem.fullAddress,
        }
        let existing = patientZips.find(zip => zip.text === zipObject.text)

        if (!existing) {
          patientZips.push(zipObject)
        } else {
          existing.count = existing.count + 1
        }
      }

      if (processedItem.conditions.length > 0) {
        processedItem.conditions.forEach(condition => {
          let condArray = condition.split('|')
          let conditionObject = {
            icd10: condArray[0],
            description: condArray[1],
            text: condArray[0],
            count: 1,
          }
          let existing = patientConditions.find(condition => condition.icd10 === conditionObject.icd10)

          if (!existing) {
            patientConditions.push(conditionObject)
          } else {
            existing.count = existing.count + 1
          }
        })
      }

      if (processedItem.latestEvents) {
        let metrics = Object.keys(processedItem.latestEvents)

        metrics.forEach(metric => {
          let typeObject = { type: metric, count: 1 }
          let existing = patientMetricTypes.find(type => type.type === typeObject.type)

          if (!existing && consts.metricList[metric] && consts.primaryMetric) {
            patientMetricTypes.push(typeObject)
          } else if (existing) {
            existing.count = existing.count + 1
          }
        })
      }

      dispatch('setAlertNotificationsArray', processedItem)
    })

    commit('setPatientTags', patientTags)
    commit(
      'setPatientConditions',
      patientConditions.sort((a, b) => b.count - a.count)
    )
    commit(
      'setPatientZips',
      patientZips.sort((a, b) => b.text.toString() - a.text.toString())
    )
    commit(
      'setPatientMetricTypes',
      patientMetricTypes.sort((a, b) => b.count - a.count)
    )
    commit(
      'setPatientDevices',
      patientDevices.sort((a, b) => b.count - a.count)
    )
  },

  processTheCounts({ commit, dispatch, state }) {
    // this method runs on the enrolled patients and processes the counts at the top of the taskboard.
    // this should be run when the enrolled are updated and when an alert is removed
    // or when a tag is added or removed

    let patientTags = []
    let patientsWithUnseenBreaches = 0
    let patientsWithZeroDaysOfInactivity = 0
    let patientsWithOneDayOfInactivity = 0
    let patientsWithTwoDaysOfInactivity = 0
    let patientsWithThreeDaysOfInactivity = 0
    let patientsWithFourDaysOfInactivity = 0
    let patientsWithFiveDaysOfInactivity = 0
    let patientsWithSixDaysOfInactivity = 0
    let patientsWithSevenPlusDaysOfInactivity = 0
    let upperInactivityBucket = 0
    let lowerInactivityBucket = 0
    let patientsWithNoData = 0
    let patientUnreadCount = 0

    state.enrolledPatients.forEach(processedItem => {
      if (processedItem.rpmMostRecentData) {
        switch (processedItem.daysOfInactivity) {
          case 0:
            patientsWithZeroDaysOfInactivity = patientsWithZeroDaysOfInactivity + 1
            break
          case 1:
            if (processedItem.unreadInactivity) {
              patientsWithOneDayOfInactivity = patientsWithOneDayOfInactivity + 1
            }
            break
          case 2:
            if (processedItem.unreadInactivity) {
              patientsWithTwoDaysOfInactivity = patientsWithTwoDaysOfInactivity + 1
            }
            break
          case 3:
            if (processedItem.unreadInactivity) {
              patientsWithThreeDaysOfInactivity = patientsWithThreeDaysOfInactivity + 1
            }
            processedItem.lowerInactivityThreshold = true
            processedItem.outreach = true
            break
          case 4:
            if (processedItem.unreadInactivity) {
              patientsWithFourDaysOfInactivity = patientsWithFourDaysOfInactivity + 1
            }
            processedItem.lowerInactivityThreshold = true
            processedItem.outreach = true
            break
          case 5:
            if (processedItem.unreadInactivity) {
              patientsWithFiveDaysOfInactivity = patientsWithFiveDaysOfInactivity + 1
            }
            processedItem.upperInactivityThreshold = true
            processedItem.atRisk = true
            break
          case 6:
            if (processedItem.unreadInactivity) {
              patientsWithSixDaysOfInactivity = patientsWithSixDaysOfInactivity + 1
            }
            processedItem.upperInactivityThreshold = true
            processedItem.atRisk = true
            break
          default:
            if (processedItem.unreadInactivity) {
              patientsWithSevenPlusDaysOfInactivity = patientsWithSevenPlusDaysOfInactivity + 1
            }
            processedItem.upperInactivityThreshold = true
            processedItem.atRisk = true
            break
        }

        // set the upper inactivity bucket to anybody greater than 5 days
        lowerInactivityBucket = patientsWithThreeDaysOfInactivity + patientsWithFourDaysOfInactivity
        upperInactivityBucket =
          patientsWithFiveDaysOfInactivity + patientsWithSevenPlusDaysOfInactivity + patientsWithSixDaysOfInactivity
      } else {
        patientsWithNoData = patientsWithNoData + 1
        processedItem.onboarding = true
      }

      // handle the unseen breaches

      if (processedItem.rpmUnseenAlerts) {
        patientsWithUnseenBreaches = patientsWithUnseenBreaches + 1
        // item.thresholdBreachAlertNotifications.forEach( notification =>{
        //   notification.patient = item
        //   notification.taskNote = notification.note
        //   unseenBreachesArray.push(notification)
        // })
      }

      if (processedItem.unreadMessage) {
        patientUnreadCount = patientUnreadCount + 1
      }

      if (processedItem.tags.length > 0) {
        processedItem.tags.forEach(tag => {
          if (!patientTags.includes(tag)) {
            patientTags.push(tag)
          }
        })
      }

      dispatch('setAlertNotificationsArray', processedItem)
    })

    commit('setPatientTags', patientTags)
    // set the full breakdown
    commit('setUnreadThresholdAlerts', patientsWithUnseenBreaches)

    commit('setZeroDaysInactivity', patientsWithZeroDaysOfInactivity)
    commit('setOneDayInactivity', patientsWithOneDayOfInactivity)
    commit('setTwoDaysInactivity', patientsWithTwoDaysOfInactivity)
    commit('setThreeDaysInactivity', patientsWithThreeDaysOfInactivity)
    commit('setFourDaysInactivity', patientsWithFourDaysOfInactivity)
    commit('setFiveDaysInactivity', patientsWithFiveDaysOfInactivity)
    commit('setSixDaysInactivity', patientsWithSixDaysOfInactivity)
    commit('setSevenPlusDaysInactivity', patientsWithSevenPlusDaysOfInactivity)
    commit('setLowerInactivityBucket', lowerInactivityBucket)
    commit('setUpperInactivityBucket', upperInactivityBucket)
    commit('setNoData', patientsWithNoData)
    commit('setUnreadMessages', patientUnreadCount)
    // go update the taskboard key to make it refresh?
    commit('updateTaskboardKey')
  },
  clearTaskboard({ commit }) {
    commit('setUnreadThresholdAlerts', 0)
    commit('setZeroDaysInactivity', 0)
    commit('setOneDayInactivity', 0)
    commit('setTwoDaysInactivity', 0)
    commit('setThreeDaysInactivity', 0)
    commit('setFourDaysInactivity', 0)
    commit('setFiveDaysInactivity', 0)
    commit('setSixDaysInactivity', 0)
    commit('setSevenPlusDaysInactivity', 0)
    commit('setLowerInactivityBucket', 0)
    commit('setUpperInactivityBucket', 0)
    commit('setEnrolledPatientsDictionary', {})
    commit('setEnrolledPatients', [])
    commit('setUnenrolledPatients', [])
    commit('setUserPatients', [])
    commit('setNoData', 0)
    commit('setTaskboardFreshness', 0)
    commit('setStoredSelectedPatients', [])
    commit('setPatientTags', [])
    commit('setPatientConditions', [])
    commit('setPatientZips', [])
    commit('setPatientMetricTypes', [])
    commit('setPatientDevices', [])
  },
  standardizedPatientProcessing({ commit, rootState, dispatch }, item) {
    /// a few functions that will help in processing the patient data
    function relativeTime(value) {
      if (value) {
        let dt = moment(value)
          .tz(moment.tz.guess())
          .format('MM/DD/YYYY h:mm a')
        if (dt.includes('12:00 am')) {
          return 'Today'
        } else {
          return moment(value)
            .tz(moment.tz.guess())
            .fromNow()
        }
      }
      return '-'
    }

    function msToTime(s) {
      if (!s) {
        return '00:00:00'
      }
      let seconds = Math.floor((s / 1000) % 60)
      let minutes = Math.floor((s / (1000 * 60)) % 60)
      let hours = Math.floor(s / (1000 * 60 * 60))

      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds

      return hours + ':' + minutes + ':' + seconds
    }

    function getShortDateFromTimestamp(timestamp) {
      let d = new Date(timestamp)

      let hours = d.getHours()
      let minutes = d.getMinutes()
      let ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      hours = hours ? hours : 12 // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes
      let strTime = hours + ':' + minutes + ' ' + ampm
      return (
        ('0' + (d.getMonth() + 1)).slice(-2) +
        '/' +
        ('0' + d.getDate()).slice(-2) +
        '/' +
        d.getFullYear() +
        ' ' +
        strTime
      )
    }

    // this is the only processing done on the patient object. that way there is a standard patient object that is created
    // this should probably live in the patient module
    commit('extraCommit')
    if (item.rpmMostRecentData) {
      // this patient has data, not tag it with how long it has been since the reading
      item.mostRecentDataTimestamp = new Date(item.rpmMostRecentData).getTime()

      let endOfTheDayOfLastReading = new Date(item.rpmMostRecentData)
      endOfTheDayOfLastReading.setHours(23, 59, 59, 999)
      let startOfTheDayToday = new Date()
      startOfTheDayToday.setHours(0, 0, 0, 0)

      let secondsSinceLastReading = startOfTheDayToday.getTime() / 1000 - endOfTheDayOfLastReading.getTime() / 1000
      let days = Math.floor(secondsSinceLastReading / (3600 * 24))
      item.daysOfInactivity = days
    } else {
      // this patient needs to finish onboarding
      item.noData = true
    }

    // Things we need for the dashboard
    // First (trimmed)
    // last (trimmed)
    // age (calculated)
    // gender
    //shortGender (just the first initial)
    // patient ID (for the organization)
    // smsPhone
    // latest blood pressure (calculated)
    // latest weight (calculated)
    // latest glucose (calculated)
    // latest heat rate (calculated)
    // QHP time this period
    // alert status
    // alert unread
    // enrolled timestamp
    // rpmEnrollmentStartDisplay
    //millisecondsThisPeriodDisplay
    //milisecondsThisPeriod

    item.readableStartDate = getShortDateFromTimestamp(item.rpm.enrollment.start)

    item.firstData = null

    if (item.rpm.enrollment.firstData) {
      // this tells me when the next 99454 is up
      item.firstData = new Date(item.rpm.enrollment.firstData).getTime()
      item.ranges = []
      // get the next ending period?
      // so there are 30 days (inclusive) in a period
      let now = new Date()
      let start = new Date(item.firstData)
      start.setHours(0, 0, 0, 0)
      now.setHours(23, 59, 59, 999)

      let startStamp = start.getTime()
      let endStamp = now.getTime()

      while (startStamp < endStamp) {
        item.ranges.push({
          start: getShortDateFromTimestamp(startStamp),
          end: getShortDateFromTimestamp(startStamp + 86400000 * 30),
        })
        startStamp = startStamp + 86400000 * 30
      }

      item.endOfThisPeriod = startStamp
      item.startOfThisPeriod = startStamp - 86400000 * 30

      /// get the actial periods

      let periodStart = new Date(item.firstData)
      periodStart.setHours(0, 0, 0, 0)

      let rightNow = new Date()

      item.monitoringPeriods = []

      while (periodStart.getTime() < rightNow.getTime()) {
        // this will add thirty days to the period start. when this ends, period start will be the start of NEXT period.
        let start = periodStart.getTime()
        periodStart.setDate(periodStart.getDate() + 30)
        let end = periodStart.getTime() - 1
        item.monitoringPeriods.push({ start: start, end: end })
      }

      item.startOfThisPeriod = item.monitoringPeriods[item.monitoringPeriods.length - 1].start
      item.endOfThisPeriod = item.monitoringPeriods[item.monitoringPeriods.length - 1].end

      // what if periods were 30 days from the start of the month?
      // what about feb?
    }

    // some patients have spaces at the end of their name. this will clean that
    if (item.lastName) {
      item.lastName = item.lastName.trim()
    }
    // create the short gender for the subtitle on the patient row
    if (item.gender) {
      item.shortGender = item.gender.charAt(0)
    }
    // set the display birthdate as well as the age for the subtitle of the patient row
    if (item.birthdate) {
      // change birthdate into display birthdate
      item.displayBirthdate =
        item.birthdate.substring(5, 7) + '/' + item.birthdate.substring(8, 10) + '/' + item.birthdate.substring(0, 4)

      let constructedDateTimestamp = new Date(
        item.birthdate.substring(0, 4),
        item.birthdate.substring(5, 7) - 1,
        item.birthdate.substring(8, 10),
        0,
        0,
        0,
        0
      ).getTime()

      let ageDifMs = Date.now() - constructedDateTimestamp
      let ageDate = new Date(ageDifMs) // miliseconds from epoch
      item.age = Math.abs(ageDate.getUTCFullYear() - 1970)
    }

    // make it easier to search for the patient
    item.fullName = item.firstName + ' ' + item.lastName.toUpperCase()
    item.nameForSearching = item.firstName.toLowerCase() + ' ' + item.lastName.toLowerCase()

    // prepare the latest metric data points
    // let events = Object.keys(item.latestEvents)
    // events.forEach(event => {
    //   // do some basic processinf
    //   let eventDict = {}
    //     if (consts.metricList[event]) {
    //       eventDict = clone(consts.metricList[event])
    //     }

    //     //item.hr
    //     //item.hrAlert

    // })

    // set the bare minimum for the dashboard

    item.hr = '-'
    if (item.latestEvents && item.latestEvents.MEASUREMENT_PULSE && item.latestEvents.MEASUREMENT_PULSE.eventData) {
      item.hr = item.latestEvents.MEASUREMENT_PULSE.eventData.value
      if (item.latestEvents.MEASUREMENT_PULSE.alert) {
        item.hrAlert = true
        item.alert = true
      }
      item.hrDate = getShortDateFromTimestamp(item.latestEvents.MEASUREMENT_PULSE.timestamp)
      item.hrDateDisplay = relativeTime(item.latestEvents.MEASUREMENT_PULSE.timestamp)
    }

    item.bp = '-'
    if (
      item.latestEvents &&
      item.latestEvents.MEASUREMENT_BLOOD_PRESSURE &&
      item.latestEvents.MEASUREMENT_BLOOD_PRESSURE.eventData
    ) {
      item.bp =
        item.latestEvents.MEASUREMENT_BLOOD_PRESSURE.eventData.systolicValue +
        '/' +
        item.latestEvents.MEASUREMENT_BLOOD_PRESSURE.eventData.diastolicValue
      if (item.latestEvents.MEASUREMENT_BLOOD_PRESSURE.alert) {
        item.bpAlert = true
        item.alert = true
      }
      item.bpDate = getShortDateFromTimestamp(item.latestEvents.MEASUREMENT_BLOOD_PRESSURE.timestamp)
      item.bpDateDisplay = relativeTime(item.latestEvents.MEASUREMENT_BLOOD_PRESSURE.timestamp)
    }

    item.glu = '-'
    if (
      item.latestEvents &&
      item.latestEvents.MEASUREMENT_BLOOD_GLUCOSE &&
      item.latestEvents.MEASUREMENT_BLOOD_GLUCOSE.eventData
    ) {
      item.glu = item.latestEvents.MEASUREMENT_BLOOD_GLUCOSE.eventData.value
      if (item.latestEvents.MEASUREMENT_BLOOD_GLUCOSE.alert) {
        item.gluAlert = true
        item.alert = true
      }
      item.gluDate = getShortDateFromTimestamp(item.latestEvents.MEASUREMENT_BLOOD_GLUCOSE.timestamp)
      item.gluDateDisplay = relativeTime(item.latestEvents.MEASUREMENT_BLOOD_GLUCOSE.timestamp)
    }

    item.wt = '-'
    if (
      item.latestEvents &&
      item.latestEvents.MEASUREMENT_BODY_WEIGHT &&
      item.latestEvents.MEASUREMENT_BODY_WEIGHT.eventData
    ) {
      item.wt = Math.round(item.latestEvents.MEASUREMENT_BODY_WEIGHT.eventData.value * 2.205 * 10) / 10
      if (item.latestEvents.MEASUREMENT_BODY_WEIGHT.alert) {
        item.wtAlert = true
        item.alert = true
      }
      item.wtDate = getShortDateFromTimestamp(item.latestEvents.MEASUREMENT_BODY_WEIGHT.timestamp)
      item.wtDateDisplay = relativeTime(item.latestEvents.MEASUREMENT_BODY_WEIGHT.timestamp)
    }
    //// end the dashboard events

    // get QHP time to display
    if (item.qhpTimeThisPeriod) {
      let today = new Date()
      let monthToUse = today.getMonth() + 1
      let yearToUse = today.getFullYear()
      if (
        item.qhpTimeThisPeriod.start &&
        item.qhpTimeThisPeriod.start.includes(
          yearToUse.toString() + '-' + ('0' + monthToUse.toString()).slice(-2) + '-01'
        )
      ) {
        item.millisecondsThisPeriod = item.qhpTimeThisPeriod.millisecondsThisPeriod
      } else {
        item.millisecondsThisPeriod = 0
      }
    }
    // get display values

    item.rpmMostRecentDataDisplay = relativeTime(item.rpmMostRecentData)
    item.millisecondsThisPeriodDisplay = msToTime(item.millisecondsThisPeriod)
    item.rpmEnrollmentStartDisplay = relativeTime(item.rpm.enrollment.start)

    if (rootState.taskboard.dismissedAlertsDictionary[item._id]) {
      let referenceDate = new Date()
      referenceDate.setHours(0, 0, 0, 0)
      if (rootState.taskboard.dismissedAlertsDictionary[item._id] >= referenceDate.getTime()) {
        item.unreadInactivity = false
      } else {
        //add some
        item.unreadInactivity = true
      }
    } else {
      item.unreadInactivity = true
    }

    if (item.address) {
      let address = ''
      let addressLine = item.address.line1

      if (item.address.line2) {
        addressLine = addressLine + ' ' + item.address.line2
      }

      if (item.address.city) {
        addressLine = addressLine + ' ' + item.address.city
      }

      if (item.address.state) {
        addressLine = addressLine + ' ' + item.address.state
      }

      if (item.address.zip) {
        addressLine = addressLine + ' ' + item.address.zip
      }
      address = addressLine
      item.displayAddress = address
    }
    item.patientMetricList = Object.keys(item.latestEvents)

    // alerts should be set on the patient object for anything over three days of missed readings?
    // and threshold breaches of course
    // this logic should also exist in the
    // dispatch('metrics/gradePatientOnEngagement', item, {root: true})
    dispatch('setAlertNotificationsArray', item)
    dispatch('setUserPermissionsOnPatient', item)

    // handle legacy omnichart

    return item
  },
  setAlertNotificationsArray({ dispatch }, item) {
    // this should run through the situations that there should be a chart alert (something that pops up when you enter the chart)
    // unread messages, threshold breaches, days on inactivity, times you are mentioned in the notes but you havent seen it yet?

    item.thresholdBreachAlertNotifications = []

    if (item.rpmUnseenAlerts) {
      /// TODO revisit how we are creating these alerts to allow them to be dismissable

      item.rpmUnseenAlertsArray.forEach(alert => {
        let measurementName = consts.metricList[alert].commonName
        let operator = 'above'

        dispatch('prepareEventData', item.latestEvents[alert]).then(processedEvent => {
          if (!item.latestEvents[alert].alertMsg.includes('above')) {
            operator = 'below'
          }
          let noteFragment =
            item.firstName + "'s" + ' most recent ' + measurementName + ' reading is ' + operator + ' the threshold. '
          item.thresholdBreachAlertNotifications.push({
            event: processedEvent,
            note: noteFragment,
          })
        })
      })
    }

    item.inactivityAlertNotifications = []
    if (item.daysOfInactivity > 2 && item.unreadInactivity) {
      let noteFragment = item.firstName + " hasn't taken a measurement in more than " + item.daysOfInactivity + ' days'
      item.inactivityAlertNotifications.push({
        icon: 'mdi-close',
        note: noteFragment,
      })
    }
  },
  setUserPermissionsOnPatient({ commit, rootState }, item) {
    commit('extraCommit')
    if (rootState.auth.user) {
      item.userCanUpdate = true
      let user = rootState.auth.user
      item.admins.forEach(admin => {
        if (admin.id === user._id && admin.ref === 'Users') {
          item.userCanUpdate = true
          item.userAdmin = true
        }
        if (admin.id === user.partnerID && admin.ref === 'Partners') {
          item.userCanUpdate = true
          item.userAdmin = true
          item.userCanViewNotes = true
          if (user.partnerRoles && user.partnerRoles.qhp) {
            item.userCanTrackTime = true
          }
        }
      })
    }
  },
  prepareEventData({ commit, rootState }, event) {
    let eventDict = {}
    if (rootState && rootState.auth && consts.metricList[event.eventType]) {
      eventDict = clone(consts.metricList[event.eventType])
    }
    commit('extraCommit', null)
    let objectToReturn = {}

    let keys = Object.keys(event)
    keys.forEach(key => {
      objectToReturn[key] = event[key]
    })

    objectToReturn.metric = event.eventType
    objectToReturn.name = event.eventType
    objectToReturn.displayValue = '-'
    objectToReturn.timestamp = event.timestamp
    objectToReturn.source = event.peripheralName
    objectToReturn.dashboardAbbreviation = eventDict.dashboardAbbreviation
    // handle formatting for out of bound events
    if (event.alert) {
      objectToReturn.alert = true
    }
    if (event.alertMsg) {
      objectToReturn.alertMsg = event.alertMsg
    }

    // handle specific formatting for events
    if (event.eventType === 'MEASUREMENT_BLOOD_PRESSURE') {
      objectToReturn.systolic = 0
      objectToReturn.diastolic = 0
    }

    switch (event.eventType) {
      case 'MEASUREMENT_BLOOD_PRESSURE':
        objectToReturn.systolic = event.eventData.systolicValue
        objectToReturn.diastolic = event.eventData.diastolicValue
        objectToReturn.displayValue = event.eventData.systolicValue + '/' + event.eventData.diastolicValue
        break
      case 'MEASUREMENT_BODY_WEIGHT':
        objectToReturn.displayValue = Math.round(event.eventData.value * 2.205 * 10) / 10
        objectToReturn.sortValue = objectToReturn.displayValue
        break
      case 'MEASUREMENT_BLOOD_GLUCOSE':
        objectToReturn.displayValue = Math.round(event.eventData.value * 100) / 100
        break
      case 'MEASUREMENT_BODY_TEMPERATURE':
        objectToReturn.displayValue = Math.round(((event.eventData.value * (9 / 5) + 32) * 100) / 100)
        break
      case 'MEASUREMENT_PULSE':
        objectToReturn.displayValue = Math.round(event.eventData.value)
        break
      case 'MEASUREMENT_ECG':
        if (event.eventData.afib) {
          objectToReturn.displayValue = 'Possible Afib'
        } else {
          objectToReturn.displayValue = 'No Afib'
        }
        break
    }
    objectToReturn.eventString = objectToReturn.displayValue
    if (!Array.isArray(eventDict.units)) {
      objectToReturn.eventString += ' ' + eventDict.units
    }
    let d = new Date(event.timestamp)
    let hours = d.getHours()
    let minutes = d.getMinutes()
    let ampm = hours >= 12 ? 'pm' : 'am'
    hours = hours % 12
    hours = hours ? hours : 12 // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes
    let strTime = hours + ':' + minutes + ' ' + ampm
    objectToReturn.readableDate =
      ('0' + (d.getMonth() + 1)).slice(-2) + '/' + ('0' + d.getDate()).slice(-2) + '/' + d.getFullYear() + ' ' + strTime
    return objectToReturn
  },
  createDashboardCSV({ commit }, payload) {
    commit('extraCommit', false)
    function getShortDateFromTimestamp(timestamp) {
      if (timestamp == null || timestamp == 0) {
        return '-'
      }
      let d = new Date(timestamp)

      let hours = d.getHours()
      let minutes = d.getMinutes()
      let ampm = hours >= 12 ? 'pm' : 'am'
      hours = hours % 12
      hours = hours ? hours : 12 // the hour '0' should be '12'
      minutes = minutes < 10 ? '0' + minutes : minutes
      let strTime = hours + ':' + minutes + ' ' + ampm
      return (
        ('0' + (d.getMonth() + 1)).slice(-2) +
        '/' +
        ('0' + d.getDate()).slice(-2) +
        '/' +
        d.getFullYear() +
        ' ' +
        strTime
      )
    }

    function msToTime(s) {
      if (!s) {
        return '00:00:00'
      }
      let seconds = Math.floor((s / 1000) % 60)
      let minutes = Math.floor((s / (1000 * 60)) % 60)
      let hours = Math.floor(s / (1000 * 60 * 60))

      hours = hours < 10 ? '0' + hours : hours
      minutes = minutes < 10 ? '0' + minutes : minutes
      seconds = seconds < 10 ? '0' + seconds : seconds

      return hours + ':' + minutes + ':' + seconds
    }

    // the first line is the titles
    // REPORT_TITLE: DATE
    // first, last, dob, start date, bp, hr, weight, glucose,
    let csv = ''
    csv = payload.title + ' ' + getShortDateFromTimestamp(new Date().getTime()) + '\n'
    csv += 'Patient ID,First Name,Last Name, Gender, DOB, Enrolled,'

    if (payload.metrics.includes('hr')) {
      csv += 'Latest HR,'
    }
    if (payload.metrics.includes('bp')) {
      csv += 'Latest BP,'
    }
    if (payload.metrics.includes('wt')) {
      csv += 'Latest WT,'
    }
    if (payload.metrics.includes('glu')) {
      csv += 'Latest GLU,'
    }

    csv += 'Last Active, Time This Period\n'

    payload.array.forEach(patient => {
      csv += patient.ownerPatientID + ','
      csv += patient.firstName + ','
      csv += patient.lastName + ','
      csv += patient.gender + ','
      csv += patient.displayBirthdate + ','
      csv += patient.readableStartDate + ','
      if (payload.metrics.includes('hr')) {
        csv += patient.hr + ','
      }
      if (payload.metrics.includes('bp')) {
        csv += patient.bp + ','
      }
      if (payload.metrics.includes('wt')) {
        csv += patient.wt + ','
      }
      if (payload.metrics.includes('glu')) {
        csv += patient.glu + ','
      }
      csv += getShortDateFromTimestamp(patient.rpmMostRecentData) + ','
      csv += msToTime(patient.millisecondsThisPeriod) + ','
      //csv += row.join(',');
      csv += '\n'
    })
    var hiddenElement = document.createElement('a')
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv)
    hiddenElement.target = '_blank'
    hiddenElement.download = payload.title + ' ' + getShortDateFromTimestamp(new Date().getTime()) + '_novorpm.csv'
    hiddenElement.click()
  },
  getUserPatients({ commit, rootState, dispatch }) {
    // set the page information into the parameters of the window so that on a refresh you go back to the same info
    commit('setRefreshingUserPatients', true)
    let limit = 500
    let offset = 0
    let url = 'Users/' + rootState.auth.user._id + '/Patients?limit=' + limit + '&offset=' + offset
    return Vue.axios
      .get(url)
      .then(data => {
        let preparedPatients = []
        data.data.items.forEach(patient => {
          dispatch('standardizedPatientProcessing', patient).then(normalizedPatient => {
            preparedPatients.push(normalizedPatient)
          })
        })
        commit('setRefreshingUserPatients', false)
        commit('setUserPatients', preparedPatients)
      })
      .catch(function(error) {
        console.log('Error getting patients: ', error)
        commit('setRefreshingUserPatients', false)
        commit('setUserPatients', [])
        return null
      })
  },
  dismissInactivity({ rootState, dispatch }, patientID) {
    // dismiss inactivity when a text is sent or a note is saved. do this automatically from a dispatch to keep it simple.

    let patientIDToUse = patientID

    if (patientIDToUse == null && rootState.patient.patient && rootState.patient.patient._id) {
      patientIDToUse = rootState.patient.patient && rootState.patient.patient._id
    }

    let lastNotesRef = firebase
      .firestore()
      .collection('inactivityDismissed')
      .doc(rootState.auth.user.partnerID)
    lastNotesRef.get().then(docSnapshot => {
      if (docSnapshot.exists) {
        if (patientIDToUse && rootState.auth.user.partnerID) {
          let db = firebase.firestore()
          let noteEntry = {}
          noteEntry[patientIDToUse] = new Date().getTime()
          return db
            .collection('inactivityDismissed')
            .doc(rootState.auth.user.partnerID)
            .update(noteEntry)
            .catch(function(error) {
              console.error('Error adding document: ', error)
            })
        }
      } else {
        if (patientIDToUse && rootState.auth.user.partnerID) {
          let db = firebase.firestore()
          let noteEntry = {}
          noteEntry[patientIDToUse] = new Date().getTime()
          return db
            .collection('inactivityDismissed')
            .doc(rootState.auth.user.partnerID)
            .set(noteEntry)
            .catch(function(error) {
              console.error('Error adding document: ', error)
            })
        }
      }
    })
    dispatch('dismissInactivityLocal', patientID)
  },
}
