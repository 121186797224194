<template>
  <v-dialog v-model="show" :max-width="options.maxWidth" persistent scrollable>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title style="font-weight:700;">
            {{cardTitle}}
            </v-toolbar-title>
          </v-toolbar>

        
        <!-- Content -->
        <v-card-text>
          <div style="text-align:justify;">
          {{cardText}}

          </div>
        </v-card-text>
        <!-- Actions -->
        <v-card-actions>
          <!-- Buttons -->
          <v-spacer></v-spacer>
          <v-btn depressed dark color="primary" @click="cancelOption()">Dismiss</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
</template>


<script>
import DialogMixin from '@/core/mixins/DialogMixin'

export default {
  name: 'OneConfirmDialog',
  mixins: [DialogMixin],
  components: {
  },
  data() {
    return {
      className: 'Confirm',
      cardTitle: 'PostAuthNotification',
      cardText: 'Post auth notification text explaining what just happened.',
      defaultOptions: {
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        showOk: true,
        showCancel: false
      }
    }
  },
  watch: {
    options(val) {
      this.cardTitle = val.cardTitle
      this.cardText = val.cardText
    }
  },
  methods: {
    cancelOption() {
      this.cancel()
    },
    uniqueContentForData(data) {
      if (data.invite) {
        if (data.invite.user_group) {
          this.cardText = 'Your account has been added to ' + data.invite.user_group + '. You now have full access to all charts associated with the group.'
        } else if (data.invite.patient) {
          this.cardText = 'You now have full access to ' + data.invite.patient + '. You may upload records, request records, or share the chart with others.'
        }
      }
    }
  },
  computed: {
    showBuilding() {
      return this.options.unit && this.options.building
    }
  }
}
</script>
