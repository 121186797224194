import Vue from 'vue'
import VueRouter from 'vue-router'
import guards from './guards'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "login" */ '../views/Login.vue'),
    meta: {
      hideHeader: true,
      centerVertical: true,
    },
  },
  {
    path: '/video/:accessToken',
    name: 'Video',
    component: () => import(/* webpackChunkName: "video" */ '../views/VideoRoomPage.vue'),
    meta: {
      hideHeader: true,
      borderless: true,
    },
  },
  {
    path: '/reported',
    name: 'PatientReported',
    component: () => import(/* webpackChunkName: "patient-reported" */ '../views/PatientReported.vue'),
    meta: {
      hideHeader: true,
      centerVertical: true,
    },
  },
  {
    path: '/calculator',
    name: 'RevenueCalculator',
    component: () => import(/* webpackChunkName: "revenue-calculator" */ '../views/RevenueCalculator.vue'),
    meta: {
      hideHeader: true,
    },
  },
  {
    path: '/policies',
    name: 'Policies',
    component: () => import(/* webpackChunkName: "policies" */ '../views/Policies.vue'),
    meta: {
      hideHeader: true,
      centerVertical: true,
    },
  },
  {
    path: '/',
    name: 'Default',
    component: () => import(/* webpackChunkName: "default" */ '../views/Login.vue'),
    meta: {
      hideHeader: true,
      centerVertical: true,
    },
  },
  {
    path: '*',
    name: 'DefaultPage',
    component: () => import(/* webpackChunkName: "default-page" */ '../views/Login.vue'),
    meta: {
      hideHeader: true,
      centerVertical: true,
    },
  },
  {
    path: '/password-reset',
    name: 'PasswordReset',
    component: () => import(/* webpackChunkName: "password-reset" */ '../views/PasswordReset.vue'),
    meta: {
      hideHeader: true,
      centerVertical: true,
    },
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "register" */ '../views/Register.vue'),
    meta: {
      hideHeader: true,
      centerVertical: true,
    },
  },
  {
    path: '/terms',
    name: 'Terms',
    component: () => import(/* webpackChunkName: "terms" */ '../views/Terms.vue'),
    meta: {
      hideHeader: true,
    },
  },
  {
    path: '/privacy',
    name: 'Privacy',
    component: () => import(/* webpackChunkName: "privacy" */ '../views/Privacy.vue'),
    meta: {
      hideHeader: true,
    },
  },
  {
    path: '/taskboard',
    name: 'Taskboard',
    component: () => import(/* webpackChunkName: "taskboard" */ '../views/PopulationView.vue'),
    meta: {
      hideHeader: false,
      customHeader: true,
      displayName: 'Taskboard',
      mobileDisplayName: 'Taskboard',
      requireUser: true,
      requireEnrolledPatients: true,
      borderless: true,
      appBarExtension: true,
    },
    ...guards,
  },
  {
    path: '/engagement',
    name: 'Engagement',
    component: () => import(/* webpackChunkName: "taskboard" */ '../views/PopulationEngagement.vue'),
    meta: {
      hideHeader: false,
      customHeader: true,
      displayName: 'Activity',
      mobileDisplayName: 'Activity',
      requireUser: true,
      requireEnrolledPatients: true,
      borderless: true,
      appBarExtension: false,
      expandedDrawer: true,
      hideOnlyHeader: false,
    },
    ...guards,
  },

  {
    path: '/devicemanagement',
    name: 'Device Management',
    component: () => import(/* webpackChunkName: "taskboard" */ '../views/DeviceManagement.vue'),
    meta: {
      hideHeader: false,
      customHeader: true,
      displayName: 'Device Management',
      mobileDisplayName: 'Device Management',
      requireUser: true,
      requireEnrolledPatients: true,
      borderless: true,
      appBarExtension: false,
      expandedDrawer: true,
      hideOnlyHeader: false,
    },
    ...guards,
  },
  {
    path: '/economics',
    name: 'Economics',
    component: () => import(/* webpackChunkName: "economics" */ '../views/Economics.vue'),
    meta: {
      hideHeader: false,
      customHeader: false,
      displayName: 'Who you should focus on today',
      mobileDisplayName: 'Economics',
      requireUser: true,
      requireEnrolledPatients: true,
      borderless: true,
    },
    ...guards,
  },
  {
    path: '/view',
    name: 'PatientFacingView',
    component: () => import(/* webpackChunkName: "patient-view" */ '../views/PatientFacingView.vue'),
    meta: {
      hideHeader: true,
      customHeader: true,
      displayName: 'View',
      mobileDisplayName: 'View',
      requireUser: true,
      borderless: true,
    },
    ...guards,
  },
  {
    path: '/conversations',
    name: 'Conversations',
    component: () => import(/* webpackChunkName: "convos" */ '../views/Conversations.vue'),
    meta: {
      hideHeader: false,
      customHeader: true,
      displayName: 'Conversations',
      mobileDisplayName: 'Conversations',
      requireUser: true,
      requireEnrolledPatients: true,
      borderless: true,
    },
    ...guards,
  },
  {
    path: '/demoprep',
    name: 'Demoprep',
    // component: Demoprep,
    component: () => import(/* webpackChunkName: "demo-prep" */ '../views/Demoprep.vue'),
    meta: {
      hideHeader: false,
      customHeader: true,
      displayName: 'Demo Prep',
      mobileDisplayName: 'Demo Prep',
      requireUser: true,
      borderless: true,
    },
    ...guards,
  },
  {
    path: '/patients/:id',
    name: 'Patient',
    component: () => import(/* webpackChunkName: "patient" */ '../views/Patient.vue'),
    meta: {
      hideHeader: false,
      showPatientBar: true,
      displayName: 'Chart',
      mobileDisplayName: 'Chart',
      hideFooter: true,
      requireUser: true,
      requirePatient: true,
    },
    children: [
      {
        path: 'patientinfo',
        name: 'PatientInfo',
        component: () => import(/* webpackChunkName: "patient-info" */ '../views/PatientInfo.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Information',
          mobileDisplayName: 'Info',
          hideFooter: true,
          requireUser: true,
          requirePatient: true,
          expandedDrawer: true,
        },
        // ...guards,
      },
      {
        path: 'feed',
        name: 'PatientFeed',
        component: () => import(/* webpackChunkName: "patient-feed" */ '../views/PatientFeed.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Feed',
          mobileDisplayName: 'Feed',
          hideFooter: true,
          requireUser: true,
          requirePatient: true,
          showPatientNotificationsOnLoad: true,
          expandedDrawer: true,
        },
        // ...guards,
      },
      {
        path: 'devices',
        name: 'Devices',
        component: () => import(/* webpackChunkName: "patient-devices" */ '../views/PatientDevices.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Devices',
          mobileDisplayName: 'Devices',
          hideFooter: true,
          requireUser: true,
          requirePatient: true,
          expandedDrawer: true,
        },
        // ...guards,
      },
      {
        path: 'feed/telehealth',
        name: 'PatientFeed/video',
        component: () => import(/* webpackChunkName: "patient-feed" */ '../views/PatientFeed.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Feed',
          mobileDisplayName: 'Feed',
          hideFooter: true,
          requireUser: true,
          requirePatient: true,
          showPatientNotificationsOnLoad: true,
          openVideo: true,
          expandedDrawer: true,
        },
        // ...guards,
      },
      {
        path: 'feed/alert',
        name: 'PatientFeed/alert',
        component: () => import(/* webpackChunkName: "patient-feed-alert" */ '../views/PatientFeed.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Feed',
          mobileDisplayName: 'Feed',
          hideFooter: true,
          requireUser: true,
          requirePatient: true,
          showPatientNotificationsOnLoad: true,
          openAlertNote: true,
          expandedDrawer: true,
        },
        // ...guards,
      },
      {
        path: 'feed/note',
        name: 'PatientFeed/note',
        component: () => import(/* webpackChunkName: "patient-feed-note" */ '../views/PatientFeed.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Feed',
          mobileDisplayName: 'Feed',
          hideFooter: true,
          requireUser: true,
          requirePatient: true,
          showPatientNotificationsOnLoad: true,
          openNote: true,
          expandedDrawer: true,
        },
        // ...guards,
      },
      {
        path: 'aspatient',
        name: 'ViewAsPatient',
        component: () => import(/* webpackChunkName: "view-as-patient" */ '../views/ViewAsPatient.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'As Patient',
          mobileDisplayName: 'As Patient',
          hideFooter: true,
          requireUser: true,
          requirePatient: true,
          showPatientNotificationsOnLoad: false,
          expandedDrawer: true,
        },
        // ...guards,
      },
      {
        path: 'notes',
        name: 'ChartNotes',
        component: () => import(/* webpackChunkName: "chart-notes" */ '../views/ChartNotes.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Notes',
          mobileDisplayName: 'Notes',
          requireUser: true,
          requirePatient: true,
          expandedDrawer: true,
        },
        // ...guards,
      },
      {
        path: 'messages',
        name: 'Messages',
        component: () => import(/* webpackChunkName: "messages" */ '../views/Messages.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Messages',
          mobileDisplayName: 'Messages',
          requireUser: true,
          requirePatient: true,
          expandedDrawer: true,
        },
        // ...guards,
      },
      {
        path: 'files',
        name: 'PatientFiles',
        component: () => import(/* webpackChunkName: "files" */ '../views/Files.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Files',
          mobileDisplayName: 'Files',
          requireUser: true,
          requirePatient: true,
          expandedDrawer: true,
        },
        // ...guards,
      },
      {
        path: 'video',
        name: 'PatientVideo',
        component: () => import(/* webpackChunkName: "video" */ '../views/Video.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Video',
          mobileDisplayName: 'Video',
          requireUser: true,
          requirePatient: true,
          expandedDrawer: true,
        },
        // ...guards,
      },
      {
        path: 'settings',
        name: 'Settings',
        component: () => import(/* webpackChunkName: "patient-settings" */ '../views/PatientSettings.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Settings',
          mobileDisplayName: 'Settings',
          requireUser: true,
          requirePatient: true,
          expandedDrawer: true,
        },
        // ...guards,
      },
      {
        path: 'followers',
        name: 'Followers',
        component: () => import(/* webpackChunkName: "followers" */ '../views/Followers.vue'),
        meta: {
          hideHeader: false,
          showPatientBar: true,
          displayName: 'Chart Access',
          mobileDisplayName: 'Access',
          requireUser: true,
          requirePatient: true,
          expandedDrawer: true,
        },
      },
    ],
    ...guards,
  },

  // {
  //   path: '/patients/:id/feed',
  //   name: 'PatientFeed',
  //   component: () =>
  //     import(/* webpackChunkName: "patient-feed" */ '../views/PatientFeed.vue'),
  //   meta: {
  //     hideHeader: false,
  //     showPatientBar: true,
  //     displayName: 'Feed',
  //     mobileDisplayName: 'Feed',
  //     hideFooter: true,
  //     requireUser: true,
  //     requirePatient: true,
  //     showPatientNotificationsOnLoad: true,
  //   },
  //   ...guards,
  // },
  // {
  //   path: '/patients/:id/feed/telehealth',
  //   name: 'PatientFeed/video',
  //   component: () =>
  //     import(/* webpackChunkName: "patient-feed" */ '../views/PatientFeed.vue'),
  //   meta: {
  //     hideHeader: false,
  //     showPatientBar: true,
  //     displayName: 'Feed',
  //     mobileDisplayName: 'Feed',
  //     hideFooter: true,
  //     requireUser: true,
  //     requirePatient: true,
  //     showPatientNotificationsOnLoad: true,
  //     openVideo: true,
  //   },
  //   ...guards,
  // },
  // {
  //   path: '/patients/:id/feed/alert',
  //   name: 'PatientFeed/alert',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "patient-feed-alert" */ '../views/PatientFeed.vue'
  //     ),
  //   meta: {
  //     hideHeader: false,
  //     showPatientBar: true,
  //     displayName: 'Feed',
  //     mobileDisplayName: 'Feed',
  //     hideFooter: true,
  //     requireUser: true,
  //     requirePatient: true,
  //     showPatientNotificationsOnLoad: true,
  //     openAlertNote: true,
  //   },
  //   ...guards,
  // },
  // {
  //   path: '/patients/:id/feed/note',
  //   name: 'PatientFeed/note',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "patient-feed-note" */ '../views/PatientFeed.vue'
  //     ),
  //   meta: {
  //     hideHeader: false,
  //     showPatientBar: true,
  //     displayName: 'Feed',
  //     mobileDisplayName: 'Feed',
  //     hideFooter: true,
  //     requireUser: true,
  //     requirePatient: true,
  //     showPatientNotificationsOnLoad: true,
  //     openNote: true,
  //   },
  //   ...guards,
  // },
  // {
  //   path: '/patients/:id/aspatient',
  //   name: 'ViewAsPatient',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "view-as-patient" */ '../views/ViewAsPatient.vue'
  //     ),
  //   meta: {
  //     hideHeader: false,
  //     showPatientBar: true,
  //     displayName: 'As Patient',
  //     mobileDisplayName: 'As Patient',
  //     hideFooter: true,
  //     requireUser: true,
  //     requirePatient: true,
  //     showPatientNotificationsOnLoad: false,
  //   },
  //   ...guards,
  // },
  // {
  //   path: '/patients/:id/notes',
  //   name: 'ChartNotes',
  //   component: () =>
  //     import(/* webpackChunkName: "chart-notes" */ '../views/ChartNotes.vue'),
  //   meta: {
  //     hideHeader: false,
  //     showPatientBar: true,
  //     displayName: 'Notes',
  //     mobileDisplayName: 'Notes',
  //     requireUser: true,
  //     requirePatient: true,
  //   },
  //   ...guards,
  // },
  // {
  //   path: '/patients/:id/messages',
  //   name: 'Messages',
  //   component: () =>
  //     import(/* webpackChunkName: "messages" */ '../views/Messages.vue'),
  //   meta: {
  //     hideHeader: false,
  //     showPatientBar: true,
  //     displayName: 'Messages',
  //     mobileDisplayName: 'Messages',
  //     requireUser: true,
  //     requirePatient: true,
  //   },
  //   ...guards,
  // },
  // {
  //   path: '/patients/:id/files',
  //   name: 'PatientFiles',
  //   component: () =>
  //     import(/* webpackChunkName: "files" */ '../views/Files.vue'),
  //   meta: {
  //     hideHeader: false,
  //     showPatientBar: true,
  //     displayName: 'Files',
  //     mobileDisplayName: 'Files',
  //     requireUser: true,
  //     requirePatient: true,
  //   },
  //   ...guards,
  // },
  // {
  //   path: '/patients/:id/video',
  //   name: 'PatientVideo',
  //   component: () =>
  //     import(/* webpackChunkName: "video" */ '../views/Video.vue'),
  //   meta: {
  //     hideHeader: false,
  //     showPatientBar: true,
  //     displayName: 'Video',
  //     mobileDisplayName: 'Video',
  //     requireUser: true,
  //     requirePatient: true,
  //   },
  //   ...guards,
  // },
  // {
  //   path: '/patients/:id/settings',
  //   name: 'Settings',
  //   component: () =>
  //     import(
  //       /* webpackChunkName: "patient-settings" */ '../views/PatientSettings.vue'
  //     ),
  //   meta: {
  //     hideHeader: false,
  //     showPatientBar: true,
  //     displayName: 'Settings',
  //     mobileDisplayName: 'Settings',
  //     requireUser: true,
  //     requirePatient: true,
  //   },
  //   ...guards,
  // },
  // {
  //   path: '/patients/:id/followers',
  //   name: 'Followers',
  //   component: () =>
  //     import(/* webpackChunkName: "followers" */ '../views/Followers.vue'),
  //   meta: {
  //     hideHeader: false,
  //     showPatientBar: true,
  //     displayName: 'Chart Access',
  //     mobileDisplayName: 'Access',
  //     requireUser: true,
  //     requirePatient: true,
  //   },
  //   ...guards,
  // },
  // {
  //   path: '/patients/:id/patientinfo',
  //   name: 'PatientInfo',
  //   component: () =>
  //     import(/* webpackChunkName: "patient-info" */ '../views/PatientInfo.vue'),
  //   meta: {
  //     hideHeader: false,
  //     showPatientBar: true,
  //     displayName: 'Information',
  //     mobileDisplayName: 'Info',
  //     hideFooter: true,
  //     requireUser: true,
  //     requirePatient: true,
  //   },
  //   ...guards,
  // },
  {
    path: '/account/:id',
    name: 'Account',
    component: () => import(/* webpackChunkName: "account" */ '../views/Account.vue'),
    meta: {
      hideHeader: false,
      showPatientBar: false,
      displayName: 'Account',
      mobileDisplayName: 'Account',
      hideFooter: true,
      requireUser: true,
    },
    ...guards,
  },
  {
    path: '/partner/:id',
    name: 'Partner',
    component: () => import(/* webpackChunkName: "organization" */ '../views/Organization.vue'),
    meta: {
      hideHeader: false,
      showPatientBar: false,
      displayName: 'Organization',
      mobileDisplayName: 'Organization',
      requireUser: true,
    },
    ...guards,
  },
  {
    path: '/partner/:id/reports',
    name: 'Reports',
    component: () => import(/* webpackChunkName: "billing-reports" */ '../views/BillingReports.vue'),
    meta: {
      hideHeader: false,
      showPatientBar: false,
      displayName: 'Reports',
      mobileDisplayName: 'Reports',
      hideFooter: true,
      requireUser: true,
    },
    ...guards,
  },
  {
    path: '/enrollment/:id',
    name: 'Enrollment',
    component: () => import(/* webpackChunkName: "enrollment" */ '../views/Enrollment.vue'),
    meta: {
      hideHeader: false,
      showPatientBar: false,
      displayName: 'Enrollment',
      mobileDisplayName: 'Enrollment',
      requireUser: true,
    },
    ...guards,
  },
  {
    path: '/shared/:id',
    name: 'Shared',
    component: () => import(/* webpackChunkName: "shared" */ '../views/Shared.vue'),
    meta: {
      hideHeader: true,
      showFooter: true,
    },
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  next()
  // you either must be logged in, or navigating to login or register. this is where we will authorize the share page
  // if (!store.getters.user && to.path !== '/login' && to.path !== '/register') {
  //   next('/login')
  // } else {
  //   console.log('you must not be logged in, or if you are, you are not going to the login page')
  //   next()
  // }
})

export default router
