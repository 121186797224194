import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  customProperties: true,
  theme: {
    dark: false,
    themes: {
      light: {
        primary: '#1A76F2',
        navColor: '#333449',
        secondaryNavColor: '#F0F2F5',
        snackbarColor: '#333449',
        snackbarButtonColor: '#565a81',
        background: '#F0F2F5',
        //background: '#FAFAFA',
      },
      dark: {
        primary: '#4485ed',
        background: '#1A76F2',
        // primary: '#3E637D' // Medium navy blue
        // primary: '#111F33' // Dark Dark Blue
        // primary: '#5DB88F' // Medical GReen
        // primary: '#0586DD' // royal blue
        // primary: '#2c323f' // medium dark navy
      },
    },
  },
})
