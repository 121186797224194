import store from '@/store'
import router from '@/router'

export default {
  beforeEnter(to, from, next) {
    // checks for the hashed urls from the old platform and redirects to login if it is hashed?
    if (to.hash.includes('#/login')) {
      let result = {}
      if (to.hash.includes('?')) {
        let removed = to.hash.split("?")[1]
        result = removed.split('&').reduce(function (result, item) {
          var parts = item.split('=');
          result[parts[0]] = parts[1];
          return result;
          }, {});
      }
      router.push({ name: 'Login',
          replace: true,
          query: result
        })
        return
    } else if (to.hash.includes('#/shared')) {
      let shareIDArray = to.hash.split("/")
      if (shareIDArray.length > 0) {
        let shareID = shareIDArray[shareIDArray.length - 1]
        router.push({
          name: "Shared",
          params: { id: shareID }
        });
        return
      }
    }
    if (store.state.auth.user) {
      if (store.state.auth.user.needsPasswordChange) {
        // router.push({ name: 'ResetPasswordPage', replace: true })
      } else {
        next()
      }
    }
    store.dispatch('auth/check').then(next, () => {
      next(false)
      console.log('returning from dispatch')
      console.log(to)
      if (to.query.token && to.name === 'Patient') {
        console.log('this is a special thing')
        store.state.auth.temporaryAuthToken = to.query.token
      } else (
        to.path === '/'
        ? router.push({ name: 'Login',
          replace: true
        })
        : router.push({ name: 'Login',
          replace: true,
          query: {
            redirect_after_login: encodeURI(location.href.split(location.origin).join(''))
          } })
      )
      
    })
  }
}
