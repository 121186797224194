import Vue from 'vue'
import { getRefreshToken, clearTokens } from '../../../shared-ui/store/sso'

export default {
  setAuthUser(state, authUser) {
    state.authUser = authUser
  },
  setTemporaryAuthToken(state, authToken) {
    state.temporaryAuthToken = authToken
  },
  setTemporaryAuthExpiration(state, expiration) {
    state.tempAuthExpiration = expiration
  },
  setUser(state, data) {
    state.user = data
  },
  setAppLocation(state, location) {
    state.appLocation = location
  },
  setBillingAccounts(state, accounts) {
    state.billingAccounts = accounts
  },
  setAuthToken(state, authToken) {
    // whenever the auth token is set, the ONECHART user is set.
    if (authToken) {
      state.authExpiry = Date.now() + 1800000 // expiration set to 30 minutes
      state.authToken = authToken
      Vue.axios.defaults.headers.Authorization = 'Bearer ' + state.authToken
      getRefreshToken(state.authToken)
    } else {
      state.user = null
      state.authToken = null
      state.authExpiry = Date.now()
      delete Vue.axios.defaults.headers.Authorization
      clearTokens()
    }
  },
  extraCommit() {},
  setSelectedUserGroup(state, selectedUserGroup) {
    state.selectedUserGroup = selectedUserGroup
  },
  setSelectedUserGroupMembers(state, selectedUserGroupMembersList) {
    state.selectedUserGroupMembers = selectedUserGroupMembersList
  },
  setPostAuthActionsPayload(state, payload) {
    state.postAuthActionsPayload = payload
  },
  setAppTheme(state, appTheme) {
    state.appTheme = appTheme
  },
  // RPM
  setRPMCurrentSession(state, session) {
    state.rpmCurrentSession = session
  },
  setRPMPartner(state, partner) {
    state.rpmPartner = partner
  },
  // ux
  setConfiguration(state, configuration) {
    state.configuration = configuration
  },
  setPartner(state, partner) {
    state.partner = partner
  },
  setOrgUsers(state, users) {
    state.orgUsers = users
  },
  setDemoFeaturesToTrue(state) {
    state.demoFeatures = true
  },
  setViewerMode(state, mode) {
    state.viewerMode = mode
  },
  addFirebaseUnsubscribeFunction(state, func) {
    state.firebaseUnsubscribeFunctions.push(func)
  },
  setUserMentions(state, userMentions) {
    state.userMentions = userMentions
  },
  setUserAssignments(state, userAssignments) {
    state.userAssignments = userAssignments
  },
}
