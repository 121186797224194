export default {
  setCoordinates(state, coordinates) {
    state.coordinates.push(coordinates)
  },
  resetCoord(state) {
    state.coordinates = []
  },
  extraCommit() {
  }
}
