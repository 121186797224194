import { customRef } from '@vue/composition-api'
import { getCookie, setCookie } from './cookie'

const EXPIRES_KEY = '.expires'

/**
 * useLocalStorage
 * @param {string} key
 * @param {{ expiresInMinutes?: number, defaultValue?: undefined, singleUse?: boolean }} opts?
 */
export const useLocalStorage = (key, opts = { expiresInMinutes: 0, defaultValue: undefined, singleUse: false }) => {
  return customRef((track, trigger) => {
    return {
      get() {
        track()
        const val = JSON.parse(localStorage.getItem(key) || null) || opts?.defaultValue
        const exp = localStorage.getItem(key + EXPIRES_KEY)
        if (exp && new Date(exp).getTime() - Date.now() < 0) {
          localStorage.removeItem(key)
          localStorage.removeItem(key + EXPIRES_KEY)
          return
        }
        return val
      },
      set(val) {
        if (val) {
          localStorage.setItem(key, JSON.stringify(val))
          if (opts?.expiresInMinutes) {
            const d = new Date()
            d.setMinutes(d.getMinutes() + opts?.expiresInMinutes)
            localStorage.setItem(key + EXPIRES_KEY, JSON.stringify(d.toISOString()))
          }
        } else {
          localStorage.removeItem(key)
          localStorage.removeItem(key + EXPIRES_KEY)
        }
        trigger()
      },
    }
  })
}

export const useCookie = (key, expiresInMS = 0, defaultValue = undefined) => {
  return customRef((track, trigger) => {
    return {
      get() {
        track()
        return JSON.parse(getCookie(key) || null) || defaultValue
      },
      set(val) {
        let expiresAt
        if (expiresInMS) {
          expiresAt = Date.now() + expiresInMS
        }
        if (val) {
          setCookie(key, JSON.stringify(val), expiresAt)
        } else {
          setCookie(key, 0, '')
        }
        trigger()
      },
    }
  })
}
