<template>
  <v-snackbar
    v-if="appTheme && user && user.partnerID"
    width="300"
    :color="appTheme.navColor"
    left
    timeout="10000"
    :bottom="!isMobileAppleDevice"
    :top="isMobileAppleDevice"
    @click="handleCardClick()"
    class="notification"
    v-model="showAlert"
  >
    <v-row no-gutters style="flex-wrap: nowrap;">
      <v-col
        cols="1"
        style="
          min-width: 60px;
          max-width: 60px;
          margin-right: 1px solid #f5f5f5;
        "
        class="flex-grow-0 flex-shrink-0"
        id="mydiv"
      >
        <v-row no-gutters v-if="alertForDisplay" class="mx-0">
          <v-icon
            v-if="alertForDisplay.type === 'thresholdBreach'"
            class="mr-3"
            large
            >mdi-alert-circle</v-icon
          >
          <v-icon
            v-if="alertForDisplay.type === 'inactivtyAlert'"
            class="mr-3"
            large
            >mdi-calendar-clock</v-icon
          >
          <v-icon v-if="alertForDisplay.type === 'chartNote'" class="mr-3" large
            >mdi-file-document-box-outline</v-icon
          >
          <v-icon
            v-if="alertForDisplay.type === 'carePlanTag'"
            class="mr-3"
            large
            >mdi-calendar-check-outline</v-icon
          >
          <v-divider vertical />
        </v-row>
        <v-spacer />
      </v-col>

      <v-col
        v-if="alertForDisplay"
        cols="11"
        style="
          min-width: 10px;
          max-width: 240px;
        "
        class="flex-grow-1 flex-shrink-1"
        >{{ alertForDisplay.note }}</v-col
      >
    </v-row>
    <v-row no-gutters v-if="alertForDisplay" class="pt-3 pl-4" align="center">
      {{ alertShown + 1 }} of {{ notificationQueue.length }}
      <v-spacer />
      <v-btn class="mr-2" @click="showAlert = false" dark small depressed text
        >Dismiss</v-btn
      >
      <v-btn
        color="primary"
        v-if="alertForDisplay.type === 'inactivtyAlert'"
        @click="addChartNote(), (showAlert = false)"
        small
        depressed
        >add note</v-btn
      >

      <v-btn
        color="primary"
        v-if="alertForDisplay.type === 'thresholdBreach'"
        @click="addEventNote(alertForDisplay.event), (showAlert = false)"
        small
        depressed
        >add note</v-btn
      >
      <v-btn
        color="primary"
        v-if="alertForDisplay.type === 'chartNote'"
        @click="goToNote(alertForDisplay.patient), (showAlert = false)"
        small
        depressed
        >go to note</v-btn
      >
      <v-btn
        color="primary"
        v-if="alertForDisplay.type === 'carePlanTag'"
        @click="goToCarePlan(alertForDisplay.patient), (showAlert = false)"
        small
        depressed
        >Care Plan</v-btn
      >
    </v-row>
  </v-snackbar>
</template>

<script>
import bus from '@/core/helpers/bus'
import { mapActions, mapMutations, mapState } from 'vuex'
import HelperMixin from '@/core/mixins/HelperMixin'

export default {
  mixins: [HelperMixin],
  name: 'NotificationCardManager',
  components: {},
  data() {
    return {
      menu: false,
      refPoint: null,
      showAlert: false,
      alertInterval: null,
      alertShown: 0,
      alertForDisplay: null,
      notificationQueue: [],
      notificationsAreRunning: false,
      alreadyNotified: [],
    }
  },
  props: {},
  computed: {
    ...mapState('patient', ['patient', 'activeVideoCall']),
    ...mapState('auth', [
      'appTheme',
      'user',
      'configuration',
      'tempAuthToken',
      'tempAuthExpiration',
      'userMentions',
      'userAssignments',
    ]),
    isMobile() {
      return this.$vuetify.breakpoint.smAndDown || false
    },
    isMobileAppleDevice() {
      if (
        navigator.userAgent.match(/Mac/) &&
        navigator.maxTouchPoints &&
        navigator.maxTouchPoints > 2
      ) {
        return true
      }
      return false
    },
  },
  watch: {
    userMentions(val) {
      if (val && val.length > 0) {
        let self = this

        setTimeout(function() {
          if (self.userMentions) {
            self.userMentions.forEach(alert => {
              if (!self.alreadyNotified.includes(alert.patientEvent)) {
                alert.type = 'chartNote'
                self.notificationQueue.push(alert)
                if (!self.notificationsAreRunning) {
                  self.queueTheAlerts()
                }
              }
            })
          }
        }, 500)
      } else {
        this.alreadyNotified = []
      }
    },
    userAssignments(val) {
      if (val && val.length > 0) {
        let self = this

        setTimeout(function() {
          if (self.userAssignments) {
            self.userAssignments.forEach(alert => {
              alert.type = 'carePlanTag'
              self.notificationQueue.push(alert)
              if (!self.notificationsAreRunning) {
                self.queueTheAlerts()
              }
            })
          }
        }, 500)
      } else {
        this.alreadyNotified = []
      }
    },
    showAlert(val) {
      if (val === false) {
        this.alertClearedMoveToNext()
      }
    },
    patient(val) {
      if (val) {
        let self = this

        setTimeout(function() {
          if (self.patient.thresholdBreachAlertNotifications) {
            self.patient.thresholdBreachAlertNotifications.forEach(alert => {
              alert.type = 'thresholdBreach'
              self.notificationQueue.push(alert)
              if (!self.notificationsAreRunning) {
                self.queueTheAlerts()
              }
            })
          }
          if (self.patient.inactivityAlertNotifications) {
            self.patient.inactivityAlertNotifications.forEach(alert => {
              alert.type = 'inactivtyAlert'
              self.notificationQueue.push(alert)
              if (!self.notificationsAreRunning) {
                self.queueTheAlerts()
              }
            })
          }
        }, 500)
      } else {
        // remove the patient alerts
        this.showAlert = false
        this.notificationQueue = []
        this.alertShown = 0
        this.notificationsAreRunning = false
      }
    },
  },
  methods: {
    ...mapActions('auth', ['signOut']),
    ...mapActions('auth', ['userActivity']),
    ...mapMutations('patient', [
      'setPatientEditingMode',
      'setPatients',
      'setPatient',
      'setTotalPatientCount',
    ]),
    ...mapActions('patient', [
      'prepareForSigningOut',
      'generateFacesheet',
      'clearPatient',
      'manageWatcher',
      'preparePatientObject',
    ]),
    ...mapActions('taskboard', ['prepareEventData']),
    handleCardClick() {
      if (this.alertForDisplay.type === 'chartNote') {
        this.goToNote(this.alertForDisplay.patient)
      }
      if (this.alertForDisplay.type === 'inactivityAlert') {
        this.addEventNote()
      }
      if (this.alertForDisplay.type === 'thresholdBreach') {
        this.addEventNote(this.alertForDisplay.event)
      }
    },
    alertClearedMoveToNext() {
      if (this.alertForDisplay.type === 'chartNote') {
        // store the event id in the already viewed list so it dowsnt display twice
        this.alreadyNotified.push(this.alertForDisplay.patientEvent)
      }
      this.alertShown = this.alertShown + 1
      let self = this

      setTimeout(function() {
        if (self.alertShown < self.notificationQueue.length) {
          self.alertForDisplay = self.notificationQueue[self.alertShown]
          self.showAlert = true
        } else {
          self.notificationsAreRunning = false
          self.notificationQueue = []
          self.alertShown = 0
          self.alertForDisplay = null
        }
      }, 400)
    },
    queueTheAlerts() {
      // every four seconds, show the alert until there are no more alerts

      let self = this

      setTimeout(function() {
        if (self.notificationQueue && self.notificationQueue.length > 0) {
          self.alertForDisplay = self.notificationQueue[0]
          self.showAlert = true
          this.notificationsAreRunning = true
        }
      }, 800)
    },
    addChartNote() {
      bus.$emit('addNoteToPoint')
    },
    addEventNote(event) {
      bus.$emit('addNoteToPoint', {
        type: 'metric',
        data: event,
      })
    },
    goToNote(returnedPatient) {
      // if youre on the patientfeed page, jsut refresh,
      // if youre elsewhere, go to the patient feed page
      if (this.patient && this.patient._id === returnedPatient.id) {
        if (this.$route.name === 'PatientFeed') {
          bus.$emit('refreshNoteList')
        } else {
          this.$router
            .push({
              name: 'PatientFeed',
              params: { id: returnedPatient.id },
            })
            .then(() => {
              bus.$emit('refreshNoteList')
            })
          this.userActivity({ patient: returnedPatient.id, type: 'view_chart' })
        }
      } else {
        this.clearPatient()
        this.setPatient(returnedPatient)
        this.manageWatcher(returnedPatient.id)
        this.$router.push({
          name: 'PatientFeed',
          params: { id: returnedPatient.id },
        })
        this.userActivity({ patient: returnedPatient.id, type: 'view_chart' })
      }
    },
    goToCarePlan(returnedPatient) {
      // this.drawer = false
      this.clearPatient()
      this.setPatient(returnedPatient)
      this.manageWatcher(returnedPatient.id)
      this.$router.push({
        name: 'CarePlan',
        params: { id: returnedPatient.id },
      })
      this.userActivity({ patient: returnedPatient.id, type: 'view_chart' })
    },
  },
  mounted() {
    // when this is mounted, then you should display the to do items like a toast but the lower left. and do it cool
    //alert(this.patient.thresholdBreachAlertNotifications.length)
  },
}
</script>
