import Vue from 'vue'
import VueCompositionAPI from '@vue/composition-api'

Vue.use(VueCompositionAPI)

const urlParams = new URLSearchParams(window.location.search)

export const isDarkMode = ['true', '1'].includes(urlParams.get('dark'))

export const isStandalone = window === (window.opener || window.parent)

export const patientId = urlParams.get('patient')

export const getSearchParam = name => urlParams.get(name)
