import store from '@/store'
import { getAccessToken } from '../../shared-ui/store/sso'
import { HttpClient } from '../../shared-ui/helpers/httpClient'
import get from 'lodash/get'
import sortBy from 'lodash/sortBy'
import uniqBy from 'lodash/uniqBy'

const requestHandler = async (ctx, request) => {
  const accessToken = await getAccessToken()
  if (accessToken) {
    request.headers['Authorization'] = `Bearer ${accessToken}`
  } else {
    store.dispatch('auth/logout', { error: 'Invalid refresh token' })
  }
  return request
}

const teleClient = HttpClient.create({
  baseUrl: process.env.VUE_APP_TELE_API_URL,
  requestHandler,
})

teleClient.responseHandler

function makeBackwardsCompatible(data) {
  let s = JSON.stringify(data)
  s = s.replaceAll('"id"', '"_id"')
  s = s.replaceAll('"lastReadMsgId"', '"lastReadMessageID"')
  s = s.replaceAll('"lastReadMsgCreated"', '"lastReadMessageCreated"')
  s = s.replaceAll('"orgId"', '"partnerID"')
  s = s.replaceAll('"lastMsg"', '"lastMessage"')
  s = s.replaceAll('"convoId"', '"conversationID"')
  s = s.replaceAll('"userId"', '"userID"')
  s = s.replaceAll('"patientId"', '"patientID"')
  s = s.replaceAll('"ownerPatientId"', '"ownerPatientID"')
  s = s.replaceAll('"conversations"', '"items"')
  s = s.replaceAll('"orgUnread"', '"unread"')
  data = JSON.parse(s)
  return data
}

function purgeTempMessages(messages = []) {
  let msgs = []
  for (let i = 0; i < messages.length; i++) {
    if (messages[i].id) {
      msgs.push(messages[i])
    }
  }
  return msgs
}

function parseMessages(messages = []) {
  let msgs = []
  for (let i = 0; i < messages.length; i++) {
    let msg = messages[i]
    msgs.push({
      id: msg.id,
      senderId: msg.sender.userId,
      displayName: msg.sender.displayName,
      isSender: msg.sender.kind !== 'PATIENT',
      content: msg.content,
      delivered: get(msg, 'smsStatus', '').toLowerCase() === 'delivered',
      created: msg.created,
    })
  }
  return msgs
}

export default {
  namespaced: true,
  state: {
    autoCheckTimer: null,
    orgId: null,
    conversation: null,
    conversations: [],
    messages: [],
    unreadConversationsCount: 0,
    unreadMessagesCount: 0,
    messengerOpenCount: 0,
  },
  mutations: {
    setOrgId(state, orgId) {
      state.orgId = orgId
    },
    setConversation(state, conversation) {
      state.conversation = conversation
    },
    setConversations(state, conversations) {
      state.conversations = conversations
    },
    setMessages(state, messages) {
      messages = uniqBy(messages, 'id')
      messages = sortBy(messages, 'created')
      state.messages = messages
    },
    setUnreadConversationCount(state, count) {
      state.unreadConversationsCount = count
    },
    setUnreadMessagesCount(state, count) {
      state.unreadMessagesCount = count
    },
    incMessengerOpenCount(state) {
      state.messengerOpenCount++
    },
    decMessengerOpenCount(state) {
      let count = state.messengerOpenCount - 1
      if (count < 0) {
        console.warn('messenger count was less than 0')
        count = 0
      }
      state.messengerOpenCount = count
    },
  },
  actions: {
    async startCheckForUnreadCount(
      { dispatch, commit, state },
      { orgId = '', patientId = '', checkForUnreadInterval = 60000, checkImmediately = false }
    ) {
      if (orgId) {
        commit('setOrgId', orgId)
      }
      clearInterval(state.autoCheckTimer)
      // get the conversation that belongs to the patient
      if (patientId && get(state, 'conversation.patientId') !== patientId) {
        await dispatch('getConversation', { orgId, patientId })
      }
      //  start the auto check
      state.autoCheckTimer = setInterval(() => {
        dispatch('getUnreadConversationsCount')
        dispatch('getUnreadMessagesCount')
      }, checkForUnreadInterval)

      if (checkImmediately) {
        // check for unread convos
        await dispatch('getUnreadConversationsCount')
        // check for unread messages
        await dispatch('getUnreadMessagesCount')
      }
    },
    async resetCheckForUnreadCount({ dispatch }) {
      dispatch('startCheckForUnreadCount', {})
    },
    async stopCheckForUnreadCount({ state }) {
      clearInterval(state.autoCheckTimer)
    },
    async getUnreadMessagesCount({ commit, state }) {
      if (!state.conversation) {
        return 0
      }
      let newerThan = state.conversation.orgLastRead
      let c = state.messages.length
      if (c > 0) {
        let lastMessage = state.messages[c - 1]
        newerThan = lastMessage.created
      }
      let { data } = await teleClient.post('/GetMsgsCount', {
        convoId: state.conversation.id,
        newerThan,
      })

      commit('setUnreadMessagesCount', Number(data.count))
      return data
    },
    async getUnreadConversationsCount({ commit, state }) {
      if (state.orgId) {
        let { data } = await teleClient.post('/GetConvosCount', {
          orgId: state.orgId,
          unreadOnly: true,
        })

        commit('setUnreadConversationCount', Number(data.count))
        return data.count
      }
      return 0
    },
    async getConversation({ commit }, { convoId = '', orgId = '', patientId = '' }) {
      let { data } = await teleClient.post('/GetConvo', {
        convoId,
        orgId,
        patientId,
      })
      commit('setConversation', data.convo)
      return data.convo
    },
    async getConversations({ commit }, { pageSize, pageToken, unreadOnly, orgId }) {
      let { data } = await teleClient.post('/GetConvos', {
        orgId,
        pageSize,
        pageToken,
        unreadOnly,
      })
      data = makeBackwardsCompatible(data)
      commit('setConversations', data.convos)
      return data.convos
    },
    async getMessages({ dispatch, commit, state }, { orgId, patientId }) {
      // console.log('Get Msgs:', patientId, !!state.conversation)
      // no convo, get it
      if (!state.conversation) {
        await dispatch('getConversation', {
          orgId,
          patientId,
        })
      }
      // still no convo, return empty messages
      if (!state.conversation) {
        return state.messages
      }
      let newerThan
      let c = state.messages.length
      if (c > 0) {
        let lastMessage = state.messages[c - 1]
        newerThan = lastMessage.created
      }

      let { data } = await teleClient.post('/GetMsgs', {
        convoId: state.conversation.id,
        newerThan,
        pageSize: 100,
      })
      let messages = [].concat(state.messages)
      if (state.messages.length === 0 || newerThan) {
        messages = messages.concat(parseMessages(data.msgs))
        messages = purgeTempMessages(messages)
      }
      commit('setMessages', messages)
      return messages
    },
    async sendMessage({ commit, state }, { orgId, patientId, content }) {
      let { data } = await teleClient.post('/SendMsg', {
        orgId,
        patientId,
        content,
      })
      let messages = state.messages.concat(parseMessages([data.msg]))
      commit('setMessages', messages)
      return data.msg
    },
    clearMessages({ commit }) {
      // console.log('clear Messages')
      commit('setConversation', null)
      commit('setUnreadMessagesCount', 0)
      commit('setMessages', [])
    },
    incMessengerOpenCount({ commit }) {
      commit('incMessengerOpenCount')
      // console.log('messenger count ==>', state.messengerOpenCount)
    },
    decMessengerOpenCount({ dispatch, commit, state }) {
      commit('decMessengerOpenCount')
      // console.log('messenger count ==>', state.messengerOpenCount)
      if (state.messengerOpenCount === 0) {
        dispatch('clearMessages')
        dispatch('resetCheckForUnreadCount')
      }
    },
  },
}
