<template>
  <v-dialog overlay-opacity=".98" overlay-color="white" v-model="show" :max-width="options.maxWidth" persistent scrollable>
      <v-card>
        <v-toolbar flat>
          <v-toolbar-title style="font-weight:700;">
            PRIVACY NOTICE
            </v-toolbar-title>
          </v-toolbar>

        
        <!-- Content -->
        <v-card-text>
          <div style="text-align:justify;">
          The following information is protected by federal and state privacy laws. 
          This information is intended only for the use of authorized person(s). 
          If you are not the intended recipient, you are hereby notified that any review, dissemination, 
          distribution, or duplication of these records is strictly prohibited. 
          By continuing, you assert that you are authorized to view this information.
          </div>
        </v-card-text>
        <!-- Actions -->
        <v-card-actions>
          <!-- Buttons -->
          
          <v-btn depressed @click="cancelOption()">Cancel</v-btn>
          <v-spacer></v-spacer>
          <v-btn depressed dark color="primary" @click="ok()">Continue</v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
</template>

<style scoped>
.building {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  font-size: 24px;
  font-weight: 300;
  line-height: 1.33;
  text-align: center;
  color: #ff8d16;
}

.content {
  font-size: 16px;
  font-weight: 300;
  line-height: 1.5;
  text-align: center;
  color: #52535f;
}
</style>

<script>
import DialogMixin from '@/core/mixins/DialogMixin'

export default {
  name: 'OneConfirmDialog',
  mixins: [DialogMixin],
  components: {
  },
  data() {
    return {
      className: 'Confirm',
      defaultOptions: {
        okLabel: 'Confirm',
        cancelLabel: 'Cancel',
        showOk: true,
        showCancel: false
      }
    }
  },
  methods: {
    cancelOption() {
      this.$router.push({ name: 'Login' })
      this.cancel()
    }
  },
  computed: {
    showBuilding() {
      return this.options.unit && this.options.building
    }
  }
}
</script>
